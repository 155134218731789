@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica.ttf") format("truetype"); }

@font-face {
  font-family: "Helvetica Bold";
  src: url("../fonts/Helvetica-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "Helvetica BoldOblique";
  src: url("../fonts/Helvetica-BoldOblique.ttf") format("truetype"); }

@font-face {
  font-family: "Helvetica Oblique";
  src: url("../fonts/Helvetica-Oblique.ttf") format("truetype"); }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes iconUp {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }
  75% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }
  to {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }

@keyframes iconDown {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  25% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg); }
  50% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }
  75% {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

* {
  margin: 0;
  padding: 0;
  font-weight: normal !important; }

*:focus {
  outline: none; }

.input-field:focus {
  box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }

.dropdown.open .select-box {
  box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }

.dropdown .select-box:focus {
  box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }

.m-0 {
  margin: 0 !important; }

.m-l-0 {
  margin-left: 0 !important; }

.m-r-0 {
  margin-right: 0 !important; }

.m-b-0 {
  margin-bottom: 0 !important; }

.m-t-0 {
  margin-top: 0 !important; }

.p-0 {
  padding: 0 !important; }

.p-t-0 {
  padding-top: 0 !important; }

.p-r-0 {
  padding-right: 0 !important; }

.p-b-0 {
  padding-bottom: 0 !important; }

.p-l-0 {
  padding-left: 0 !important; }

a:hover, a:focus {
  text-decoration: none; }

.cursor-p {
  cursor: pointer; }

.alert-text {
  color: #ed5a5a !important; }

html {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  html ::-webkit-scrollbar {
    width: 15px; }
  html ::-webkit-scrollbar-track {
    background: #f2f3f7;
    background-clip: content-box; }
  html ::-webkit-scrollbar-thumb {
    background-color: #4687f4;
    border-radius: 10px;
    max-height: 10px; }
  html ::-webkit-scrollbar-thumb:hover {
    background: #4687f4; }

body {
  min-height: 100vh;
  background-color: white;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  body ::-webkit-scrollbar {
    width: 10px; }
  body ::-webkit-scrollbar-track {
    background: white;
    background-clip: content-box; }
  body ::-webkit-scrollbar-thumb {
    background: #4687f4;
    border-radius: 0px;
    max-height: 10px; }
  body ::-webkit-scrollbar-thumb:hover {
    background: #4687f4; }

tspan,
text {
  font-family: Helvetica; }

input[disabled] {
  cursor: not-allowed !important;
  background-color: inherit; }

button[disabled] {
  cursor: not-allowed !important;
  background: rgba(0, 0, 0, 0.1); }

.ReactTable {
  position: relative; }
  .ReactTable .rt-table {
    overflow-x: scroll; }
    .ReactTable .rt-table::-webkit-scrollbar {
      height: 3px; }
    .ReactTable .rt-table::-webkit-scrollbar-button {
      display: none; }
    .ReactTable .rt-table::-webkit-scrollbar-track {
      background: transparent; }
    .ReactTable .rt-table::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #4687f4; }
  .ReactTable .rt-noData {
    position: absolute;
    top: 70%;
    background: transparent !important;
    color: black !important; }
  .ReactTable.no-data.no-pagination .rt-noData {
    top: 70%; }
  .ReactTable.no-data .rt-tbody {
    min-height: 60px; }

.ReactModal__Overlay {
  z-index: 1010; }

.loader-modal-overlay,
.content-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.loader-modal-overlay {
  background-color: rgba(255, 255, 255, 0.75); }

.content-modal-overlay {
  background-color: rgba(0, 0, 0, 0.25); }

.loader-modal-content,
.content-modal-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.loader-modal-content {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center; }

.content-modal-content {
  background-color: #fff; }

.content-wrapper.preview-wrapper, .content-wrapper.login-wrapper, .content-wrapper.signup-wrapper, .content-wrapper.forgot-password-wrapper {
  background: linear-gradient(#f2f3f7 1px, #fff 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px; }

.primary-link {
  display: inline-block;
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #29a9e0;
  cursor: pointer; }
  .primary-link:hover, .primary-link:focus {
    display: inline-block;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #29a9e0;
    cursor: pointer; }

.input-label {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.19;
  letter-spacing: normal;
  color: #1d1d1d;
  margin-bottom: 3px;
  margin-left: 5px; }

.input-field,
.form-control {
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 7px;
  width: 100%;
  padding: 15px 12px;
  font-family: "Helvetica";
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: normal;
  height: auto; }

.input-field::placeholder,
.form-control::placeholder {
  color: #bababa; }

.form-control:focus {
  border: 1px solid #bcbcbc; }

.date-field {
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 7px;
  width: 100%;
  padding: 15px 12px;
  font-family: "Helvetica";
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: normal; }
  .date-field::placeholder {
    color: #bababa; }
  .date-field:focus {
    box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }

.dropdown,
.dropdown .select-box {
  width: 100%; }

.dropdown.open .select-box:active, .dropdown.open .select-box:hover, .dropdown.open .select-box:focus,
.dropdown .select-box:active,
.dropdown .select-box:hover,
.dropdown .select-box:focus {
  background-color: #fff;
  color: #1d1d1d;
  border: 1px solid #eee; }

.dropdown.open > .dropdown-toggle.btn-default {
  background-color: #fff;
  color: #1d1d1d;
  border: 1px solid #eee; }

.dropdown .select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1d1d1d;
  border: 1px solid #eee;
  border-radius: 7px;
  font-size: 14px;
  font-family: Helvetica;
  line-height: 1.14;
  letter-spacing: normal;
  padding: 15px 12px;
  text-overflow: ellipsis;
  overflow: hidden; }
  .dropdown .select-box .caret {
    color: #bcbcbc; }
  .dropdown .select-box + .dropdown-menu {
    width: 100%; }
    .dropdown .select-box + .dropdown-menu li.active a {
      background-color: #29a9e0;
      color: #fff; }
      .dropdown .select-box + .dropdown-menu li.active a:hover {
        background-color: #29a9e0;
        color: #fff; }
    .dropdown .select-box + .dropdown-menu li a {
      padding: 5px 12px;
      line-height: 1.14;
      letter-spacing: normal;
      font-size: 14px;
      font-family: Helvetica;
      white-space: normal; }
      .dropdown .select-box + .dropdown-menu li a:hover, .dropdown .select-box + .dropdown-menu li a:focus {
        background-color: #eee; }
    .dropdown .select-box + .dropdown-menu li:first-child a {
      color: #001061;
      font-family: "Helvetica Bold";
      background-color: #eee;
      padding: 10px 12px; }

.checkbox-box {
  display: flex;
  align-items: center; }
  .checkbox-box input[type="checkbox"] {
    display: none; }
    .checkbox-box input[type="checkbox"] + .checkbox .checkbox-checked {
      display: none; }
    .checkbox-box input[type="checkbox"] + .checkbox .checkbox-unchecked {
      display: block; }
    .checkbox-box input[type="checkbox"]:checked + .checkbox .checkbox-checked {
      display: block; }
    .checkbox-box input[type="checkbox"]:checked + .checkbox .checkbox-unchecked {
      display: none; }
  .checkbox-box .checkbox {
    margin: 0 5px 0 0; }
    .checkbox-box .checkbox .checkbox-checked,
    .checkbox-box .checkbox .checkbox-unchecked {
      cursor: pointer; }
  .checkbox-box label {
    font-size: 14px;
    font-family: "Helvetica";
    cursor: pointer;
    margin-bottom: 0; }

.radio-box input[type="radio"] {
  display: none; }
  .radio-box input[type="radio"] + .radios .radio-checked {
    display: none; }
  .radio-box input[type="radio"] + .radios .radio-unchecked {
    display: block; }
  .radio-box input[type="radio"]:checked + .radios .radio-checked {
    display: block; }
  .radio-box input[type="radio"]:checked + .radios .radio-unchecked {
    display: none; }

.radio-box .radio-checked,
.radio-box .radio-unchecked {
  cursor: pointer; }

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none; }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  top: 3px !important; }

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%; }

.react-datepicker-popper {
  transform: scale(1.5) !important;
  top: 90px !important;
  left: 35px !important;
  z-index: 1000 !important; }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0 !important; }

.input-box {
  position: relative; }
  .input-box.input-disabled label {
    cursor: not-allowed; }
  .input-box label {
    display: inline-block;
    position: absolute;
    left: 12px;
    top: 13px;
    transition: all 150ms ease-in;
    font-family: "Helvetica";
    font-size: 14px;
    color: #bcbcbc;
    margin-bottom: 0;
    cursor: text;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
    .input-box label::selection {
      background-color: transparent; }
    .input-box label.field-active {
      transform: translateY(-20px);
      font-size: 11px;
      color: #001061;
      background-color: #fff; }
      .input-box label.field-active span {
        color: #ed5a5a;
        transform: scale(1.5);
        display: inline-block; }
  .input-box .floating-label {
    -webkit-appearance: none !important; }

.datepicker-box {
  position: relative; }
  .datepicker-box.datepicker-disabled label {
    cursor: not-allowed; }
  .datepicker-box label {
    display: inline-block;
    position: absolute;
    left: 12px;
    top: 13px;
    transition: all 150ms ease-in;
    font-family: "Helvetica";
    font-size: 14px;
    color: #bcbcbc;
    margin-bottom: 0;
    cursor: text;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 999; }
    .datepicker-box label::selection {
      background-color: transparent; }
    .datepicker-box label.field-active {
      transform: translateY(-20px);
      font-size: 11px;
      color: #001061;
      background-color: #fff; }
      .datepicker-box label.field-active span {
        color: #ed5a5a;
        transform: scale(1.5);
        display: inline-block; }
  .datepicker-box .floating-label {
    -webkit-appearance: none !important; }

.dropdown-box {
  position: relative; }
  .dropdown-box.dropdown-disabled label {
    cursor: not-allowed; }
  .dropdown-box label {
    display: inline-block;
    position: absolute;
    left: 13px;
    top: 11px;
    transition: all 150ms ease-in;
    font-family: "Helvetica";
    font-size: 14px;
    color: #bcbcbc;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 999;
    cursor: pointer; }
    .dropdown-box label::selection {
      background-color: transparent; }
    .dropdown-box label.dropdown-active {
      transform: translateY(-18px);
      font-size: 11px;
      color: #001061;
      background-color: #fff; }
      .dropdown-box label.dropdown-active span {
        color: #ed5a5a;
        transform: scale(1.5);
        display: inline-block; }
  .dropdown-box .floating-label {
    -webkit-appearance: none !important; }
  .dropdown-box .select-box {
    transition: all 1s ease-in; }
    .dropdown-box .select-box.opacity-0 {
      opacity: 0; }
    .dropdown-box .select-box.opacity-1 {
      opacity: 1; }

.mt-1 {
  margin-top: 0.4em !important; }

.mt-2 {
  margin-top: 0.8em !important; }

.mt-3 {
  margin-top: 1.2em !important; }

.mb-1 {
  margin-bottom: 0.4em !important; }

.mb-2 {
  margin-bottom: 0.8em !important; }

.mb-3 {
  margin-bottom: 1.2em !important; }

.mr-1 {
  margin-right: 0.4em !important; }

.mr-2 {
  margin-right: 0.8em !important; }

.mr-3 {
  margin-right: 1.2em !important; }

.ml-1 {
  margin-left: 0.4em !important; }

.ml-2 {
  margin-left: 0.8em !important; }

.ml-3 {
  margin-left: 1.2em !important; }

.p-3 {
  padding: 1.2em !important; }

.p-2 {
  padding: 0.8em !important; }

.boldText {
  font-weight: 600 !important;
  color: #1d1d1d;
  font-size: 14px;
  display: inline-block;
  margin-right: 1em; }

.popover .left > .arrow::after {
  right: 2px; }

.popover .popover-title {
  font-family: "Helvetica Bold"; }

.card__wrapper {
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding-bottom: 30px;
  padding-top: 15px;
  padding-left: 35px;
  padding-right: 35px; }

.button_Generic {
  border-radius: 10px;
  width: 138px;
  height: 38px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  border: none; }
  .button_Generic.green-btn {
    background: #29a9e0; }
  .button_Generic.blue-btn {
    background: #29a9e0; }
  .button_Generic.red-btn {
    color: #ff6055;
    border: 1px solid #ff6055; }
  .button_Generic.bold-btn {
    font-weight: 700 !important; }
  .button_Generic.grey-btn {
    background: #c4c0c0; }
  .button_Generic.white-btn {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid #4687f4 !important;
    color: #555555;
    font-size: 12px; }

.set-cursor-pointer {
  cursor: pointer; }

.select-field {
  width: 150px; }

.no-display {
  display: none; }

.overflow-break-word {
  overflow-wrap: break-word; }

.no-rows-div {
  text-align: center;
  border: 1px solid #000;
  padding: 10px 0;
  background-color: rgba(255, 228, 196, 0.265); }

.no-data-div {
  text-align: center;
  color: #1d1d1d;
  font-weight: 800 !important; }

.child-space-between {
  display: flex;
  justify-content: space-between; }

#otp {
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  border: none;
  width: 9ch;
  font-size: 14px;
  align-content: center;
  background: -webkit-repeating-linear-gradient(left, #696969, #696969 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat;
  background: repeating-linear-gradient(90deg, #696969, #696969 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat;
  color: #1d1d1d;
  font: 5ch consolas, monospace;
  letter-spacing: 0.5ch; }

.f-12 {
  font-size: 12px !important; }

.primary-color {
  color: #001061;
  font-weight: bold; }

.text-center {
  margin-top: 4%; }
  .text-center .save-btn {
    width: 141px;
    border-radius: 25px;
    background-color: #4687f4;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin-top: 10px;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .text-center .save-btn {
        width: 100%; } }

.justify-content-center {
  display: flex;
  justify-content: center; }

@media screen and (max-width: 768px) {
  [class^="col-md"] {
    padding: 0;
    margin: 10px 0px 0px 0px; } }

@media screen and (max-width: 768px) {
  [class^="col-xs"] {
    padding: 0;
    margin: 10px 0px 0px 0px; } }

.promoter_img {
  height: 45rem;
  display: flex;
  margin: 15px auto;
  border-radius: 1rem; }

.preview-wrapper .heading {
  font-family: Helvetica;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: normal;
  color: #001061;
  margin: 0;
  font-weight: bolder !important; }

.preview-wrapper .content-box {
  width: 500px;
  border-radius: 20px;
  background-color: #fff; }
  .preview-wrapper .content-box .sub-heading {
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.21;
    letter-spacing: normal;
    color: #001061;
    margin: 10px 0 20px; }
  .preview-wrapper .content-box img {
    height: 40rem; }

.preview-wrapper .btns-box {
  display: flex;
  align-items: center;
  margin: 45px 0; }
  .preview-wrapper .btns-box a {
    width: 110px;
    height: 40px;
    font-family: Helvetica;
    font-size: 18px;
    line-height: 0.89;
    letter-spacing: -0.1px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: none; }
    .preview-wrapper .btns-box a:first-child {
      background-color: #4687F4; }
    .preview-wrapper .btns-box a:last-child {
      background-color: #4687F4; }
  .preview-wrapper .btns-box span {
    font-family: Helvetica;
    margin: 0 15px;
    font-size: 16px;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #001061; }

.preview-wrapper .video-preview-wrapper .videos-preview-box {
  width: 90%;
  margin: 0 auto;
  display: flex; }
  .preview-wrapper .video-preview-wrapper .videos-preview-box .list-item {
    width: 100%;
    cursor: pointer;
    border: 2px solid #eee;
    position: relative; }
    .preview-wrapper .video-preview-wrapper .videos-preview-box .list-item:hover {
      box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }
    .preview-wrapper .video-preview-wrapper .videos-preview-box .list-item img {
      width: 100%;
      height: 150px; }
    .preview-wrapper .video-preview-wrapper .videos-preview-box .list-item:first-child {
      margin-right: 15px;
      border-right: none;
      border-top: none;
      border-left: none; }
    .preview-wrapper .video-preview-wrapper .videos-preview-box .list-item .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #001061; }

.cross-icon {
  margin-top: 20px;
  float: right;
  cursor: pointer; }

.progressbar-wrapper {
  width: 875px;
  margin: 0 auto 15px; }
  @media screen and (max-width: 768px) {
    .progressbar-wrapper {
      width: 95%;
      margin-top: 30px; } }
  .progressbar-wrapper .progressbar {
    display: flex; }
    .progressbar-wrapper .progressbar li {
      list-style: none;
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .progressbar-wrapper .progressbar li span {
        border: 2px solid #fff;
        border-radius: 100%;
        margin: 0 auto 5px auto;
        color: #bcbcbc;
        box-shadow: 0 0 0 1px #bcbcbc;
        padding: 5px;
        height: 45px;
        width: 45px;
        text-align: center;
        background: #fff; }
        .progressbar-wrapper .progressbar li span::after {
          content: "";
          position: absolute;
          width: 73%;
          height: 3px;
          background: #ddd;
          top: 30%;
          left: -36.5%; }
          @media screen and (max-width: 768px) {
            .progressbar-wrapper .progressbar li span::after {
              top: 45%;
              left: -18%;
              width: 35%; } }
      .progressbar-wrapper .progressbar li:first-child span::after {
        content: none; }
      .progressbar-wrapper .progressbar li:first-child.active span {
        box-shadow: 0 0 0 1px #4687f4; }
        .progressbar-wrapper .progressbar li:first-child.active span svg g path {
          fill: #4687f4;
          stroke: #4687f4; }
      .progressbar-wrapper .progressbar li:first-child.done span {
        box-shadow: 0 0 0 1px #1de9b6; }
        .progressbar-wrapper .progressbar li:first-child.done span svg g path {
          fill: #1de9b6; }
      .progressbar-wrapper .progressbar li:nth-child(2).active span {
        box-shadow: 0 0 0 1px #4687f4; }
        .progressbar-wrapper .progressbar li:nth-child(2).active span svg g path {
          fill: #4687f4;
          stroke: #4687f4; }
      .progressbar-wrapper .progressbar li:nth-child(2).done span {
        box-shadow: 0 0 0 1px #1de9b6; }
        .progressbar-wrapper .progressbar li:nth-child(2).done span svg g path {
          fill: #1de9b6;
          stroke: #1de9b6; }
        .progressbar-wrapper .progressbar li:nth-child(2).done span::after {
          background: #1de9b6; }
      .progressbar-wrapper .progressbar li:nth-child(3).active span {
        box-shadow: 0 0 0 1px #4687f4; }
        .progressbar-wrapper .progressbar li:nth-child(3).active span svg path {
          fill: #4687f4;
          stroke: #4687f4; }
      .progressbar-wrapper .progressbar li:nth-child(3).done span {
        box-shadow: 0 0 0 1px #1de9b6; }
        .progressbar-wrapper .progressbar li:nth-child(3).done span svg path {
          fill: #1de9b6; }
        .progressbar-wrapper .progressbar li:nth-child(3).done span::after {
          background: #1de9b6; }
      .progressbar-wrapper .progressbar li:nth-child(4).active span {
        box-shadow: 0 0 0 1px #4687f4; }
        .progressbar-wrapper .progressbar li:nth-child(4).active span svg g path {
          fill: #4687f4;
          stroke: #4687f4; }
      .progressbar-wrapper .progressbar li:nth-child(4).done span {
        box-shadow: 0 0 0 1px #1de9b6; }
        .progressbar-wrapper .progressbar li:nth-child(4).done span svg g path {
          stroke: #1de9b6; }
        .progressbar-wrapper .progressbar li:nth-child(4).done span::after {
          background: #1de9b6; }
      .progressbar-wrapper .progressbar li:nth-child(4).done::after {
        background: #1de9b6;
        z-index: 1; }
      .progressbar-wrapper .progressbar li:last-child.active span {
        box-shadow: 0 0 0 1px #4687f4; }
        .progressbar-wrapper .progressbar li:last-child.active span svg path {
          fill: #4687f4;
          stroke: #4687f4; }
      .progressbar-wrapper .progressbar li:last-child.done span {
        box-shadow: 0 0 0 1px #1de9b6; }
        .progressbar-wrapper .progressbar li:last-child.done span svg path {
          stroke: #1de9b6; }
        .progressbar-wrapper .progressbar li:last-child.done span::after {
          background: #1de9b6; }
      .progressbar-wrapper .progressbar li label {
        font-family: Helvetica;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d; }
        @media screen and (max-width: 768px) {
          .progressbar-wrapper .progressbar li label {
            display: none; } }
      .progressbar-wrapper .progressbar li.active span::after {
        background: #ddd; }
      .progressbar-wrapper .progressbar li.active label {
        color: #4687f4; }
      .progressbar-wrapper .progressbar li.done label {
        color: #1de9b6; }

.credit-navs-wrapper .navbar {
  background-color: #F2F3F7;
  border: none;
  margin-bottom: 0;
  border-radius: 0; }
  .credit-navs-wrapper .navbar.navbar-fixed-top {
    z-index: 1001; }
  .credit-navs-wrapper .navbar .credit-navs {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .credit-navs-wrapper .navbar .credit-navs li.active a, .credit-navs-wrapper .navbar .credit-navs li.active a:hover, .credit-navs-wrapper .navbar .credit-navs li.active a:focus {
      background-color: #4687F4;
      border: 1px solid #fff;
      border-radius: 25px;
      color: #fff; }
    .credit-navs-wrapper .navbar .credit-navs li a, .credit-navs-wrapper .navbar .credit-navs li a:hover, .credit-navs-wrapper .navbar .credit-navs li a:focus {
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.04;
      letter-spacing: 0;
      color: #000000;
      padding: 10px 15px;
      margin: 10px 0; }

.credit-navs-wrapper .appNumber {
  max-width: 150px;
  background: #4687f4;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  padding: 5px;
  border-radius: 0px 0px 13px 13px;
  position: relative; }
  .credit-navs-wrapper .appNumber:hover {
    cursor: pointer; }
    .credit-navs-wrapper .appNumber:hover .textOnHover {
      visibility: visible; }
  .credit-navs-wrapper .appNumber .textOnHover {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-left: 20px; }

.credit-navs-wrapper.credit-navs-wrapper-lead-manager .credit-navs {
  justify-content: flex-start; }
  .credit-navs-wrapper.credit-navs-wrapper-lead-manager .credit-navs li {
    margin-right: 5%; }

.header-wrapper .navbar {
  background-color: #fff;
  border: none;
  padding: 15px 0;
  margin-bottom: 0; }
  .header-wrapper .navbar .navbar-header .logo {
    display: inline-block;
    text-align: center;
    float: none;
    padding: 0;
    height: auto;
    margin-top: 1rem; }
    .header-wrapper .navbar .navbar-header .logo img {
      height: 2.8rem;
      display: inline-block; }
    .header-wrapper .navbar .navbar-header .logo span {
      font-size: 16px;
      font-family: "Helvetica Bold";
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #001061;
      display: block; }
  .header-wrapper .navbar .navbar-collapse {
    width: 100%; }
    .header-wrapper .navbar .navbar-collapse .navbar-nav {
      margin: 15px 0; }
      .header-wrapper .navbar .navbar-collapse .navbar-nav > li {
        margin: 0 20px; }
        .header-wrapper .navbar .navbar-collapse .navbar-nav > li.fixed {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          z-index: 1001; }
        .header-wrapper .navbar .navbar-collapse .navbar-nav > li a,
        .header-wrapper .navbar .navbar-collapse .navbar-nav > li span {
          font-family: Helvetica;
          font-size: 18px;
          line-height: 1.21;
          letter-spacing: 0;
          color: #717171;
          padding: 0; }
        .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown {
          width: auto; }
          .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
            padding: 10px 15px;
            border-radius: 5px; }
            .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li a {
              padding: 0 10px; }
            .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li:first-child > a {
              background: transparent; }
              .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li:first-child > a:hover, .header-wrapper .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu li:first-child > a:focus {
                background: transparent; }
      .header-wrapper .navbar .navbar-collapse .navbar-nav > .open > a {
        background: transparent; }
        .header-wrapper .navbar .navbar-collapse .navbar-nav > .open > a:hover, .header-wrapper .navbar .navbar-collapse .navbar-nav > .open > a:focus {
          background: transparent; }

.header-wrapper .search-wrapper {
  display: inline-block;
  width: 400px;
  margin: 10px 0;
  margin-right: 20px; }
  .header-wrapper .search-wrapper .search-border {
    display: flex;
    align-items: center;
    border: 1px solid #adadad;
    border-radius: 2rem;
    margin-left: 2rem;
    width: 100%;
    overflow: hidden; }
    .header-wrapper .search-wrapper .search-border .search-form {
      display: flex;
      align-items: center;
      width: 100%; }
      .header-wrapper .search-wrapper .search-border .search-form .select {
        min-width: 125px;
        /* Increased from 120px */
        width: 30%;
        /* Added to make it take up more space */
        max-width: 200px;
        /* Added to prevent it from becoming too wide */
        outline: none;
        padding: 0.5rem 1rem;
        color: #bcbcbc;
        border: none;
        border-right: 1px solid grey;
        flex-shrink: 0; }
      .header-wrapper .search-wrapper .search-border .search-form option {
        color: #1d1d1d; }
      .header-wrapper .search-wrapper .search-border .search-form .search {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 190px;
        border: none;
        padding: 0.5rem 1rem;
        outline: none; }
      .header-wrapper .search-wrapper .search-border .search-form .submit {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: #4687f4;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        padding: 0;
        flex-shrink: 0; }
        .header-wrapper .search-wrapper .search-border .search-form .submit img {
          width: 1.3rem;
          height: auto; }

.header-wrapper .searchWrapper {
  display: flex; }
  .header-wrapper .searchWrapper .search-icon {
    color: #fff;
    background-color: #4687f4;
    cursor: pointer;
    margin-left: 1px;
    padding: 9px 15px 13px;
    display: inline-block; }
  .header-wrapper .searchWrapper .header-type-search {
    width: 300px;
    border: 1px solid #bcbcbc;
    padding: 10px 12px 12px;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #000; }
  .header-wrapper .searchWrapper .header-select-search {
    width: 125px; }
    .header-wrapper .searchWrapper .header-select-search > div {
      border-radius: 0;
      border: 1px solid #bcbcbc;
      padding: 3px 0 2px;
      box-shadow: none; }
    .header-wrapper .searchWrapper .header-select-search input:not([type="hidden"]) {
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      text-align: left;
      color: #000; }
    .header-wrapper .searchWrapper .header-select-search .select__indicators > span {
      display: none; }
    .header-wrapper .searchWrapper .header-select-search .select__menu {
      font-size: 14px; }
    .header-wrapper .searchWrapper .header-select-search .select__placeholder {
      color: #bcbcbc;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      text-align: left; }
  .header-wrapper .searchWrapper .header-type-search::placeholder,
  .header-wrapper .searchWrapper .header-select-search input::placeholder {
    color: #bababa; }

.header-wrapper .notification-icon__wrapper {
  position: relative; }
  .header-wrapper .notification-icon__wrapper .new-notification__circle {
    background-color: red;
    border-radius: 1000px;
    padding: 5px;
    position: absolute;
    top: 1px;
    right: 2px;
    transition: 5s ease; }
  .header-wrapper .notification-icon__wrapper .circle1 {
    animation: waves 2.5s linear 4s forwards; }
  .header-wrapper .notification-icon__wrapper .circle2 {
    animation: waves 2.5s linear 7s forwards; }
  .header-wrapper .notification-icon__wrapper .circle3 {
    animation: waves 2.5s linear 9s forwards; }

@media (min-width: 768px) {
  .header-wrapper .navbar .navbar-collapse .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header-wrapper .navbar .navbar-collapse .navbar-nav li:first-child {
      margin-left: 0; }
    .header-wrapper .navbar .navbar-collapse .navbar-nav li:last-child {
      margin-right: 0; } }

@media (max-width: 768px) {
  .header-wrapper .navbar {
    margin-bottom: 0; }
    .header-wrapper .navbar .navbar-header .logo {
      padding: 10px 40px; }
      .header-wrapper .navbar .navbar-header .logo img {
        width: 30px;
        height: 20px;
        display: inline-block; }
      .header-wrapper .navbar .navbar-header .logo span {
        font-size: 10px;
        line-height: 1; } }

.notification.popover {
  margin-top: 20px;
  max-width: 450px; }
  .notification.popover .popover-title {
    font-size: 22px; }
  .notification.popover .popover-content {
    padding: 0; }
    .notification.popover .popover-content .green-background {
      background-color: #4687f4;
      color: #fff;
      font-size: 18px;
      padding: 8px 14px; }
    .notification.popover .popover-content .blue-background {
      background-color: #4687f4;
      color: #fff;
      font-size: 18px;
      padding: 8px 14px; }
    .notification.popover .popover-content .notification__wrapper {
      padding: 8px 14px;
      position: relative;
      display: flex;
      align-items: center; }
      .notification.popover .popover-content .notification__wrapper:not(:last-child) {
        border-bottom: 1px solid #dddfe2; }
      .notification.popover .popover-content .notification__wrapper .identifier-circle {
        border-radius: 11100px;
        height: 40px;
        width: 40px;
        margin-right: 20px;
        position: absolute; }
        .notification.popover .popover-content .notification__wrapper .identifier-circle p {
          color: #fff;
          font-weight: 900 !important;
          text-align: center;
          text-transform: uppercase;
          transform: translateY(10px); }
      .notification.popover .popover-content .notification__wrapper .right-content {
        margin-left: 55px; }
        .notification.popover .popover-content .notification__wrapper .right-content .notification-message {
          color: #1d1d1d; }
        .notification.popover .popover-content .notification__wrapper .right-content .time-period__wrapper {
          display: flex;
          justify-content: space-between;
          color: #9498a0; }
          .notification.popover .popover-content .notification__wrapper .right-content .time-period__wrapper .time svg {
            transform: translateY(-2px); }

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(3);
    opacity: 0; } }

.dashboard-wrapper .user-list-dropdown__control {
  height: 50px; }

.dashboard-wrapper .underwriters-modal {
  width: 500px;
  padding: 20px;
  margin: 15px;
  background-color: #fff;
  border-radius: 20px;
  height: auto; }
  .dashboard-wrapper .underwriters-modal::-webkit-scrollbar {
    height: 3px; }
  .dashboard-wrapper .underwriters-modal::-webkit-scrollbar-button {
    display: none; }
  .dashboard-wrapper .underwriters-modal::-webkit-scrollbar-track {
    background: transparent; }
  .dashboard-wrapper .underwriters-modal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #4687F4; }
  .dashboard-wrapper .underwriters-modal .box-header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .dashboard-wrapper .underwriters-modal .box-header h4 {
      font-family: 'Helvetica';
      font-size: 16px;
      line-height: 20px;
      color: #1d1d1d;
      margin: 0; }
    .dashboard-wrapper .underwriters-modal .box-header .cross-icon {
      color: #bcbcbc;
      cursor: pointer;
      margin-top: 0; }
  .dashboard-wrapper .underwriters-modal h3 {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.73;
    color: #1d1d1d; }
  .dashboard-wrapper .underwriters-modal .underwriters-box .search-box {
    border-bottom: 1px solid #707070; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .search-box span {
      color: #bcbcbc;
      margin-right: 5px; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .search-box input {
      border: none;
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.14; }
  .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list {
    list-style: none;
    max-height: 200px;
    overflow-y: scroll; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list::-webkit-scrollbar {
      width: 3px; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list::-webkit-scrollbar-button {
      display: none; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list::-webkit-scrollbar-track {
      background: transparent; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #4687F4; }
    .dashboard-wrapper .underwriters-modal .underwriters-box .underwriters-list li {
      margin: 10px 0; }
  .dashboard-wrapper .underwriters-modal button {
    font-family: 'Helvetica bold';
    font-size: 14px;
    line-height: 1.19;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    background-color: #4687F4;
    width: 100px;
    padding: 10px;
    margin-top: 20px; }

.dashboard-wrapper .daterange-bar-wrapper {
  background-color: #F2F3F7;
  padding: 10px 0; }
  .dashboard-wrapper .daterange-bar-wrapper .add-btn {
    border-radius: 25px;
    background-color: #4687F4;
    border: none;
    font-family: Helvetica;
    font-size: 14px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff;
    padding: 7px 15px;
    display: flex;
    align-items: center;
    float: left;
    width: 16%; }
    .dashboard-wrapper .daterange-bar-wrapper .add-btn span:last-child {
      text-align: left; }
  .dashboard-wrapper .daterange-bar-wrapper .daterange-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    float: right; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box .search-icon {
      color: #fff;
      background-color: #4687F4;
      cursor: pointer;
      padding: 3px 10px 13px;
      display: inline-block; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box > span {
      color: #000;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      margin-right: 25px; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box button {
      background-color: transparent;
      border: 1px solid #000;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: normal;
      color: #000;
      padding: 7px 10px;
      min-width: 350px;
      display: flex;
      justify-content: space-around;
      align-items: center; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box .icons {
      margin-top: -3px; }
    .dashboard-wrapper .daterange-bar-wrapper .daterange-box .clear-filter {
      color: white;
      border-radius: 10px;
      background-color: #4687F4;
      padding: 4px 10px; }
      .dashboard-wrapper .daterange-bar-wrapper .daterange-box .clear-filter:hover {
        cursor: pointer; }
  .dashboard-wrapper .daterange-bar-wrapper .mobile-sub-header {
    display: flex;
    padding: 0 20px;
    justify-content: space-between; }
    .dashboard-wrapper .daterange-bar-wrapper .mobile-sub-header .add-btn {
      width: 60%;
      display: flex;
      justify-content: center; }
    .dashboard-wrapper .daterange-bar-wrapper .mobile-sub-header .daterange-box button {
      min-width: 50px; }

.dashboard-wrapper .applicants-list-wrapper {
  width: 90%;
  margin: 25px auto 30px; }
  .dashboard-wrapper .applicants-list-wrapper ul {
    display: flex;
    justify-content: space-around;
    align-items: center; }
    .dashboard-wrapper .applicants-list-wrapper ul li {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding: 0 2px 12px;
      text-align: center;
      border-bottom: 2px solid transparent; }
      .dashboard-wrapper .applicants-list-wrapper ul li.active {
        border-bottom-color: #4687F4; }
      .dashboard-wrapper .applicants-list-wrapper ul li span:first-child {
        font-family: Helvetica;
        font-size: 24px;
        line-height: 0.94;
        letter-spacing: normal;
        color: #717171;
        margin-bottom: 1px; }
      .dashboard-wrapper .applicants-list-wrapper ul li span:last-child {
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.04;
        letter-spacing: 0;
        color: #717171; }

.dashboard-wrapper .mobile-applicants-list-wrapper {
  margin: 30px 20px;
  overflow-x: scroll; }
  .dashboard-wrapper .mobile-applicants-list-wrapper::-webkit-scrollbar {
    display: none; }
  .dashboard-wrapper .mobile-applicants-list-wrapper ul {
    display: flex; }
    .dashboard-wrapper .mobile-applicants-list-wrapper ul li {
      display: flex;
      margin-right: 40px;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding: 0 2px 12px;
      text-align: center;
      border-bottom: 2px solid transparent; }
      .dashboard-wrapper .mobile-applicants-list-wrapper ul li.active {
        border-bottom-color: #4687f4; }
      .dashboard-wrapper .mobile-applicants-list-wrapper ul li span:first-child {
        font-family: Helvetica;
        font-size: 22px;
        line-height: 0.94;
        letter-spacing: normal;
        color: #001061;
        margin-bottom: 1px; }
      .dashboard-wrapper .mobile-applicants-list-wrapper ul li span:last-child {
        font-family: Helvetica;
        font-size: 18px;
        line-height: 1.04;
        letter-spacing: 0;
        color: #001061;
        margin-top: 6px; }

.dashboard-wrapper .datatable-wrapper {
  margin-bottom: 25px; }
  .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table {
    overflow-x: scroll;
    width: 100%; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table::-webkit-scrollbar {
      height: 10px; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table::-webkit-scrollbar-button {
      display: none; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table::-webkit-scrollbar-track {
      background: transparent; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-table::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #4687F4; }
  .dashboard-wrapper .datatable-wrapper .ReactTable .rt-th {
    background-color: #4687F4;
    color: #fff;
    padding: 10px 0 !important;
    border-right: 1px solid #fff !important; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-th.-sort-asc::after {
      content: '\f0d7';
      position: absolute;
      font-family: FontAwesome;
      right: 9px;
      top: 10px;
      font-size: 16px; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-th.-sort-desc::after {
      content: '\f0d8';
      position: absolute;
      font-family: FontAwesome;
      right: 9px;
      top: 10px;
      font-size: 16px; }
  .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tbody .rt-tr {
    cursor: pointer; }
    .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
      text-align: center;
      padding: 25px 0 !important;
      white-space: normal; }
      .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .edit-icon {
        color: #5f5f5f;
        border-bottom: 1px solid #5f5f5f;
        padding-bottom: 2px; }
      .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .search-icon {
        color: #5f5f5f; }
  .dashboard-wrapper .datatable-wrapper .ReactTable .rt-tr.-odd {
    background-color: #f7f9fc; }
  .dashboard-wrapper .datatable-wrapper .datatable-pagination {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
    .dashboard-wrapper .datatable-wrapper .datatable-pagination li {
      flex: 1;
      text-align: center; }
      .dashboard-wrapper .datatable-wrapper .datatable-pagination li button {
        width: 100%;
        padding: 10px;
        border: none; }

.error-container {
  border: 1px solid #b53629;
  width: 100%;
  max-width: 300px;
  margin: 2em auto;
  padding: 1em;
  border-radius: 10px; }
  .error-container .icon {
    color: #b53629; }
  .error-container .error-line {
    color: #b53629;
    font-size: 100%;
    text-align: left; }
  .error-container .error {
    font-size: 1em;
    margin-top: 5px;
    color: #1d1d1d;
    text-align: left; }

.signup-wrapper .content-box {
  padding: 35px 50px;
  width: 750px;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #fff; }

.signup-wrapper img {
  height: 40rem; }

@media screen and (max-width: 768px) {
  .signup-wrapper {
    width: 95%;
    padding: 15px; } }

.signup-wrapper .box-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .signup-wrapper .box-header .heading {
    font-family: Helvetica;
    font-size: 24px;
    line-height: 1.2;
    color: #001061;
    margin: 0 10px 0 0;
    font-weight: bolder !important; }

.signup-wrapper .form-box {
  margin-top: 15px; }
  .signup-wrapper .form-box .dropdown {
    margin: 20px 0 15px; }
  .signup-wrapper .form-box .input-box {
    margin: 10px 0 15px; }
  .signup-wrapper .form-box .actions-box button {
    width: 100px;
    background-color: #4687F4;
    padding: 10px 12px 12px;
    border-radius: 25px;
    border: none;
    font-family: Helvetica;
    font-size: 18px;
    line-height: 0.89;
    letter-spacing: -0.1px;
    color: #ffffff;
    margin-bottom: 10px; }

#password-popover .popover-content b {
  font-family: "Helvetica Bold"; }

.login-wrapper .content-box {
  border-radius: 20px;
  background-color: #fff;
  width: 750px;
  margin: 0 auto; }
  .login-wrapper .content-box img {
    height: 40rem; }
  @media screen and (max-width: 768px) {
    .login-wrapper .content-box {
      width: 95%;
      padding: 15px; } }
  .login-wrapper .content-box .heading {
    font-family: Helvetica;
    font-size: 24px;
    line-height: 1.2;
    color: #001061;
    margin: 0 0 25px 0;
    font-weight: bolder !important;
    padding-left: 15px; }
  .login-wrapper .content-box .dropdown {
    margin-top: 25px; }
  .login-wrapper .content-box .form-box .input-box,
  .login-wrapper .content-box .form-box .remember-box {
    margin: 20px 0; }
  .login-wrapper .content-box .form-box .input-box:first-child {
    margin-top: 0; }
  .login-wrapper .content-box .form-box .remember-box {
    display: flex;
    align-items: center; }
    .login-wrapper .content-box .form-box .remember-box input[type='checkbox'] {
      height: 15px;
      width: 15px;
      margin: 0 7px 0 0; }
    .login-wrapper .content-box .form-box .remember-box label {
      font-size: 16px; }
  .login-wrapper .content-box .form-box .actions-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px; }
    .login-wrapper .content-box .form-box .actions-box button {
      width: 125px;
      background-color: #4687F4;
      padding: 10px 12px 12px;
      border-radius: 25px;
      border: none;
      font-family: Helvetica;
      font-size: 18px;
      line-height: 0.89;
      letter-spacing: -0.1px;
      color: #ffffff; }
    .login-wrapper .content-box .form-box .actions-box button,
    .login-wrapper .content-box .form-box .actions-box span {
      margin-bottom: 10px; }
    .login-wrapper .content-box .form-box .actions-box a {
      margin-top: 15px; }

@media screen and (max-width: 500px) {
  .content-wrapper {
    padding: 20px !important; }
  .login-wrapper .content-box {
    padding: 15px; } }

@media screen and (max-width: 500px) and (max-width: 768px) {
  .login-wrapper {
    width: 95%;
    padding: 15px; } }

@media screen and (max-width: 500px) {
  .login-wrapper [class*='col-'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 10px !important; } }

.forgot-password-wrapper .content-box {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 25px;
  width: 500px;
  margin: 0 auto;
  position: relative;
  text-align: center; }
  .forgot-password-wrapper .content-box .heading {
    font-family: Helvetica;
    font-size: 24px;
    line-height: 1.2;
    color: #001061;
    margin: 0 0 25px 0; }
  .forgot-password-wrapper .content-box .go-back {
    display: flex;
    align-items: center;
    position: absolute;
    left: 15px;
    top: 30px;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.14;
    color: #29a9e0;
    cursor: pointer; }
    .forgot-password-wrapper .content-box .go-back svg {
      margin-top: -3px;
      height: 21px; }
  .forgot-password-wrapper .content-box p {
    width: 75%;
    font-family: Helvetica;
    font-size: 14px;
    line-height: 1.14;
    margin: 25px auto;
    color: #5d5d5d; }
  .forgot-password-wrapper .content-box .form-box {
    width: 55%;
    margin: 0 auto 20px; }
    .forgot-password-wrapper .content-box .form-box .btns-box {
      margin-top: 20px; }
      .forgot-password-wrapper .content-box .form-box .btns-box button {
        width: 100%;
        background-color: #4687F4;
        padding: 10px 12px 12px;
        border-radius: 25px;
        border: none;
        font-family: Helvetica;
        font-size: 18px;
        line-height: 0.89;
        letter-spacing: -0.1px;
        color: #ffffff;
        margin-bottom: 10px; }

.help-wrapper {
  background-color: #fafafa;
  padding: 20px 0; }
  .help-wrapper .content-box {
    width: 95%;
    margin: 0 auto; }
    .help-wrapper .content-box .box {
      margin: 10px 0 25px; }
      .help-wrapper .content-box .box .box-label {
        font-family: "Helvetica Bold";
        font-size: 20px;
        line-height: 0.89;
        letter-spacing: -0.1px;
        text-align: left;
        color: #000;
        margin: 0 0 15px 7px; }
      .help-wrapper .content-box .box .box-content {
        border-radius: 20px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff; }
        .help-wrapper .content-box .box .box-content ul li {
          display: flex;
          margin-top: 3px;
          color: #1d1d1d; }
          .help-wrapper .content-box .box .box-content ul li span:last-child {
            font-family: Helvetica;
            font-size: 16px;
            line-height: 1.21;
            letter-spacing: 0;
            margin-left: 7px; }
    .help-wrapper .content-box .support-box .box-content {
      width: 350px;
      padding: 10px 20px; }
      .help-wrapper .content-box .support-box .box-content ul li {
        color: #001061;
        align-items: center; }
  .help-wrapper .doc-box .box-content,
  .help-wrapper .faq-box .box-content {
    padding: 20px 25px; }
    .help-wrapper .doc-box .box-content ul,
    .help-wrapper .faq-box .box-content ul {
      margin-bottom: 25px; }
      .help-wrapper .doc-box .box-content ul li,
      .help-wrapper .faq-box .box-content ul li {
        margin: 12px 0; }

.faq-wrapper b {
  font-weight: bold !important;
  padding-left: 20px; }

.faq-wrapper .heading {
  font-family: "Helvetica Bold";
  font-size: 26px;
  line-height: 30px;
  margin-top: 20px;
  color: #000; }

.faq-wrapper .nav-tabs {
  margin: 30px 30px 0;
  border-color: #bcbcbc; }
  .faq-wrapper .nav-tabs > li {
    margin: 0 4px -1px;
    background-color: #e5e5e5;
    border-radius: 5px 5px 0 0;
    position: relative; }
    .faq-wrapper .nav-tabs > li a {
      color: #1d1d1d;
      border-radius: 5px 5px 0 0;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      padding: 12px 24px; }
      .faq-wrapper .nav-tabs > li a label {
        font-family: "Helvetica Bold";
        margin-bottom: 0; }
    .faq-wrapper .nav-tabs > li.active::after {
      content: '';
      width: 75%;
      height: 2px;
      background-color: #4687f4;
      position: absolute;
      bottom: 0;
      left: 13%; }
    .faq-wrapper .nav-tabs > li.active a {
      color: #1d1d1d;
      margin-right: 0;
      font-family: "Helvetica Bold"; }

.faq-wrapper .tab-content {
  border-right: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  border-left: 1px solid #bcbcbc;
  border-top: 2px solid #e5e5e5;
  border-radius: 25px;
  margin-top: -2px;
  margin-bottom: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 30px; }
  .faq-wrapper .tab-content .box-content {
    background-color: #fafafa;
    border-radius: 12px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .faq-wrapper .tab-content .box-content .box-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
      .faq-wrapper .tab-content .box-content .box-header z .box-heading {
        margin-top: 0;
        font-family: "Helvetica Bold";
        font-size: 16px;
        line-height: 0.92;
        letter-spacing: normal;
        color: #4687f4; }
      .faq-wrapper .tab-content .box-content .box-header .edit-icon {
        color: #5f5f5f;
        border-bottom: 1px solid #5f5f5f;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 3px; }

.faq-wrapper .slide-wrapper {
  margin-bottom: 25px;
  background: #FFFFFF;
  box-shadow: 0 4px 20px rgba(186, 186, 186, 0.18);
  border-radius: 10px; }
  .faq-wrapper .slide-wrapper .slide-header {
    cursor: pointer;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .faq-wrapper .slide-wrapper .slide-header h4 {
      margin: 0;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 18px;
      color: #1b1b1b; }
    .faq-wrapper .slide-wrapper .slide-header .icon {
      background-color: #f1fbff;
      color: #00107f;
      border-radius: 10px; }
  .faq-wrapper .slide-wrapper .react-slidedown {
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 10px; }
    .faq-wrapper .slide-wrapper .react-slidedown table {
      width: 796px;
      height: 265px;
      left: 302px;
      top: 378px;
      border: 1px solid #CDCDCD;
      box-sizing: border-box;
      border-radius: 10px;
      margin-left: 20px;
      margin-bottom: 20px; }
      .faq-wrapper .slide-wrapper .react-slidedown table thead tr:nth-child(odd) {
        background: #F3F3F3; }
      .faq-wrapper .slide-wrapper .react-slidedown table tbody tr:nth-child(even) {
        background: #F3F3F3; }
      .faq-wrapper .slide-wrapper .react-slidedown table th {
        width: 696px;
        height: 53px;
        left: 302px;
        top: 378px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #5A5A5A; }
        .faq-wrapper .slide-wrapper .react-slidedown table th span {
          width: 116px;
          height: 18px;
          left: 703px;
          top: 396px;
          font-weight: bold;
          font-family: Helvetica;
          font-size: 18px;
          line-height: 130%;
          color: #5A5A5A; }
      .faq-wrapper .slide-wrapper .react-slidedown table td {
        width: 696px;
        height: 40px;
        left: 336px;
        top: 471px;
        text-align: center;
        border: 0.5px solid #CDCDCD;
        box-sizing: border-box; }
    .faq-wrapper .slide-wrapper .react-slidedown ul li {
      padding-left: 20px;
      list-style-type: circle; }
    .faq-wrapper .slide-wrapper .react-slidedown p {
      max-width: 800px;
      text-align: justify;
      text-justify: inter-word;
      padding-left: 20px; }
    .faq-wrapper .slide-wrapper .react-slidedown h5 {
      padding-left: 20px;
      font-weight: 700; }
  .faq-wrapper .slide-wrapper.open .slide-header h4 {
    font-family: "Helvetica Bold"; }
  .faq-wrapper .slide-wrapper.open .slide-header .icon {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-name: iconDown;
    animation-name: iconDown;
    animation-duration: .35s;
    transform: rotate(0deg); }
  .faq-wrapper .slide-wrapper.closed .slide-header .icon {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-name: iconUp;
    animation-name: iconUp;
    animation-duration: .35s;
    transform: rotate(180deg); }
  .faq-wrapper .slide-wrapper:last-child {
    margin-bottom: 0; }

.applicant-guide-wrapper {
  background-color: #fafafa;
  padding: 20px 0; }
  .applicant-guide-wrapper .content-box {
    width: 950px;
    margin: 0 auto;
    padding: 35px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative; }
    @media screen and (max-width: 768px) {
      .applicant-guide-wrapper .content-box {
        width: 95%;
        padding: 15px; } }
    .applicant-guide-wrapper .content-box .back-btn-link {
      display: flex;
      align-items: center;
      position: absolute;
      left: 25px;
      top: 40px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.14;
      color: #29a9e0;
      cursor: pointer; }
      .applicant-guide-wrapper .content-box .back-btn-link svg {
        margin-top: -3px;
        height: 21px; }
    .applicant-guide-wrapper .content-box .box-heading {
      font-family: 'Helvetica';
      font-size: 26px;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #001061;
      margin-top: 0;
      margin-bottom: 25px; }
      @media screen and (max-width: 768px) {
        .applicant-guide-wrapper .content-box .box-heading {
          margin-top: 60px; } }
  .applicant-guide-wrapper .declaration-container {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      .applicant-guide-wrapper .declaration-container {
        display: block; } }
    .applicant-guide-wrapper .declaration-container .declaration-box {
      border: 1px solid #bcbcbc;
      border-radius: 25px;
      background-color: #fafafa; }
      .applicant-guide-wrapper .declaration-container .declaration-box:first-child {
        margin-right: 10px; }
        @media screen and (max-width: 768px) {
          .applicant-guide-wrapper .declaration-container .declaration-box:first-child {
            margin-right: 0px; } }
      .applicant-guide-wrapper .declaration-container .declaration-box:last-child {
        margin-left: 10px; }
        @media screen and (max-width: 768px) {
          .applicant-guide-wrapper .declaration-container .declaration-box:last-child {
            margin-left: 0px;
            margin-top: 20px; } }
      .applicant-guide-wrapper .declaration-container .declaration-box .statement {
        padding: 10px 20px; }
        .applicant-guide-wrapper .declaration-container .declaration-box .statement label {
          font-family: 'Helvetica Bold';
          font-size: 12px;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: left;
          color: #1d1d1d; }
        .applicant-guide-wrapper .declaration-container .declaration-box .statement p {
          font-family: Helvetica;
          font-size: 12px;
          line-height: 1.19;
          text-align: left;
          color: #1d1d1d; }
        .applicant-guide-wrapper .declaration-container .declaration-box .statement ul {
          margin-bottom: 0;
          margin-top: 10px; }
          .applicant-guide-wrapper .declaration-container .declaration-box .statement ul li {
            margin: 10px 0; }
            .applicant-guide-wrapper .declaration-container .declaration-box .statement ul li:first-child {
              margin-top: 0; }
            .applicant-guide-wrapper .declaration-container .declaration-box .statement ul li:last-child {
              margin-bottom: 0; }
  .applicant-guide-wrapper .btns-box {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; }
    .applicant-guide-wrapper .btns-box button {
      border-radius: 25px;
      background-color: #4687f4;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #ffffff;
      border: none;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .applicant-guide-wrapper .btns-box .save-btn {
      width: 225px;
      border-radius: 25px;
      background-color: #4687F4;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #ffffff;
      border: none;
      padding: 10px;
      margin-top: 10px;
      justify-content: center; }
      @media screen and (max-width: 768px) {
        .applicant-guide-wrapper .btns-box .save-btn {
          width: 100%; } }
  .applicant-guide-wrapper .form-wrapper {
    margin-top: 25px; }
    .applicant-guide-wrapper .form-wrapper .form-box {
      border-radius: 25px;
      background-color: #fafafa;
      padding: 30px;
      border: 1px solid #bcbcbc;
      margin-top: 10px;
      position: relative; }
      .applicant-guide-wrapper .form-wrapper .form-box .remove-box-icon {
        position: absolute;
        color: #ff0000;
        right: 30px;
        top: 30px;
        cursor: pointer; }
        .applicant-guide-wrapper .form-wrapper .form-box .remove-box-icon:hover {
          -webkit-transform-origin: top center;
          transform-origin: top center;
          -webkit-animation-name: swing;
          animation-name: swing;
          animation-duration: 0.5s; }
      .applicant-guide-wrapper .form-wrapper .form-box h3 {
        font-family: 'Helvetica Bold';
        font-size: 18px;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: #001061;
        margin: 0 0 10px 0; }
      .applicant-guide-wrapper .form-wrapper .form-box .inputs-row {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        border-bottom: 1px solid #d9d8d8;
        padding-bottom: 10px;
        position: relative; }
        @media screen and (max-width: 768px) {
          .applicant-guide-wrapper .form-wrapper .form-box .inputs-row {
            display: block; } }
        .applicant-guide-wrapper .form-wrapper .form-box .inputs-row:last-child {
          border-bottom: none; }
        .applicant-guide-wrapper .form-wrapper .form-box .inputs-row .input-box {
          width: 25%;
          margin: 0 5px; }
          @media screen and (max-width: 768px) {
            .applicant-guide-wrapper .form-wrapper .form-box .inputs-row .input-box {
              width: 100%;
              margin: 15px 0; } }
        .applicant-guide-wrapper .form-wrapper .form-box .inputs-row .dropdown {
          max-width: 20%;
          margin: 0; }
          @media screen and (max-width: 768px) {
            .applicant-guide-wrapper .form-wrapper .form-box .inputs-row .dropdown {
              max-width: 100%;
              margin: 0; } }
          .applicant-guide-wrapper .form-wrapper .form-box .inputs-row .dropdown button {
            text-overflow: ellipsis;
            overflow: hidden; }
        .applicant-guide-wrapper .form-wrapper .form-box .inputs-row > label {
          font-family: Helvetica;
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: normal;
          color: #1d1d1d;
          width: 135px;
          margin-bottom: 0; }
        .applicant-guide-wrapper .form-wrapper .form-box .inputs-row > *:last-child {
          display: flex;
          align-items: center;
          width: 45px; }
        .applicant-guide-wrapper .form-wrapper .form-box .inputs-row .check-icon {
          background-color: #78e35f;
          color: #fff;
          border-radius: 25px;
          padding: 2px;
          cursor: pointer;
          margin: 5px 5px 5px 0;
          width: 25px;
          height: 26px; }
        .applicant-guide-wrapper .form-wrapper .form-box .inputs-row .remove-icon {
          color: #ff0000;
          cursor: pointer;
          display: inline-block; }
          @media screen and (max-width: 768px) {
            .applicant-guide-wrapper .form-wrapper .form-box .inputs-row .remove-icon {
              position: absolute;
              top: 0px;
              right: 10px; } }
          .applicant-guide-wrapper .form-wrapper .form-box .inputs-row .remove-icon:hover {
            -webkit-transform-origin: top center;
            transform-origin: top center;
            -webkit-animation-name: swing;
            animation-name: swing;
            animation-duration: 0.5s; }
      .applicant-guide-wrapper .form-wrapper .form-box .add-btn {
        width: 225px;
        border-radius: 25px;
        background-color: #4687F4;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #ffffff;
        border: none;
        padding: 10px;
        margin: 10px 0 0 auto; }
        @media screen and (max-width: 768px) {
          .applicant-guide-wrapper .form-wrapper .form-box .add-btn {
            width: 100%; } }

.applicant-guide-modal {
  width: 425px;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 25px;
  position: relative; }
  .applicant-guide-modal .cross-icon {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #bcbcbc;
    cursor: pointer; }
  .applicant-guide-modal h3 {
    font-family: Helvetica;
    font-size: 20px;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #1d1d1d;
    margin-top: 0; }
  .applicant-guide-modal .form-box .input-field,
  .applicant-guide-modal .form-box .dropdown {
    margin: 10px 0; }
  .applicant-guide-modal .form-box .submit-btn {
    width: 175px;
    border-radius: 25px;
    background-color: #29a9e0;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin-top: 25px; }
  .applicant-guide-modal .form-box .dropdown .select-box {
    color: #bcbcbc; }
    @media screen and (max-width: 768px) {
      .applicant-guide-modal .form-box .dropdown .select-box {
        max-width: 100%;
        margin: 15px 0; } }

.applicants-wrapper {
  background-color: #fafafa;
  padding: 30px 0; }
  .applicants-wrapper .content-box {
    width: 890px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px 40px;
    border-radius: 15px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .applicants-wrapper .content-box {
        width: 95%;
        padding: 15px; } }
    .applicants-wrapper .content-box .back-btn-link {
      display: flex;
      align-items: center;
      position: absolute;
      left: 25px;
      top: 25px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.14;
      color: #29a9e0;
      cursor: pointer; }
      .applicants-wrapper .content-box .back-btn-link svg {
        margin-top: -3px;
        height: 21px; }
    .applicants-wrapper .content-box .box-heading {
      font-family: 'Helvetica';
      font-size: 26px;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #001061;
      margin-top: 0;
      margin-bottom: 25px; }
      @media screen and (max-width: 768px) {
        .applicants-wrapper .content-box .box-heading {
          margin-top: 60px; } }
    .applicants-wrapper .content-box .btns-box .next-btn {
      background-color: #29a9e0;
      padding: 10px;
      width: 225px;
      border-radius: 25px;
      color: #fff;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      border: none; }
      @media screen and (max-width: 768px) {
        .applicants-wrapper .content-box .btns-box .next-btn {
          width: 90%; } }
    .applicants-wrapper .content-box > .btns-box {
      display: flex;
      align-items: center;
      flex-direction: column; }
    .applicants-wrapper .content-box .applicants-box > div:nth-child(n + 2) {
      margin-top: 10px; }
    .applicants-wrapper .content-box .applicants-box .form-box {
      border: 1px solid #eee;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #f5f5f5;
      padding: 25px;
      border-radius: 10px;
      width: 90%;
      position: relative;
      margin: 0 auto 25px; }
      .applicants-wrapper .content-box .applicants-box .form-box .select-box1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #1d1d1d;
        border: 1px solid #eee;
        border-radius: 7px;
        font-size: 14px;
        font-family: Helvetica;
        line-height: 1.14;
        letter-spacing: normal;
        padding: 11px 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 47px;
        width: 100%; }
      .applicants-wrapper .content-box .applicants-box .form-box select option {
        margin: 40px;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
        width: 100%; }
        .applicants-wrapper .content-box .applicants-box .form-box select option:first-child {
          color: #001061;
          font-family: Helvetica Bold;
          background-color: #eee;
          padding: 10px 12px; }
        .applicants-wrapper .content-box .applicants-box .form-box select option :focus {
          width: 100%; }
        .applicants-wrapper .content-box .applicants-box .form-box select option :hover {
          font-family: Helvetica Bold;
          background-color: #29a9e0;
          color: #fff; }
        .applicants-wrapper .content-box .applicants-box .form-box select option :active {
          font-family: Helvetica Bold;
          background-color: #29a9e0;
          color: #fff; }
      .applicants-wrapper .content-box .applicants-box .form-box h4 {
        margin: 0 0 15px;
        font-family: Helvetica;
        font-size: 21px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #1d1d1d; }
      .applicants-wrapper .content-box .applicants-box .form-box .checkbox-box {
        margin: 0 0 0 auto;
        justify-content: flex-end; }
      .applicants-wrapper .content-box .applicants-box .form-box .remove-icon {
        color: #ff0000;
        cursor: pointer;
        display: inline-block;
        margin-left: 10px; }
        .applicants-wrapper .content-box .applicants-box .form-box .remove-icon:hover {
          -webkit-transform-origin: top center;
          transform-origin: top center;
          -webkit-animation-name: swing;
          animation-name: swing;
          animation-duration: 0.5s; }
      .applicants-wrapper .content-box .applicants-box .form-box .dropdown {
        margin: 10px 0; }
        .applicants-wrapper .content-box .applicants-box .form-box .dropdown .select-box {
          color: #1d1d1d; }
      .applicants-wrapper .content-box .applicants-box .form-box .datepicker-box,
      .applicants-wrapper .content-box .applicants-box .form-box .input-box {
        margin: 10px 0; }
      .applicants-wrapper .content-box .applicants-box .form-box .input-field:disabled {
        background-color: #fff; }
      .applicants-wrapper .content-box .applicants-box .form-box .address-box .input-label,
      .applicants-wrapper .content-box .applicants-box .form-box .address-box .checkbox-box {
        margin-top: 15px; }
      .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search {
        margin: 10px 0; }
        .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search > div {
          border: 1px solid #eee;
          padding: 3px 0;
          box-shadow: none;
          border-radius: 7px; }
        .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search input:not([type='hidden']) {
          font-family: Helvetica;
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          text-align: left;
          color: #000; }
        .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search .select__single-value,
        .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search .select__menu {
          text-transform: capitalize; }
        .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search .select__indicators {
          display: none; }
        .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search .select__placeholder {
          color: #bcbcbc;
          font-family: Helvetica;
          font-size: 16px;
          line-height: 1.19;
          letter-spacing: 0;
          text-align: left; }
        .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search .select__control {
          position: relative;
          padding: 7px 0; }
          .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search .select__control::after {
            content: '\f0d7';
            position: absolute;
            font-family: FontAwesome;
            right: 10px;
            top: 15px;
            color: #bcbcbc;
            font-size: 12px; }
          .applicants-wrapper .content-box .applicants-box .form-box .pin-code-select-search .select__control.select__control--is-focused {
            box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }

.banking-wrapper {
  background-color: #fafafa;
  padding: 20px 0; }
  .banking-wrapper .content-box {
    width: 890px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px 40px;
    border-radius: 15px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .banking-wrapper .content-box {
        width: 95%;
        padding: 15px; } }
    .banking-wrapper .content-box .back-btn-link {
      display: flex;
      align-items: center;
      position: absolute;
      left: 25px;
      top: 25px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.14;
      color: #29a9e0;
      cursor: pointer; }
      .banking-wrapper .content-box .back-btn-link svg {
        margin-top: -3px;
        height: 21px; }
    .banking-wrapper .content-box .box-heading {
      font-family: 'Helvetica';
      font-size: 26px;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #001061;
      margin-top: 0;
      margin-bottom: 25px; }
      @media screen and (max-width: 768px) {
        .banking-wrapper .content-box .box-heading {
          margin-top: 60px; } }
    .banking-wrapper .content-box .forms-box-wrapper {
      margin-top: 25px; }
      .banking-wrapper .content-box .forms-box-wrapper .form-box {
        border: 1px solid #eee;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #f5f5f5;
        padding: 25px;
        border-radius: 10px;
        width: 90%;
        position: relative;
        margin: 0 auto; }
        @media screen and (max-width: 768px) {
          .banking-wrapper .content-box .forms-box-wrapper .form-box {
            padding: 15px; } }
        .banking-wrapper .content-box .forms-box-wrapper .form-box:nth-child(n + 2) {
          margin-top: 10px; }
          @media screen and (max-width: 768px) {
            .banking-wrapper .content-box .forms-box-wrapper .form-box:nth-child(n + 2) {
              margin-top: 20px; } }
        .banking-wrapper .content-box .forms-box-wrapper .form-box .box-header {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .banking-wrapper .content-box .forms-box-wrapper .form-box .box-header h5 {
            font-family: 'Helvetica Bold';
            font-size: 21px;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #1d1d1d; }
          .banking-wrapper .content-box .forms-box-wrapper .form-box .box-header .add-btn {
            background-color: #4687F4;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 0 0 0 auto;
            padding: 4px 15px 5px 10px;
            width: 150px;
            border-radius: 25px;
            color: #fff;
            font-family: Helvetica;
            font-size: 16px;
            line-height: 1.19;
            letter-spacing: 0;
            border: none; }
            @media screen and (max-width: 768px) {
              .banking-wrapper .content-box .forms-box-wrapper .form-box .box-header .add-btn {
                padding: 4px 8px;
                width: inherit; } }
          .banking-wrapper .content-box .forms-box-wrapper .form-box .box-header .remove-icon {
            color: #ff0000;
            cursor: pointer;
            margin-left: 10px;
            display: inline-block; }
            .banking-wrapper .content-box .forms-box-wrapper .form-box .box-header .remove-icon:hover {
              -webkit-transform-origin: top center;
              transform-origin: top center;
              -webkit-animation-name: swing;
              animation-name: swing;
              animation-duration: 0.5s; }
        .banking-wrapper .content-box .forms-box-wrapper .form-box .bank-select-search {
          margin-top: 15px;
          width: 50%; }
          @media screen and (max-width: 768px) {
            .banking-wrapper .content-box .forms-box-wrapper .form-box .bank-select-search {
              width: 100%; } }
          .banking-wrapper .content-box .forms-box-wrapper .form-box .bank-select-search > div {
            border: 1px solid #bcbcbc;
            padding: 3px 0 2px;
            box-shadow: none;
            border-radius: 7px; }
          .banking-wrapper .content-box .forms-box-wrapper .form-box .bank-select-search input:not([type='hidden']) {
            font-family: Helvetica;
            font-size: 14px;
            line-height: 1.19;
            letter-spacing: 0;
            text-align: left;
            color: #000; }
          .banking-wrapper .content-box .forms-box-wrapper .form-box .bank-select-search .select__single-value,
          .banking-wrapper .content-box .forms-box-wrapper .form-box .bank-select-search .select__menu {
            text-transform: capitalize; }
          .banking-wrapper .content-box .forms-box-wrapper .form-box .bank-select-search .select__indicators > span {
            display: none; }
          .banking-wrapper .content-box .forms-box-wrapper .form-box .bank-select-search .select__placeholder {
            color: #bcbcbc;
            font-family: Helvetica;
            font-size: 16px;
            line-height: 1.19;
            letter-spacing: 0;
            text-align: left; }
        .banking-wrapper .content-box .forms-box-wrapper .form-box .account-type-box {
          margin-top: 25px;
          display: flex;
          justify-content: space-between; }
          .banking-wrapper .content-box .forms-box-wrapper .form-box .account-type-box .account-type {
            flex: 1; }
            .banking-wrapper .content-box .forms-box-wrapper .form-box .account-type-box .account-type > div {
              display: flex;
              align-items: center;
              margin-bottom: 10px; }
              .banking-wrapper .content-box .forms-box-wrapper .form-box .account-type-box .account-type > div .input-label {
                margin-bottom: 0; }
            .banking-wrapper .content-box .forms-box-wrapper .form-box .account-type-box .account-type .input-field {
              width: 90%; }
              .banking-wrapper .content-box .forms-box-wrapper .form-box .account-type-box .account-type .input-field.readonly {
                background-color: rgba(0, 0, 0, 0.05);
                cursor: not-allowed; }
        .banking-wrapper .content-box .forms-box-wrapper .form-box .check-box {
          margin-left: -1.6rem; }
        .banking-wrapper .content-box .forms-box-wrapper .form-box:nth-child(n + 1) {
          margin-top: 15px; }
          @media screen and (max-width: 768px) {
            .banking-wrapper .content-box .forms-box-wrapper .form-box:nth-child(n + 1) {
              margin-top: 30px; } }
      .banking-wrapper .content-box .forms-box-wrapper .btns-box {
        text-align: center;
        margin-top: 15px; }
        .banking-wrapper .content-box .forms-box-wrapper .btns-box .next-btn {
          width: 125px;
          border-radius: 25px;
          color: #fff;
          font-family: Helvetica;
          font-size: 16px;
          line-height: 1.19;
          letter-spacing: 0;
          border: none;
          background-color: #29a9e0;
          padding: 10px; }
          @media screen and (max-width: 768px) {
            .banking-wrapper .content-box .forms-box-wrapper .btns-box .next-btn {
              width: 90%;
              margin-top: 20px; } }
  .banking-wrapper .files-wrapper {
    margin: 35px 0 10px; }
    .banking-wrapper .files-wrapper h3 {
      font-family: Helvetica;
      font-size: 18px;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #1d1d1d;
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid #d9d8d8; }
    .banking-wrapper .files-wrapper button {
      border-radius: 25px;
      background-color: #4687F4;
      font-family: Helvetica;
      line-height: 1.19;
      letter-spacing: 0;
      color: #ffffff;
      border: none; }
    .banking-wrapper .files-wrapper > button {
      display: inline-flex;
      width: 235px;
      margin: 15px 0 0;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      padding: 10px; }
      @media screen and (max-width: 768px) {
        .banking-wrapper .files-wrapper > button {
          width: 100%; } }
    .banking-wrapper .files-wrapper .files-box {
      margin-bottom: 15px; }
      .banking-wrapper .files-wrapper .files-box .inputs-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px; }
        @media screen and (max-width: 768px) {
          .banking-wrapper .files-wrapper .files-box .inputs-row {
            margin-top: 20px; } }
        .banking-wrapper .files-wrapper .files-box .inputs-row .input-field {
          width: 200px; }
          .banking-wrapper .files-wrapper .files-box .inputs-row .input-field:disabled {
            background-color: #fff; }
          @media screen and (max-width: 768px) {
            .banking-wrapper .files-wrapper .files-box .inputs-row .input-field {
              display: none; } }
        .banking-wrapper .files-wrapper .files-box .inputs-row input[type='file'] {
          display: none; }
        .banking-wrapper .files-wrapper .files-box .inputs-row button {
          font-size: 14px;
          padding: 5px 10px;
          width: 100px; }
        .banking-wrapper .files-wrapper .files-box .inputs-row .actions-box {
          width: 70px;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .banking-wrapper .files-wrapper .files-box .inputs-row .actions-box .success-icon {
            background-color: #8bd591;
            color: #fff;
            border-radius: 25px;
            padding: 3px 4px;
            cursor: pointer;
            opacity: 0; }
            .banking-wrapper .files-wrapper .files-box .inputs-row .actions-box .success-icon.visible {
              opacity: 1; }
          .banking-wrapper .files-wrapper .files-box .inputs-row .actions-box .delete-icon {
            color: #ed5a5a;
            cursor: pointer;
            margin-left: 5px; }
            .banking-wrapper .files-wrapper .files-box .inputs-row .actions-box .delete-icon:hover {
              -webkit-transform-origin: top center;
              transform-origin: top center;
              -webkit-animation-name: swing;
              animation-name: swing;
              animation-duration: 0.5s; }
      .banking-wrapper .files-wrapper .files-box .mobile-password {
        display: none; }
        @media screen and (max-width: 768px) {
          .banking-wrapper .files-wrapper .files-box .mobile-password {
            display: block; }
            .banking-wrapper .files-wrapper .files-box .mobile-password:disabled {
              background-color: #fff; } }
  .banking-wrapper .upload-button {
    background-color: #4687F4;
    width: 235px;
    border-radius: 200px;
    height: 43px;
    border: none;
    cursor: pointer;
    color: #fff;
    text-align: center; }
    .banking-wrapper .upload-button::placeholder {
      color: #fff;
      text-align: center;
      font-size: 16px; }

.documents-wrapper {
  background-color: #fafafa;
  padding: 20px 0; }
  .documents-wrapper .content-box {
    width: 825px;
    margin: 0 auto;
    padding: 25px 35px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative; }
    @media screen and (max-width: 768px) {
      .documents-wrapper .content-box {
        width: 95%;
        padding: 15px; } }
    .documents-wrapper .content-box .back-btn-link {
      display: flex;
      align-items: center;
      position: absolute;
      left: 25px;
      top: 30px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.14;
      color: #29a9e0;
      cursor: pointer; }
      .documents-wrapper .content-box .back-btn-link svg {
        margin-top: -3px;
        height: 21px; }
    .documents-wrapper .content-box .box-heading {
      font-family: "Helvetica";
      font-size: 26px;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #001061;
      margin-top: 0; }
      @media screen and (max-width: 768px) {
        .documents-wrapper .content-box .box-heading {
          margin-top: 60px; } }
    .documents-wrapper .content-box .entity-box {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .documents-wrapper .content-box .entity-box .dropdown {
        margin-left: 10px;
        width: 50%; }
      .documents-wrapper .content-box .entity-box label {
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0;
        text-align: left;
        color: #1d1d1d;
        display: flex;
        align-items: center; }
        .documents-wrapper .content-box .entity-box label span {
          margin-right: 5px;
          margin-top: -3px; }
    .documents-wrapper .content-box .dropdown button {
      border: 1px solid #bcbcbc; }
    .documents-wrapper .content-box .dropdown .select-box {
      margin: 3% auto !important;
      font-family: Helvetica;
      border-radius: 0px;
      padding: 7px 10px;
      line-height: 1.34;
      text-align: left;
      font-weight: 400 !important;
      border: 1px solid #bcbcbc;
      box-sizing: border-box;
      border-radius: 5px;
      width: 85%; }
    .documents-wrapper .content-box .documents-box {
      margin-top: 25px;
      border: 1px solid #eee;
      padding: 15px 25px 25px;
      border-radius: 10px;
      display: block;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
      .documents-wrapper .content-box .documents-box .row {
        border-bottom: 2px solid #bcbcbc; }
        .documents-wrapper .content-box .documents-box .row .col-lg-11 {
          padding: 0px; }
        .documents-wrapper .content-box .documents-box .row .col-lg-1 {
          padding: 0px;
          display: flex;
          justify-content: flex-end; }
      .documents-wrapper .content-box .documents-box .icon {
        background-color: #f1fbff;
        color: #00107f;
        border-radius: 10px; }
      .documents-wrapper .content-box .documents-box h5 {
        font-family: "Helvetica Bold";
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #1d1d1d;
        margin-top: 0;
        margin-bottom: 20px; }
      .documents-wrapper .content-box .documents-box .document-box {
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        margin-top: 3%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start; }
        .documents-wrapper .content-box .documents-box .document-box .label1 {
          min-height: 50px;
          background-color: #eeeeee;
          border-radius: 10px 10px 0px 0px;
          width: 100%; }
          .documents-wrapper .content-box .documents-box .document-box .label1 p {
            margin-left: 20px !important;
            font-size: 14px;
            color: #001061;
            vertical-align: middle;
            font-weight: 800 !important; }
        .documents-wrapper .content-box .documents-box .document-box .upload-button {
          background-color: #4687F4;
          border-radius: 20px;
          padding: 5px;
          align-content: center;
          max-width: 55%;
          margin: 2% 2% 4% 7% !important; }
        .documents-wrapper .content-box .documents-box .document-box:last-child {
          margin-bottom: 1rem; }
        .documents-wrapper .content-box .documents-box .document-box label {
          font-family: Helvetica;
          font-size: 12px;
          line-height: 1.19;
          letter-spacing: 0;
          text-align: left;
          color: #f5f5f5;
          display: flex;
          align-items: center; }
          .documents-wrapper .content-box .documents-box .document-box label span {
            margin-right: 5px;
            margin-top: -3px; }
        .documents-wrapper .content-box .documents-box .document-box > div {
          display: flex;
          justify-content: flex-start;
          align-items: center; }
          .documents-wrapper .content-box .documents-box .document-box > div > * {
            margin: 0 10px; }
            .documents-wrapper .content-box .documents-box .document-box > div > *:first-child {
              margin-left: 0; }
            .documents-wrapper .content-box .documents-box .document-box > div > *:last-child {
              margin-right: 0; }
          .documents-wrapper .content-box .documents-box .document-box > div .dropdown,
          .documents-wrapper .content-box .documents-box .document-box > div .input-field {
            max-width: 265px; }
          .documents-wrapper .content-box .documents-box .document-box > div .input-field {
            border: 1px solid #bcbcbc;
            border-radius: 7px;
            padding: 15px 12px;
            font-family: "Helvetica";
            font-size: 14px;
            line-height: 1.14;
            letter-spacing: normal; }
          .documents-wrapper .content-box .documents-box .document-box > div input[type="file"] {
            display: none; }
          .documents-wrapper .content-box .documents-box .document-box > div .upload-btn {
            width: 90px;
            padding: 9px 10px;
            border-radius: 25px;
            background-color: #4687f4;
            font-size: 14px;
            border: none;
            line-height: 1.19;
            letter-spacing: 0;
            text-align: center;
            color: #ffffff; }
          .documents-wrapper .content-box .documents-box .document-box > div .actions-box {
            min-width: 25px;
            margin-right: 0; }
            .documents-wrapper .content-box .documents-box .document-box > div .actions-box .check-icon {
              background-color: #8bd591;
              color: #fff;
              cursor: pointer;
              border-radius: 25px;
              width: 25px;
              height: 26px;
              display: inline-block;
              text-align: center;
              padding-top: 2px;
              opacity: 0; }
              .documents-wrapper .content-box .documents-box .document-box > div .actions-box .check-icon.visible {
                opacity: 1; }
        .documents-wrapper .content-box .documents-box .document-box .fileContainer {
          display: flex;
          flex-wrap: wrap;
          margin: 2% 2% 4% 7% !important; }
          .documents-wrapper .content-box .documents-box .document-box .fileContainer .file-wrapper {
            border: 1px solid #bcbcbc;
            border-radius: 20px;
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: .2em  !important;
            padding: 1% 1% 1% 1%; }
        .documents-wrapper .content-box .documents-box .document-box .documentWrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between; }
    .documents-wrapper .content-box .btns-box {
      display: inline-flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-top: 10px; }
      .documents-wrapper .content-box .btns-box .next-btn {
        width: 225px;
        border-radius: 25px;
        color: #fff;
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0;
        border: none;
        background-color: #4687F4;
        padding: 10px; }
        @media screen and (max-width: 768px) {
          .documents-wrapper .content-box .btns-box .next-btn {
            width: 90%; } }

.caret {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #bcbcbc; }

input[type="file"] {
  z-index: -1;
  position: absolute;
  opacity: 0; }

input:focus + label {
  outline: 2px solid; }

.gstn-wrapper {
  padding: 20px 0; }
  .gstn-wrapper .content-box {
    width: 750px;
    margin: 0 auto;
    padding: 35px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative; }
    @media screen and (max-width: 768px) {
      .gstn-wrapper .content-box {
        width: 95%;
        padding: 15px; } }
    @media screen and (max-width: 768px) {
      .gstn-wrapper .content-box .col-md-4 {
        margin-top: 20px; } }
    .gstn-wrapper .content-box .back-btn-link {
      display: flex;
      align-items: center;
      position: absolute;
      left: 25px;
      top: 40px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.14;
      color: #29a9e0;
      cursor: pointer; }
      .gstn-wrapper .content-box .back-btn-link svg {
        margin-top: -3px;
        height: 21px; }
    .gstn-wrapper .content-box .box-heading {
      font-family: Helvetica;
      font-size: 26px;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #1d1d1d;
      margin-top: 0;
      margin-bottom: 25px; }
      @media screen and (max-width: 768px) {
        .gstn-wrapper .content-box .box-heading {
          margin-top: 60px; } }
  .gstn-wrapper .input-box .input-field:read-only {
    background-color: #fafafa; }
  .gstn-wrapper .dropdown-box,
  .gstn-wrapper .input-box {
    margin: 10px 0; }
  .gstn-wrapper .dropdown {
    margin: 0; }
  .gstn-wrapper .range-box {
    margin: 15px 0; }
    .gstn-wrapper .range-box .range-label {
      font-family: Helvetica;
      font-size: 16px;
      margin: 10px 0 0 20px;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #1d1d1d; }
    .gstn-wrapper .range-box .range-input {
      width: 40px; }
  .gstn-wrapper .rangeslider {
    margin: 10px 0 0;
    height: 9px;
    background-color: transparent;
    border: 1px solid #4687F4;
    box-shadow: none; }
    .gstn-wrapper .rangeslider .rangeslider__handle {
      background-color: #4687F4;
      width: 18px;
      height: 18px;
      box-shadow: none;
      border: none;
      transform: translate3d(-10%, -50%, 0); }
      .gstn-wrapper .rangeslider .rangeslider__handle::after {
        display: none; }
    .gstn-wrapper .rangeslider .rangeslider__fill {
      background-color: #4687F4; }
  .gstn-wrapper .access-btn {
    margin: 25px 0;
    background-color: #4687F4;
    width: 100%;
    padding: 10px 12px 12px;
    border-radius: 25px;
    border: none;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 0.89;
    letter-spacing: -0.1px;
    text-align: center;
    color: #ffffff; }
  .gstn-wrapper .gstn-form .save-btn {
    background-color: #4687F4;
    width: 225px;
    border-radius: 25px;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin-top: 15px; }
  .gstn-wrapper .gstn-form .select-box1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1d1d1d;
    border: 1px solid #eee;
    border-radius: 7px;
    font-size: 14px;
    font-family: Helvetica;
    line-height: 1.14;
    letter-spacing: normal;
    padding: 11px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 47px;
    width: 100%; }
  .gstn-wrapper .gstn-form select option {
    margin: 40px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    width: 100%; }
    .gstn-wrapper .gstn-form select option:first-child {
      color: #001061;
      font-family: Helvetica Bold;
      background-color: #eee;
      padding: 10px 12px; }
    .gstn-wrapper .gstn-form select option :focus {
      width: 100%; }
    .gstn-wrapper .gstn-form select option :hover {
      font-family: Helvetica Bold;
      background-color: #29a9e0;
      color: #fff; }
    .gstn-wrapper .gstn-form select option :active {
      font-family: Helvetica Bold;
      background-color: #29a9e0;
      color: #fff; }
  .gstn-wrapper .industry-select-search > div {
    border: 1px solid #eee;
    box-shadow: none;
    border-radius: 7px; }
  .gstn-wrapper .industry-select-search input:not([type='hidden']) {
    font-family: Helvetica;
    font-size: 14px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #000; }
  .gstn-wrapper .industry-select-search .select__single-value,
  .gstn-wrapper .industry-select-search .select__menu {
    text-transform: capitalize; }
  .gstn-wrapper .industry-select-search .select__indicators {
    display: none; }
  .gstn-wrapper .industry-select-search .select__placeholder {
    color: #bcbcbc;
    font-family: Helvetica;
    font-size: inherit;
    line-height: 1.19;
    letter-spacing: 0;
    text-align: left; }
  .gstn-wrapper .industry-select-search .select__menu .select__menu-list {
    overflow-y: scroll;
    max-height: 225px; }
    .gstn-wrapper .industry-select-search .select__menu .select__menu-list::-webkit-scrollbar {
      width: 5px; }
    .gstn-wrapper .industry-select-search .select__menu .select__menu-list::-webkit-scrollbar-button {
      display: none; }
    .gstn-wrapper .industry-select-search .select__menu .select__menu-list::-webkit-scrollbar-track {
      background: transparent; }
    .gstn-wrapper .industry-select-search .select__menu .select__menu-list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #4687F4; }
    .gstn-wrapper .industry-select-search .select__menu .select__menu-list .select__option {
      padding: 5px 12px;
      line-height: 1.14;
      letter-spacing: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      font-family: Helvetica; }
      .gstn-wrapper .industry-select-search .select__menu .select__menu-list .select__option:hover, .gstn-wrapper .industry-select-search .select__menu .select__menu-list .select__option.select__option--is-focused {
        background-color: #eee;
        color: #262626; }
      .gstn-wrapper .industry-select-search .select__menu .select__menu-list .select__option.select__option--is-selected {
        background-color: #29a9e0;
        color: #fff; }
        .gstn-wrapper .industry-select-search .select__menu .select__menu-list .select__option.select__option--is-selected:hover {
          background-color: #29a9e0;
          color: #fff; }
  .gstn-wrapper .industry-select-search .select__control {
    position: relative;
    padding: 7px 0; }
    .gstn-wrapper .industry-select-search .select__control::after {
      content: '\f0d7';
      position: absolute;
      font-family: FontAwesome;
      right: 15px;
      top: 15px;
      color: #bcbcbc;
      font-size: 12px; }
    .gstn-wrapper .industry-select-search .select__control.select__control--is-disabled {
      cursor: not-allowed; }
      .gstn-wrapper .industry-select-search .select__control.select__control--is-disabled .select__placeholder {
        color: #bcbcbc; }
    .gstn-wrapper .industry-select-search .select__control.select__control--is-focused {
      box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }
  .gstn-wrapper .declaration-box {
    margin: 10px 0; }
    .gstn-wrapper .declaration-box .checkbox-box {
      margin-bottom: 5px; }
    .gstn-wrapper .declaration-box .input-label {
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #1d1d1d;
      margin: 0;
      cursor: pointer; }
    .gstn-wrapper .declaration-box .statement {
      padding: 7px;
      border: 1px solid #bcbcbc;
      border-radius: 7px;
      background-color: #fafafa;
      font-family: Helvetica;
      font-size: 12px;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #1d1d1d; }
      @media screen and (max-width: 768px) {
        .gstn-wrapper .declaration-box .statement {
          margin-bottom: 30px; } }
      .gstn-wrapper .declaration-box .statement b {
        font-weight: bold !important; }

.post-login-wrapper {
  background-color: #fafafa;
  padding: 20px 0; }
  .post-login-wrapper .content-box {
    width: 640px;
    margin: 0 auto;
    padding: 35px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative; }
    @media screen and (max-width: 768px) {
      .post-login-wrapper .content-box {
        width: 95%; } }
    .post-login-wrapper .content-box .back-btn-link {
      position: absolute;
      top: 50px;
      left: 35px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.14;
      color: #29a9e0;
      cursor: pointer; }
      .post-login-wrapper .content-box .back-btn-link svg {
        margin-top: -3px;
        height: 21px; }
    .post-login-wrapper .content-box .btns-box {
      display: flex;
      justify-content: center; }
      @media screen and (max-width: 768px) {
        .post-login-wrapper .content-box .btns-box {
          margin-top: 60px; } }
      .post-login-wrapper .content-box .btns-box button {
        min-width: 250px;
        border-radius: 25px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #4687F4;
        padding: 10px 20px 12px;
        border: none;
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.43;
        letter-spacing: 0;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center; }
        .post-login-wrapper .content-box .btns-box button span:last-child {
          margin-top: 2px;
          margin-left: 10px; }
    .post-login-wrapper .content-box .videos-preview-box {
      margin: 45px 0 25px; }
      @media screen and (max-width: 768px) {
        .post-login-wrapper .content-box .videos-preview-box {
          margin: 10px 0 10px; } }
      .post-login-wrapper .content-box .videos-preview-box .list-item {
        text-align: center; }
        @media screen and (max-width: 768px) {
          .post-login-wrapper .content-box .videos-preview-box .list-item {
            margin-top: 20px; } }
        .post-login-wrapper .content-box .videos-preview-box .list-item label {
          font-family: Helvetica;
          font-size: 16px;
          line-height: 1.14;
          letter-spacing: normal;
          color: #1d1d1d; }
          @media screen and (max-width: 768px) {
            .post-login-wrapper .content-box .videos-preview-box .list-item label {
              margin-bottom: 20px; } }
        .post-login-wrapper .content-box .videos-preview-box .list-item .video-box {
          max-width: 100%;
          margin-top: 10px; }
          .post-login-wrapper .content-box .videos-preview-box .list-item .video-box img {
            width: 100%;
            height: 140px; }

.forms-preview-wrapper {
  background-color: #f8f8f8; }
  .forms-preview-wrapper .content-wrapper {
    width: 1050px;
    margin: 0 auto;
    padding: 25px 0; }
    @media screen and (max-width: 768px) {
      .forms-preview-wrapper .content-wrapper {
        width: 100%;
        padding: 10px 0; } }
    @media screen and (max-width: 768px) {
      .forms-preview-wrapper .content-wrapper .col-xs-12 {
        padding: 0px; } }
    .forms-preview-wrapper .content-wrapper .box-wrapper {
      margin-bottom: 25px;
      border-radius: 12px;
      box-shadow: 0 9px 16px 0 rgba(0, 0, 0, 0.16); }
      .forms-preview-wrapper .content-wrapper .box-wrapper.border-top {
        padding-top: 10px;
        background: #4687F4; }
        .forms-preview-wrapper .content-wrapper .box-wrapper.border-top .box-content {
          border-radius: 0 0 12px 12px; }
      .forms-preview-wrapper .content-wrapper .box-wrapper.border-left {
        padding-left: 10px;
        background: #4687F4; }
        .forms-preview-wrapper .content-wrapper .box-wrapper.border-left .box-content {
          border-radius: 0 12px 12px 0; }
      .forms-preview-wrapper .content-wrapper .box-wrapper .ReactTable .rt-thead .rt-th {
        position: relative;
        background-color: #4687F4;
        font-family: 'Helvetica Bold';
        font-size: 14px;
        line-height: 1.25;
        letter-spacing: 0;
        color: #fff;
        padding: 12px 5px;
        white-space: normal;
        border-right-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center; }
        .forms-preview-wrapper .content-wrapper .box-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
          content: '\f0d7';
          position: absolute;
          font-family: FontAwesome;
          right: 5px;
          top: 33%;
          font-size: 16px; }
        .forms-preview-wrapper .content-wrapper .box-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
          content: '\f0d8';
          position: absolute;
          font-family: FontAwesome;
          right: 5px;
          top: 33%;
          font-size: 16px; }
      .forms-preview-wrapper .content-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.25;
        letter-spacing: 0;
        text-align: center;
        color: #1d1d1d;
        padding: 7px 0; }
        .forms-preview-wrapper .content-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
          border-right-color: transparent; }
          .forms-preview-wrapper .content-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td input[type='checkbox'] {
            width: 15px;
            height: 15px;
            margin: 0 auto; }
        .forms-preview-wrapper .content-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr.-odd {
          background-color: #f7f9fc; }
        .forms-preview-wrapper .content-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr.-even {
          background-color: #fff; }
      .forms-preview-wrapper .content-wrapper .box-wrapper .box-content {
        padding: 20px;
        background-color: #fff; }
        @media screen and (max-width: 768px) {
          .forms-preview-wrapper .content-wrapper .box-wrapper .box-content {
            padding: 10px; } }
        .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-heading {
          font-family: 'Helvetica Bold';
          font-size: 18px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #000;
          margin: 20px 0 10px 0; }
          @media screen and (max-width: 768px) {
            .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-heading {
              padding: 5px 0;
              margin: 10px 0 5px 0; } }
        .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-content {
          background-color: #fafafa;
          border-radius: 12px;
          margin-top: 20px; }
        .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-header {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
          .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-header .box-heading {
            margin-top: 0; }
          .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-header .edit-icon {
            color: #5f5f5f;
            border-bottom: 1px solid #5f5f5f;
            cursor: pointer;
            display: inline-block;
            margin-bottom: 3px; }
            @media screen and (max-width: 768px) {
              .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-header .edit-icon {
                display: none; } }
        .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details {
          padding-top: 15px;
          padding-bottom: 15px; }
          @media screen and (max-width: 768px) {
            .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details {
              padding-top: 10px;
              padding-bottom: 10px; } }
          .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div {
            margin-bottom: 15px; }
            .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div:last-child {
              margin-bottom: 0; }
            .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div label,
            .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div span {
              font-size: 14px;
              line-height: 1.19;
              letter-spacing: 0;
              color: #1d1d1d;
              margin: 0; }
            .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div label {
              font-family: 'Helvetica Bold';
              display: block; }
            .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div span {
              font-family: 'Helvetica'; }
            .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div textarea {
              resize: none;
              width: 100%;
              margin-top: 10px;
              padding: 15px; }
            .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div .check-icon {
              margin-left: 25px;
              color: #fff; }
        .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-right {
          position: relative; }
          .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-right::after {
            position: absolute;
            content: '';
            border-right: 1px solid rgba(0, 0, 0, 0.16);
            right: 0;
            top: 15px;
            width: 1px;
            height: 90%; }
            @media screen and (max-width: 768px) {
              .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-right::after {
                display: none; } }
        .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-left {
          position: relative; }
          .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-left::before {
            position: absolute;
            content: '';
            left: 0;
            top: 15px;
            width: 1px;
            height: 90%;
            border-left: 1px solid rgba(0, 0, 0, 0.16); }
            @media screen and (max-width: 768px) {
              .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-left::before {
                display: none; } }
        .forms-preview-wrapper .content-wrapper .box-wrapper .box-content .horizontal-divider {
          margin: 10px 0;
          border-top: 1px solid rgba(0, 0, 0, 0.16); }
      .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list {
        list-style: none;
        margin: 10px 0; }
        .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5px 0;
          padding-bottom: 5px;
          border-bottom: 1px solid #eee; }
          .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li label,
          .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li span {
            font-size: 14px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            margin: 0; }
          .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li label {
            font-family: 'Helvetica Bold';
            flex: 0.75; }
          .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li span {
            font-family: 'Helvetica'; }
            .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li span:first-of-type {
              flex: 1.25;
              margin-left: 10px; }
            .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li span:last-of-type {
              flex: 1; }
          .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li svg {
            color: #b22929; }
          .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li .months-list {
            display: flex;
            list-style: none;
            flex: 1.25; }
            .forms-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li .months-list li {
              font-family: Helvetica;
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: normal;
              color: #ffffff;
              flex: 1;
              background-color: #19ce35;
              text-align: center;
              border-right: 0.5px solid #fff; }
    .forms-preview-wrapper .content-wrapper .submit-btn {
      background: #4687F4;
      font-family: Helvetica;
      font-size: 16px;
      min-width: 225px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #ffffff;
      border-radius: 25px;
      border: none;
      padding: 9px;
      margin: 10px 0;
      text-transform: uppercase; }
    .forms-preview-wrapper .content-wrapper .check-icon {
      color: #fff;
      border-radius: 25px;
      cursor: pointer;
      width: 25px;
      height: 26px;
      text-align: center;
      padding: 3px 3px 6px;
      background-color: #78e35f; }
    .forms-preview-wrapper .content-wrapper .check-list {
      list-style: none;
      margin-bottom: 0; }
      .forms-preview-wrapper .content-wrapper .check-list li {
        display: flex;
        justify-content: space-between;
        align-items: flex-start; }
        .forms-preview-wrapper .content-wrapper .check-list li:not(:first-child) {
          margin: 10px 0; }
        .forms-preview-wrapper .content-wrapper .check-list li:first-child {
          width: 70px;
          margin: 0 -15px 0 auto; }
        .forms-preview-wrapper .content-wrapper .check-list li:last-child {
          margin-bottom: 0; }
        .forms-preview-wrapper .content-wrapper .check-list li label {
          font-family: 'Helvetica Bold';
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          margin: 0;
          width: 80%; }
        .forms-preview-wrapper .content-wrapper .check-list li > span {
          width: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center; }
    .forms-preview-wrapper .content-wrapper .entity-box .vertical-divider-left::before {
      left: -20px !important; }
    .forms-preview-wrapper .content-wrapper .entity-box .vertical-divider-right::after {
      right: 20px !important; }

.references-wrapper {
  background-color: #fafafa;
  padding: 30px 0; }
  .references-wrapper .content-box {
    width: 890px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px 40px;
    border-radius: 15px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .references-wrapper .content-box {
        width: 95%;
        padding: 15px; } }
    .references-wrapper .content-box .skipNow {
      position: absolute;
      font-size: 16px;
      top: 20px;
      right: 20px;
      color: #4687F4 !important;
      text-decoration: underline; }
      .references-wrapper .content-box .skipNow:hover {
        cursor: pointer; }
      @media screen and (max-width: 768px) {
        .references-wrapper .content-box .skipNow {
          top: 20px;
          right: 10px; } }
    .references-wrapper .content-box .back-btn-link {
      display: flex;
      align-items: center;
      position: absolute;
      left: 25px;
      top: 25px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.14;
      color: #29a9e0;
      cursor: pointer; }
      .references-wrapper .content-box .back-btn-link svg {
        margin-top: -3px;
        height: 21px; }
    .references-wrapper .content-box .box-heading {
      font-family: 'Helvetica';
      font-size: 26px;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #001061;
      margin-top: 0;
      margin-bottom: 25px; }
      @media screen and (max-width: 768px) {
        .references-wrapper .content-box .box-heading {
          margin-top: 60px; } }
    .references-wrapper .content-box .btns-box .next-btn {
      background-color: #4687F4;
      padding: 10px;
      width: 225px;
      border-radius: 25px;
      color: #fff;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      border: none; }
      @media screen and (max-width: 768px) {
        .references-wrapper .content-box .btns-box .next-btn {
          width: 90%; } }
    .references-wrapper .content-box > .btns-box {
      display: flex;
      align-items: center;
      flex-direction: column; }
    .references-wrapper .content-box .references-box .form-box {
      border: 1px solid #eee;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #f5f5f5;
      padding: 25px;
      border-radius: 10px;
      width: 90%;
      position: relative;
      margin: 0 auto 25px; }
      .references-wrapper .content-box .references-box .form-box .select-box1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #1d1d1d;
        border: 1px solid #eee;
        border-radius: 7px;
        font-size: 14px;
        font-family: Helvetica;
        line-height: 1.14;
        letter-spacing: normal;
        padding: 11px 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 47px;
        width: 100%; }
      .references-wrapper .content-box .references-box .form-box select option {
        margin: 40px;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
        width: 100%; }
        .references-wrapper .content-box .references-box .form-box select option:first-child {
          color: #001061;
          font-family: Helvetica Bold;
          background-color: #eee;
          padding: 10px 12px; }
        .references-wrapper .content-box .references-box .form-box select option :focus {
          width: 100%; }
        .references-wrapper .content-box .references-box .form-box select option :hover {
          font-family: Helvetica Bold;
          background-color: #29a9e0;
          color: #fff; }
        .references-wrapper .content-box .references-box .form-box select option :active {
          font-family: Helvetica Bold;
          background-color: #29a9e0;
          color: #fff; }
      .references-wrapper .content-box .references-box .form-box .box-header {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .references-wrapper .content-box .references-box .form-box .box-header h4 {
          margin: 0 0 15px;
          font-family: Helvetica;
          font-size: 21px;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #1d1d1d; }
        .references-wrapper .content-box .references-box .form-box .box-header .remove-icon {
          color: #ff0000;
          cursor: pointer;
          display: inline-block;
          margin-left: 10px; }
          .references-wrapper .content-box .references-box .form-box .box-header .remove-icon:hover {
            -webkit-transform-origin: top center;
            transform-origin: top center;
            -webkit-animation-name: swing;
            animation-name: swing;
            animation-duration: 0.5s; }
      .references-wrapper .content-box .references-box .form-box .add-btn {
        width: 225px;
        border-radius: 25px;
        background-color: #4687F4;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #ffffff;
        border: none;
        padding: 10px;
        margin: 10px 0 0 auto; }
        @media screen and (max-width: 768px) {
          .references-wrapper .content-box .references-box .form-box .add-btn {
            width: 100%; } }
      .references-wrapper .content-box .references-box .form-box .dropdown {
        margin: 10px 0; }
        .references-wrapper .content-box .references-box .form-box .dropdown .select-box {
          color: #1d1d1d; }
      .references-wrapper .content-box .references-box .form-box .input-box {
        margin: 10px 0; }
      .references-wrapper .content-box .references-box .form-box .input-field:disabled {
        background-color: #fff; }
      .references-wrapper .content-box .references-box .form-box .address-box .input-label {
        margin-top: 15px; }
      .references-wrapper .content-box .references-box .form-box .pin-code-select-search {
        margin: 10px 0; }
        .references-wrapper .content-box .references-box .form-box .pin-code-select-search > div {
          border: 1px solid #eee;
          padding: 3px 0;
          box-shadow: none;
          border-radius: 7px; }
        .references-wrapper .content-box .references-box .form-box .pin-code-select-search input:not([type='hidden']) {
          font-family: Helvetica;
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          text-align: left;
          color: #000; }
        .references-wrapper .content-box .references-box .form-box .pin-code-select-search .select__single-value,
        .references-wrapper .content-box .references-box .form-box .pin-code-select-search .select__menu {
          text-transform: capitalize; }
        .references-wrapper .content-box .references-box .form-box .pin-code-select-search .select__indicators {
          display: none; }
        .references-wrapper .content-box .references-box .form-box .pin-code-select-search .select__placeholder {
          color: #bcbcbc;
          font-family: Helvetica;
          font-size: 16px;
          line-height: 1.19;
          letter-spacing: 0;
          text-align: left; }
        .references-wrapper .content-box .references-box .form-box .pin-code-select-search .select__control {
          position: relative;
          padding: 7px 0; }
          .references-wrapper .content-box .references-box .form-box .pin-code-select-search .select__control::after {
            content: '\f0d7';
            position: absolute;
            font-family: FontAwesome;
            right: 10px;
            top: 15px;
            color: #bcbcbc;
            font-size: 12px; }
          .references-wrapper .content-box .references-box .form-box .pin-code-select-search .select__control.select__control--is-focused {
            box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }

.submit-wrapper {
  background-color: #fafafa;
  padding: 20px 0; }
  .submit-wrapper .content-box {
    width: 500px;
    margin: 0 auto;
    padding: 35px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    @media screen and (max-width: 768px) {
      .submit-wrapper .content-box {
        width: 95%;
        padding: 15px; } }
    .submit-wrapper .content-box .box-heading {
      font-family: Helvetica;
      font-size: 26px;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #1d1d1d;
      margin-top: 0;
      margin-bottom: 25px; }
  .submit-wrapper .declaration-box {
    margin-top: 35px; }
    .submit-wrapper .declaration-box .checkbox-box {
      margin-bottom: 10px; }
    .submit-wrapper .declaration-box .input-label {
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #1d1d1d;
      margin: 0;
      cursor: pointer; }
    .submit-wrapper .declaration-box .statement {
      padding: 5px 7px;
      border: 1px solid #bcbcbc;
      border-radius: 7px;
      background-color: #fafafa;
      font-family: Helvetica;
      font-size: 12px;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #1d1d1d; }
      .submit-wrapper .declaration-box .statement b {
        font-weight: bold !important; }
  .submit-wrapper .btns-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px; }
    .submit-wrapper .btns-box button {
      width: 200px;
      border-radius: 25px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #ffffff;
      padding: 10px;
      border: none; }
      .submit-wrapper .btns-box button.submit-btn {
        background-color: #29a9e0;
        margin-bottom: 10px; }
      .submit-wrapper .btns-box button.edit-btn {
        background-color: #001061; }

.sourcing_details-wrapper {
  background-color: #fafafa;
  padding: 20px 0; }
  .sourcing_details-wrapper .content-box {
    width: 950px;
    margin: 0 auto;
    padding: 35px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative; }
    @media screen and (max-width: 768px) {
      .sourcing_details-wrapper .content-box {
        width: 95%;
        padding: 15px; } }
    .sourcing_details-wrapper .content-box .back-btn-link {
      display: flex;
      align-items: center;
      position: absolute;
      left: 25px;
      top: 40px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.14;
      color: #29a9e0;
      cursor: pointer; }
    .sourcing_details-wrapper .content-box .select-box1 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #1d1d1d;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      line-height: 1.14;
      letter-spacing: normal;
      padding: 11px 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 47px;
      width: 100%; }
    .sourcing_details-wrapper .content-box select option {
      margin: 40px;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      width: 100%; }
      .sourcing_details-wrapper .content-box select option:first-child {
        color: #001061;
        font-family: Helvetica Bold;
        background-color: #eee;
        padding: 10px 12px; }
      .sourcing_details-wrapper .content-box select option :focus {
        width: 100%; }
      .sourcing_details-wrapper .content-box select option :hover {
        font-family: Helvetica Bold;
        background-color: #29a9e0;
        color: #fff; }
      .sourcing_details-wrapper .content-box select option :active {
        font-family: Helvetica Bold;
        background-color: #29a9e0;
        color: #fff; }
    .sourcing_details-wrapper .content-box .box-heading {
      font-family: 'Helvetica';
      font-size: 26px;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #001061;
      margin-top: 0;
      margin-bottom: 25px; }
      @media screen and (max-width: 768px) {
        .sourcing_details-wrapper .content-box .box-heading {
          margin-top: 60px; } }
    .sourcing_details-wrapper .content-box .sub-heading {
      margin: 20px 0px 20px 20px; }
    .sourcing_details-wrapper .content-box .toggle__wrapper {
      display: flex;
      margin-left: 30px;
      margin-bottom: 30px;
      width: fit-content;
      cursor: pointer; }
      .sourcing_details-wrapper .content-box .toggle__wrapper .active-button {
        padding: 5px 30px;
        transform: scale(1.1, 1.05);
        border-radius: 4px;
        color: #fff;
        background-color: #4687F4; }
        @media screen and (max-width: 768px) {
          .sourcing_details-wrapper .content-box .toggle__wrapper .active-button {
            padding: 5px 10px; } }
      .sourcing_details-wrapper .content-box .toggle__wrapper .normal-button {
        padding: 5px 30px;
        border-radius: 4px;
        border: 2px solid #4687F4; }
        @media screen and (max-width: 768px) {
          .sourcing_details-wrapper .content-box .toggle__wrapper .normal-button {
            padding: 5px 10px; } }
    .sourcing_details-wrapper .content-box #business-constitution-select,
    .sourcing_details-wrapper .content-box input[name='businessName'] {
      margin-top: 8px; }
    .sourcing_details-wrapper .content-box .input-box .input-field:read-only {
      background-color: #fafafa; }
    .sourcing_details-wrapper .content-box .input-box,
    .sourcing_details-wrapper .content-box .datepicker-box {
      margin: 10px 0; }
    .sourcing_details-wrapper .content-box .back-btn-link svg {
      margin-top: -3px;
      height: 21px; }
    .sourcing_details-wrapper .content-box .text-center {
      margin-top: 4%; }
      .sourcing_details-wrapper .content-box .text-center .save-btn {
        width: 225px;
        border-radius: 25px;
        background-color: #4687F4;
        color: #ffffff;
        border: none;
        padding: 10px;
        margin-top: 10px;
        justify-content: center; }

.gstn-updated-wrapper {
  background-color: #fafafa;
  padding: 20px 0; }
  @media screen and (max-width: 768px) {
    .gstn-updated-wrapper .col-xs-12 {
      padding: 0; } }
  .gstn-updated-wrapper .select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1d1d1d;
    border: 1px solid #eee;
    border-radius: 7px;
    font-size: 14px;
    font-family: Helvetica;
    line-height: 1.14;
    letter-spacing: normal;
    padding: 11px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 47px;
    width: 100%; }
  .gstn-updated-wrapper .content-box {
    width: 950px;
    margin: 0 auto;
    padding: 35px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative; }
    @media screen and (max-width: 768px) {
      .gstn-updated-wrapper .content-box {
        width: 95%;
        padding: 15px; } }
    .gstn-updated-wrapper .content-box #business-constitution-select,
    .gstn-updated-wrapper .content-box input[name='business'] {
      margin-top: 10px; }
    .gstn-updated-wrapper .content-box .select-color {
      color: #c5c5c0; }
    .gstn-updated-wrapper .content-box .box-details {
      color: #c5c5c0;
      margin-top: 10px; }
    .gstn-updated-wrapper .content-box .select-box_main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: black;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      line-height: 1.14;
      letter-spacing: normal;
      padding: 11px 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 47px;
      width: 100%; }
    .gstn-updated-wrapper .content-box .back-btn-link {
      display: flex;
      align-items: center;
      position: absolute;
      left: 25px;
      top: 40px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.14;
      color: #29a9e0;
      cursor: pointer; }
    .gstn-updated-wrapper .content-box .input-box .input-field:read-only {
      background-color: #fafafa; }
    .gstn-updated-wrapper .content-box .input-box,
    .gstn-updated-wrapper .content-box .datepicker-box {
      margin: 10px 0; }
    .gstn-updated-wrapper .content-box .back-btn-link svg {
      margin-top: -3px;
      height: 21px; }
    .gstn-updated-wrapper .content-box .box-heading {
      font-family: 'Helvetica';
      font-size: 26px;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #001061;
      margin-top: 0; }
      @media screen and (max-width: 768px) {
        .gstn-updated-wrapper .content-box .box-heading {
          margin-top: 60px; } }
    .gstn-updated-wrapper .content-box .text-center {
      margin-top: 4%; }
      .gstn-updated-wrapper .content-box .text-center .save-btn {
        width: 225px;
        border-radius: 25px;
        background-color: #4687F4;
        color: #ffffff;
        border: none;
        padding: 10px;
        margin-top: 10px;
        justify-content: center; }
        @media screen and (max-width: 768px) {
          .gstn-updated-wrapper .content-box .text-center .save-btn {
            width: 80%; } }
    .gstn-updated-wrapper .content-box .content-box-inner {
      width: 97%;
      margin: 0 auto;
      margin-top: 22px;
      background-color: #fff;
      border-radius: 30px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      position: relative; }
      .gstn-updated-wrapper .content-box .content-box-inner .secondary-box-heading {
        font-family: 'Helvetica Bold';
        font-size: 21px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #4687f4; }
        @media screen and (max-width: 768px) {
          .gstn-updated-wrapper .content-box .content-box-inner .secondary-box-heading {
            font-size: 18px !important;
            line-height: 1;
            margin: 10px 0; } }
      .gstn-updated-wrapper .content-box .content-box-inner .address {
        border-top: 1px solid #dddddd; }
        .gstn-updated-wrapper .content-box .content-box-inner .address:not(:last-child) {
          border-bottom: 1px solid #dddddd; }
        .gstn-updated-wrapper .content-box .content-box-inner .address .address-sub {
          padding-left: 20px;
          color: #4687f4; }
          .gstn-updated-wrapper .content-box .content-box-inner .address .address-sub .option {
            color: #c5c5c0; }
        .gstn-updated-wrapper .content-box .content-box-inner .address .btn-width {
          width: 172px !important; }
          @media screen and (max-width: 768px) {
            .gstn-updated-wrapper .content-box .content-box-inner .address .btn-width {
              width: 200px !important; } }
      .gstn-updated-wrapper .content-box .content-box-inner .gstRegistration .gstRegistration-inner {
        padding: 17px; }
      .gstn-updated-wrapper .content-box .content-box-inner .remove-icon {
        color: #ff0000;
        cursor: pointer;
        display: inline-block; }
        .gstn-updated-wrapper .content-box .content-box-inner .remove-icon:hover {
          -webkit-transform-origin: top center;
          transform-origin: top center;
          -webkit-animation-name: swing;
          animation-name: swing;
          animation-duration: 0.5s; }
      .gstn-updated-wrapper .content-box .content-box-inner .float-right {
        float: right;
        margin-top: 5px; }
      .gstn-updated-wrapper .content-box .content-box-inner .address-flex {
        display: flex;
        height: 64px; }
      .gstn-updated-wrapper .content-box .content-box-inner .address {
        padding: 17px; }
        .gstn-updated-wrapper .content-box .content-box-inner .address .address-inner {
          padding-left: 20px;
          color: #000000; }
        .gstn-updated-wrapper .content-box .content-box-inner .address .add-btn {
          background-color: #4687f4;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin: 0 0 0 auto;
          padding: 4px 15px 5px 10px;
          width: 150px;
          border-radius: 25px;
          color: #fff;
          border: none; }
      .gstn-updated-wrapper .content-box .content-box-inner .monthly {
        color: red;
        border: 10px solid red; }
      .gstn-updated-wrapper .content-box .content-box-inner .box-border {
        padding-top: 5px; }
      .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-flex {
        display: flex; }
        .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-flex .btn-width {
          width: 172px !important; }
        .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-flex .box-heading {
          color: #000;
          font-size: 22px; }
          .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-flex .box-heading .option {
            color: #c5c5c0;
            font-size: 16px;
            margin-left: 5px; }
      .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px; }
        .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header:not(:last-child) {
          border-bottom: 1px solid #dddddd; }
        @media screen and (max-width: 768px) {
          .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header {
            flex-direction: column;
            align-items: flex-start; } }
        .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header .GstButton {
          color: white;
          background-color: #4687F4;
          padding: 10px 13px;
          font-size: 16px;
          border: none; }
        .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header h5 {
          font-family: 'Helvetica Bold';
          font-size: 21px;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #4687f4; }
          @media screen and (max-width: 768px) {
            .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header h5 {
              font-size: 18px !important;
              line-height: 1; } }
        .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header .add-btn {
          background-color: #4687F4;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin: 0 0 0 auto;
          padding: 4px 15px 5px 10px;
          width: 150px;
          border-radius: 25px;
          color: #fff;
          font-family: Helvetica;
          font-size: 16px;
          line-height: 1.19;
          letter-spacing: 0;
          border: none; }
          @media screen and (max-width: 768px) {
            .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header .add-btn {
              width: inherit;
              padding: 4px 12px 4px 8px;
              margin: 10px 0 0 auto; } }
        .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header .remove-icon {
          color: #ff0000;
          cursor: pointer;
          margin-left: 10px;
          display: inline-block; }
        .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header .remove {
          margin-top: 19px;
          float: right; }
        .gstn-updated-wrapper .content-box .content-box-inner .address-box .box-header .remove-icon:hover {
          -webkit-transform-origin: top center;
          transform-origin: top center;
          -webkit-animation-name: swing;
          animation-name: swing;
          animation-duration: 0.5s; }
      .gstn-updated-wrapper .content-box .content-box-inner .success-message-gst {
        position: absolute;
        right: 20px;
        bottom: 30px;
        color: #4687f4; }

.manually_gst-wrapper .heading {
  color: #4687f4;
  font-size: 24px;
  border-bottom: 5px;
  padding-bottom: 14px;
  border-bottom: 1.5px solid #bcbcbc; }

.manually_gst-wrapper .total {
  width: 100%;
  height: 12%;
  background-color: #001061;
  padding-top: 2%;
  padding-bottom: 2%; }
  .manually_gst-wrapper .total .innerspan_main {
    color: #ffffff;
    margin-left: 42%; }
  .manually_gst-wrapper .total .innerspan_sub {
    color: #ffffff;
    margin-left: 30%; }

.manually_gst-wrapper .btn-flex {
  display: flex; }
  .manually_gst-wrapper .btn-flex .span {
    color: #001061;
    font-weight: bolder; }
  .manually_gst-wrapper .btn-flex .add-btn {
    background-color: #4687f4;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 0 0 auto;
    padding: 4px 15px 5px 10px;
    width: 150px;
    border-radius: 25px;
    color: #fff;
    border: none; }

.manually_gst-wrapper .text-center {
  margin-top: 4%; }
  .manually_gst-wrapper .text-center .save-btn {
    width: 141px;
    border-radius: 25px;
    background-color: #4687F4;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin-top: 10px;
    justify-content: center; }

.manually_gst-wrapper .monthly-table .input-box_sub {
  position: relative;
  margin: 2px;
  width: 80%;
  margin-left: 30px; }

.manually_gst-wrapper .monthly-table .select-box_sub {
  display: flex;
  margin-left: 13%;
  justify-content: space-between;
  align-items: center;
  color: #1d1d1d;
  border: 1px solid #eee;
  border-radius: 7px;
  font-size: 14px;
  font-family: Helvetica;
  line-height: 1.14;
  letter-spacing: normal;
  padding: 11px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 47px;
  width: 78%; }

.manually_gst-wrapper .monthly-table .serial_no {
  color: #181818;
  text-align: center;
  font-size: 21px;
  line-height: 42px; }

.overviewDetails-wrapper {
  margin-top: 30px; }
  .overviewDetails-wrapper * {
    padding: 0px;
    overflow-wrap: break-word; }
  .overviewDetails-wrapper .details-button {
    color: white;
    background-color: #00CDBE;
    border-radius: 25px;
    border: none;
    padding: 1px 11px;
    margin-top: 14px; }
  .overviewDetails-wrapper .content-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #fafafa;
    padding: 15px 20px;
    margin-bottom: 20px;
    margin-left: 15px; }
    .overviewDetails-wrapper .content-wrapper h2 {
      font-family: Helvetica;
      font-size: 24px;
      line-height: 1;
      letter-spacing: -0.2px;
      color: #4687F4;
      margin: 10px 0; }
    .overviewDetails-wrapper .content-wrapper h3 {
      font-family: Helvetica;
      font-size: 24px;
      line-height: 1.18;
      letter-spacing: -0.2px;
      color: #4687F4;
      margin: 10px 0; }
    .overviewDetails-wrapper .content-wrapper h4 {
      font-family: "Helvetica Bold";
      font-size: 18px;
      line-height: 0.92;
      letter-spacing: normal;
      color: #000;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 10px; }
    .overviewDetails-wrapper .content-wrapper label,
    .overviewDetails-wrapper .content-wrapper span {
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden; }
    .overviewDetails-wrapper .content-wrapper label {
      font-family: "Helvetica Bold"; }
    .overviewDetails-wrapper .content-wrapper span {
      font-family: Helvetica; }
    .overviewDetails-wrapper .content-wrapper .chart-box {
      margin-top: 45px; }
    .overviewDetails-wrapper .content-wrapper .content-box {
      margin-top: 15px; }
      .overviewDetails-wrapper .content-wrapper .content-box .content {
        margin-bottom: 10px; }
        .overviewDetails-wrapper .content-wrapper .content-box .content span address {
          line-height: inherit; }
        .overviewDetails-wrapper .content-wrapper .content-box .content:last-child {
          margin-bottom: 0; }

.overview-applicant-modal {
  width: 75%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 25px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto; }
  .overview-applicant-modal .otplink-modal h3 {
    text-align: center; }
  .overview-applicant-modal .cross-icon {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #bcbcbc;
    cursor: pointer; }
  .overview-applicant-modal h3 {
    font-family: Helvetica;
    font-size: 28px;
    line-height: 1;
    letter-spacing: -0.2px;
    color: #001061;
    margin: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #bcbcbc; }
    .overview-applicant-modal h3 small {
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.67;
      letter-spacing: -0.1px;
      color: #001061; }
  .overview-applicant-modal .ReactTable {
    margin: 20px auto 0; }
    .overview-applicant-modal .ReactTable .rt-thead .rt-tr .rt-th {
      position: relative;
      background-color: #4687F4;
      font-family: "Helvetica Bold";
      font-size: 14px;
      line-height: 1.25;
      letter-spacing: 0;
      color: #ffffff;
      padding: 10px;
      border-right-color: #fff; }
      .overview-applicant-modal .ReactTable .rt-thead .rt-tr .rt-th:first-child {
        text-align: left;
        padding-left: 15px; }
      .overview-applicant-modal .ReactTable .rt-thead .rt-tr .rt-th.-sort-asc::after {
        content: "\f0d7";
        position: absolute;
        font-family: FontAwesome;
        right: 9px;
        top: 10px;
        font-size: 16px; }
      .overview-applicant-modal .ReactTable .rt-thead .rt-tr .rt-th.-sort-desc::after {
        content: "\f0d8";
        position: absolute;
        font-family: FontAwesome;
        right: 9px;
        top: 10px;
        font-size: 16px; }
    .overview-applicant-modal .ReactTable .rt-tbody .rt-tr {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.25;
      letter-spacing: 0;
      color: #1d1d1d; }
      .overview-applicant-modal .ReactTable .rt-tbody .rt-tr .rt-td {
        border-right-color: transparent;
        padding: 10px;
        text-align: center; }
        .overview-applicant-modal .ReactTable .rt-tbody .rt-tr .rt-td:first-child {
          text-align: left;
          padding-left: 15px; }
        .overview-applicant-modal .ReactTable .rt-tbody .rt-tr .rt-td label {
          margin-bottom: 0;
          font-family: "Helvetica Bold";
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: normal;
          color: #1d1d1d; }
      .overview-applicant-modal .ReactTable .rt-tbody .rt-tr.-odd {
        background-color: #f7f9fc; }
      .overview-applicant-modal .ReactTable .rt-tbody .rt-tr.-even {
        background-color: #ffffff; }
  .overview-applicant-modal .circular-progressbar {
    width: 125px;
    display: block;
    margin: 35px auto;
    border-radius: 50%;
    border: 1px solid #36ddd1; }
    .overview-applicant-modal .circular-progressbar .CircularProgressbar-trail {
      stroke: transparent;
      box-shadow: 2px 2px 2px 2px; }
  .overview-applicant-modal .applicant-details,
  .overview-applicant-modal .progress-list {
    list-style: none;
    margin-bottom: 0;
    margin-top: 15px; }
    .overview-applicant-modal .applicant-details li,
    .overview-applicant-modal .progress-list li {
      display: flex;
      align-items: flex-start;
      margin: 10px 0; }
      .overview-applicant-modal .applicant-details li:first-child,
      .overview-applicant-modal .progress-list li:first-child {
        margin-top: 0; }
      .overview-applicant-modal .applicant-details li:last-child,
      .overview-applicant-modal .progress-list li:last-child {
        margin-bottom: 0; }
      .overview-applicant-modal .applicant-details li label,
      .overview-applicant-modal .applicant-details li span,
      .overview-applicant-modal .progress-list li label,
      .overview-applicant-modal .progress-list li span {
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d;
        margin-bottom: 0; }
      .overview-applicant-modal .applicant-details li label,
      .overview-applicant-modal .progress-list li label {
        flex: 0.6;
        font-family: "Helvetica Bold"; }
      .overview-applicant-modal .applicant-details li span,
      .overview-applicant-modal .progress-list li span {
        flex: 1.4;
        font-family: Helvetica; }
  .overview-applicant-modal .progress-list li label {
    flex: 0.5; }
  .overview-applicant-modal .progress-list li .progress {
    margin-top: 2px;
    box-shadow: none;
    flex: 1.5;
    height: 12px;
    width: 100%;
    margin-bottom: 0;
    background-color: transparent;
    border: 1px solid #4687f4;
    border-radius: 15px; }
    .overview-applicant-modal .progress-list li .progress .progress-bar {
      background-color: #4687f4;
      line-height: 12px;
      font-size: 10px;
      font-family: "Helvetica Bold"; }

.business-risk__container .toggle__wrapper {
  display: flex;
  margin: 20px auto;
  width: fit-content;
  cursor: pointer; }
  .business-risk__container .toggle__wrapper .active-button {
    padding: 5px 30px;
    transform: scale(1.15, 1.1);
    border-radius: 10px;
    transition: all 0.1s ease-in;
    color: #fff;
    background-color: #4687F4; }
  .business-risk__container .toggle__wrapper .normal-button {
    padding: 5px 40px;
    border-radius: 10px;
    border: 2px solid #4687F4; }

.business-risk__container .business-risk-wrapper {
  margin-top: 30px; }
  .business-risk__container .business-risk-wrapper .row.upper-section .nav-tabs {
    margin-top: 0; }
  .business-risk__container .business-risk-wrapper .row.upper-section ul {
    display: flex; }
    .business-risk__container .business-risk-wrapper .row.upper-section ul li a {
      height: 100%;
      padding: 0 !important; }
  .business-risk__container .business-risk-wrapper .row.upper-section .tabs-heading {
    padding: 10px 15px; }
  .business-risk__container .business-risk-wrapper .row.upper-section .bold-text__smaller {
    font-size: 12px;
    font-weight: bold !important; }
  .business-risk__container .business-risk-wrapper .row.upper-section .margin-for-level {
    margin-top: 55px; }
  .business-risk__container .business-risk-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 35px;
    padding: 25px 20px; }
    .business-risk__container .business-risk-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0;
      margin: 0 10px; }
      .business-risk__container .business-risk-wrapper .box-wrapper .box-header button {
        background-color: #4687F4;
        padding: 5px 10px;
        margin-bottom: 10px;
        border: none;
        color: white;
        border-radius: 20px; }
      .business-risk__container .business-risk-wrapper .box-wrapper .box-header .react-switch {
        float: right;
        margin-bottom: 10px; }
        .business-risk__container .business-risk-wrapper .box-wrapper .box-header .react-switch .react-switch-bg {
          width: 200px !important;
          border-radius: 12px;
          background-color: #e0e0e0; }
      .business-risk__container .business-risk-wrapper .box-wrapper .box-header .quarterDataFlag {
        color: #b53629;
        border: 1px solid #b53629;
        padding: 5px;
        border-radius: 5px;
        margin-left: 10px;
        float: right; }
    .business-risk__container .business-risk-wrapper .box-wrapper .hsn-table {
      margin-top: 20px; }
      .business-risk__container .business-risk-wrapper .box-wrapper .hsn-table h4 {
        background: #001061;
        color: #fff;
        padding: 15px 10px;
        margin-bottom: 0; }
      .business-risk__container .business-risk-wrapper .box-wrapper .hsn-table ul li {
        display: flex;
        padding: 8px; }
        .business-risk__container .business-risk-wrapper .box-wrapper .hsn-table ul li:nth-child(2n + 1) {
          background: #f0f0f0; }
        .business-risk__container .business-risk-wrapper .box-wrapper .hsn-table ul li:nth-child(2n) {
          background: #f5f5f5; }
        .business-risk__container .business-risk-wrapper .box-wrapper .hsn-table ul li span {
          margin-left: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
    .business-risk__container .business-risk-wrapper .box-wrapper .chart-box {
      margin-top: 25px;
      min-height: 200px; }
    .business-risk__container .business-risk-wrapper .box-wrapper .box-footer {
      margin: 20px -20px -25px -20px;
      background-color: #4687F4;
      border-radius: 0 0 15px 15px; }
      .business-risk__container .business-risk-wrapper .box-wrapper .box-footer ul {
        display: flex;
        margin-bottom: 0; }
        .business-risk__container .business-risk-wrapper .box-wrapper .box-footer ul li {
          flex: 1;
          padding: 10px 15px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .business-risk__container .business-risk-wrapper .box-wrapper .box-footer ul li label {
            font-family: Helvetica;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #ffffff; }
          .business-risk__container .business-risk-wrapper .box-wrapper .box-footer ul li > span {
            font-family: Helvetica;
            font-size: 28px;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #ffffff;
            display: flex;
            align-items: center;
            margin-top: 10px; }
            .business-risk__container .business-risk-wrapper .box-wrapper .box-footer ul li > span .icon {
              margin-top: -3px; }
        .business-risk__container .business-risk-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .business-risk__container .business-risk-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px; }
          .business-risk__container .business-risk-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
    .business-risk__container .business-risk-wrapper .box-wrapper h4 {
      font-family: "Helvetica Bold";
      font-size: 18px;
      line-height: 0.92;
      letter-spacing: normal;
      color: #000000;
      margin-top: 0; }
    .business-risk__container .business-risk-wrapper .box-wrapper .select-box {
      margin-top: 15px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      border: 1px solid #e0e0e0;
      padding: 3px; }
    .business-risk__container .business-risk-wrapper .box-wrapper .content-box {
      margin-top: 20px; }
      .business-risk__container .business-risk-wrapper .box-wrapper .content-box .content {
        margin: 20px 0; }
        .business-risk__container .business-risk-wrapper .box-wrapper .content-box .content:first-child {
          margin-top: 0; }
        .business-risk__container .business-risk-wrapper .box-wrapper .content-box .content:last-child {
          margin-bottom: 0; }
        .business-risk__container .business-risk-wrapper .box-wrapper .content-box .content label,
        .business-risk__container .business-risk-wrapper .box-wrapper .content-box .content span {
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          display: block; }
        .business-risk__container .business-risk-wrapper .box-wrapper .content-box .content label {
          font-family: "Helvetica Bold"; }
        .business-risk__container .business-risk-wrapper .box-wrapper .content-box .content span {
          font-family: Helvetica; }
    .business-risk__container .business-risk-wrapper .box-wrapper .flag-box {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .business-risk__container .business-risk-wrapper .box-wrapper .flag-box span {
        color: #c60d0d; }
        .business-risk__container .business-risk-wrapper .box-wrapper .flag-box span:last-child {
          margin-left: 10px; }
    .business-risk__container .business-risk-wrapper .box-wrapper .vertical-divider-left {
      position: relative; }
      .business-risk__container .business-risk-wrapper .box-wrapper .vertical-divider-left::before {
        position: absolute;
        left: -10px;
        content: "";
        height: 100%;
        width: 1px;
        border: 1px solid #e0e0e0; }
    .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box {
      margin-top: 15px; }
      .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box h5 {
        text-align: center;
        font-family: "Helvetica Bold";
        font-size: 14px;
        line-height: 0.92;
        letter-spacing: normal;
        margin-top: 0;
        margin-left: 15%; }
      .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box.customer-chart .circular-progressbar {
        border-radius: 50px;
        border: 2px solid #36ddd1; }
      .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box.customer-chart .funnel-chart ul li > span > span {
        background-color: #36ddd1; }
      .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box.vendor-chart .circular-progressbar {
        border-radius: 50px;
        border: 2px solid #56d9fe; }
      .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box.vendor-chart .funnel-chart ul li > span > span {
        background-color: #56d9fe; }
      .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box.product-chart .circular-progressbar {
        border-radius: 50px;
        border: 2px solid #3670cc; }
      .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box.product-chart .funnel-chart ul li > span > span {
        background-color: #3670cc; }
      .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .circular-progressbar {
        width: 100px;
        margin: 10px 0 20px 45px; }
        .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .circular-progressbar .CircularProgressbar-trail {
          stroke: transparent;
          box-shadow: 2px 2px 2px 2px; }
      .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart {
        border-right: 1px solid #e0e0e0; }
        .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul {
          width: 85%;
          margin: 0 auto; }
          .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li {
            display: flex; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li label {
              width: 130px;
              font-family: Helvetica;
              font-size: 14px;
              line-height: 1;
              letter-spacing: normal;
              color: #1d1d1d;
              margin-bottom: 0;
              border-right: 1px solid #e0e0e0;
              padding-top: 3px;
              text-align: right;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding-right: 5px;
              text-align: start;
              word-wrap: break-word; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li > span {
              display: flex;
              margin-bottom: 5px; }
              .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li > span > span {
                display: block;
                margin: 0 auto;
                font-family: Helvetica;
                font-size: 14px;
                line-height: 1.2;
                text-align: center;
                color: #ffffff;
                padding: 3px 0; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:first-child > span > span {
              width: 100%; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(2) > span > span {
              width: 90%; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(3) > span > span {
              width: 80%; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(4) > span > span {
              width: 70%; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(5) > span > span {
              width: 60%; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(6) > span > span {
              width: 50%; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(7) > span > span {
              width: 40%; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(8) > span > span {
              width: 30%; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(9) > span > span {
              width: 20%; }
            .business-risk__container .business-risk-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:last-child > span > span {
              width: 10%; }
    .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box {
      margin-top: 15px;
      margin-bottom: 0; }
      .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box table {
        width: 100%; }
        .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box table thead tr {
          background-color: #4687F4;
          color: #fff; }
        .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box table tbody tr:nth-child(even) {
          background-color: #f7f9fc; }
        .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box table tbody tr:nth-child(odd) {
          background-color: #ffffff; }
        .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box table td,
        .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box table th {
          padding: 10px 20px; }
      .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box p,
      .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box ol li {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d;
        margin-left: 15px; }
      .business-risk__container .business-risk-wrapper .box-wrapper.analysis-box p {
        margin-top: 10px;
        margin-bottom: 5px; }

.sales_form_container .box-wrapper .box-header {
  padding-bottom: 15px;
  border-radius: 15px 15px 0 0; }
  .sales_form_container .box-wrapper .box-header h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: bolder;
    line-height: 1.17;
    color: #4687F4;
    border-bottom: 1px solid lightgray;
    padding-bottom: 1rem; }

.sales_form_container .box-wrapper .table-box {
  overflow-x: scroll; }
  .sales_form_container .box-wrapper .table-box table {
    width: 100%;
    display: block;
    white-space: nowrap;
    border: 1px solid #e9e9e9; }
    .sales_form_container .box-wrapper .table-box table thead tr {
      background-color: #001061;
      color: white;
      border-radius: 0px; }
      .sales_form_container .box-wrapper .table-box table thead tr th {
        border-right: 1px solid #e9e9e9;
        padding: 1rem 2rem;
        text-align: left;
        font-weight: bold !important;
        border-collapse: collapse; }
    .sales_form_container .box-wrapper .table-box table tbody tr:nth-child(even) {
      background-color: #f7f9fc; }
    .sales_form_container .box-wrapper .table-box table tbody tr:nth-child(odd) {
      background-color: #ffffff; }
    .sales_form_container .box-wrapper .table-box table tbody tr td {
      text-align: left;
      border-right: 1px solid #e9e9e9;
      border-collapse: collapse; }
      .sales_form_container .box-wrapper .table-box table tbody tr td:last-child {
        border-right: none; }
      .sales_form_container .box-wrapper .table-box table tbody tr td b {
        font-weight: bold !important; }
      .sales_form_container .box-wrapper .table-box table tbody tr td .border_bottom {
        border-bottom: 1px solid #e9e9e9;
        border-collapse: collapse; }
    .sales_form_container .box-wrapper .table-box table tbody tr .sub_column {
      padding: 1rem 1rem;
      border: 1px solid #e9e9e9;
      border-right: none;
      border-collapse: collapse; }
      .sales_form_container .box-wrapper .table-box table tbody tr .sub_column img {
        height: 3.5rem;
        margin-left: 4rem; }

.sales_form_container .box-wrapper .color-information {
  margin-top: 3rem;
  display: flex; }
  .sales_form_container .box-wrapper .color-information .color-box {
    width: 3.5rem;
    height: 3.5rem; }
  .sales_form_container .box-wrapper .color-information .greater-median {
    background-color: #ecf9f1; }
  .sales_form_container .box-wrapper .color-information .lesser-median {
    background-color: #ffefee; }

.sales_form_container .box-wrapper .box-footer {
  margin: 20px -20px -25px -20px;
  background-color: #001061;
  border-radius: 0 0 15px 15px; }
  .sales_form_container .box-wrapper .box-footer ul {
    display: flex;
    margin-bottom: 0; }
    .sales_form_container .box-wrapper .box-footer ul li {
      flex: 1;
      padding: 10px 15px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .sales_form_container .box-wrapper .box-footer ul li label {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.33;
        letter-spacing: 0;
        color: #ffffff; }
      .sales_form_container .box-wrapper .box-footer ul li > span {
        font-family: Helvetica;
        font-size: 28px;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-top: 10px; }
        .sales_form_container .box-wrapper .box-footer ul li > span .icon {
          margin-top: -3px; }
    .sales_form_container .box-wrapper .box-footer ul:last-child {
      border-top: 0.5px solid rgba(255, 255, 255, 0.5);
      border-radius: 0 0 15px 15px; }
      .sales_form_container .box-wrapper .box-footer ul:last-child li:first-child {
        border-bottom-left-radius: 15px; }
      .sales_form_container .box-wrapper .box-footer ul:last-child li:last-child {
        border-bottom-right-radius: 15px; }

.sales_form_container .box-wrapper .color-information {
  margin-top: 3rem;
  display: flex; }
  .sales_form_container .box-wrapper .color-information .color-box {
    width: 3.5rem;
    height: 3.5rem; }
  .sales_form_container .box-wrapper .color-information .greater-median {
    background-color: #ecf9f1; }
  .sales_form_container .box-wrapper .color-information .lesser-median {
    background-color: #ffefee; }

.sales_form_container .box-wrapper .box-footer {
  margin: 20px -20px -25px -20px;
  background-color: #001061;
  border-radius: 0 0 15px 15px; }
  .sales_form_container .box-wrapper .box-footer ul {
    display: flex;
    margin-bottom: 0; }
    .sales_form_container .box-wrapper .box-footer ul li {
      flex: 1;
      padding: 10px 15px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .sales_form_container .box-wrapper .box-footer ul li label {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.33;
        letter-spacing: 0;
        color: #ffffff; }
      .sales_form_container .box-wrapper .box-footer ul li > span {
        font-family: Helvetica;
        font-size: 28px;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-top: 10px; }
        .sales_form_container .box-wrapper .box-footer ul li > span .icon {
          margin-top: -3px; }
    .sales_form_container .box-wrapper .box-footer ul:last-child {
      border-top: 0.5px solid rgba(255, 255, 255, 0.5);
      border-radius: 0 0 15px 15px; }
      .sales_form_container .box-wrapper .box-footer ul:last-child li:first-child {
        border-bottom-left-radius: 15px; }
      .sales_form_container .box-wrapper .box-footer ul:last-child li:last-child {
        border-bottom-right-radius: 15px; }

.cash-flow-wrapper {
  margin-top: 30px;
  padding: 0px 70px; }
  .cash-flow-wrapper .checkbox__container {
    list-style: none;
    display: flex;
    margin: 20px 0; }
    .cash-flow-wrapper .checkbox__container li {
      margin-right: 10px; }
  .cash-flow-wrapper .btns-box button {
    width: 100%;
    margin-bottom: 10px;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cash-flow-wrapper .btns-box button div {
      margin-right: 5px; }
  .cash-flow-wrapper .nav-tabs {
    margin: 0 30px;
    border-color: #bcbcbc; }
    .cash-flow-wrapper .nav-tabs > li {
      margin: 0 4px -1px;
      background-color: #e5e5e5;
      border-radius: 5px 5px 0 0;
      position: relative; }
      .cash-flow-wrapper .nav-tabs > li a {
        color: #1d1d1d;
        border-radius: 5px 5px 0 0;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        padding: 12px; }
        .cash-flow-wrapper .nav-tabs > li a > div {
          padding: 10px 20px; }
        .cash-flow-wrapper .nav-tabs > li a label {
          font-family: "Helvetica Bold";
          margin-bottom: 0; }
      .cash-flow-wrapper .nav-tabs > li:first-child a > div {
        padding: 18px 20px; }
      .cash-flow-wrapper .nav-tabs > li.active::after {
        content: "";
        width: 75%;
        height: 2px;
        background-color: #4687F4;
        position: absolute;
        bottom: 0;
        left: 13%; }
      .cash-flow-wrapper .nav-tabs > li.active a {
        color: #1d1d1d;
        margin-right: 0;
        font-family: "Helvetica Bold"; }
  .cash-flow-wrapper .tab-content {
    border-right: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
    border-top: 2px solid #e5e5e5;
    border-radius: 25px;
    margin-top: -2px;
    margin-bottom: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px; }
    .cash-flow-wrapper .tab-content .entityName {
      font-size: 20px;
      color: #000;
      padding: 0 15px;
      display: inline-block; }
    .cash-flow-wrapper .tab-content .actions-box {
      padding: 5px 10px;
      display: flex;
      justify-content: flex-end; }
      .cash-flow-wrapper .tab-content .actions-box span {
        padding: 0 10px; }
      .cash-flow-wrapper .tab-content .actions-box .icon-download {
        color: #2d9086;
        cursor: pointer; }
        .cash-flow-wrapper .tab-content .actions-box .icon-download svg {
          color: #1d6f42; }
      .cash-flow-wrapper .tab-content .actions-box .icon-upload {
        color: #001061;
        cursor: pointer; }
      .cash-flow-wrapper .tab-content .actions-box .icon-download-pdf svg {
        color: red; }
    .cash-flow-wrapper .tab-content .box-content {
      border-radius: 12px;
      padding: 15px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
      .cash-flow-wrapper .tab-content .box-content .box-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        align-items: center;
        padding-bottom: 5px; }
        .cash-flow-wrapper .tab-content .box-content .box-header .box-heading {
          margin-top: 0;
          font-family: "Helvetica Bold";
          font-size: 16px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #000; }
        .cash-flow-wrapper .tab-content .box-content .box-header .actions {
          display: flex; }
          .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content {
            display: flex;
            justify-content: center;
            line-height: 1;
            font-family: "Helvetica";
            text-align: center;
            color: #1d1d1d;
            align-items: center; }
            .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content span,
            .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content div {
              padding-right: 5px; }
            .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content span {
              display: inline-block;
              max-width: 75px; }
              .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content span.icon-pdf {
                color: #ed5a5a; }
              .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content span.icon-excel {
                color: #8bd591; }
            .cash-flow-wrapper .tab-content .box-content .box-header .actions .download-content div {
              cursor: pointer; }
        .cash-flow-wrapper .tab-content .box-content .box-header .edit-icon {
          color: #5f5f5f;
          border-bottom: 1px solid #5f5f5f;
          cursor: pointer;
          display: inline-block;
          margin-bottom: 3px; }
        .cash-flow-wrapper .tab-content .box-content .box-header .view-flags {
          color: #ff4444;
          position: absolute;
          right: 35px;
          top: 25px;
          cursor: pointer; }
          .cash-flow-wrapper .tab-content .box-content .box-header .view-flags.disable {
            color: #b9b9b9;
            cursor: not-allowed; }
      .cash-flow-wrapper .tab-content .box-content .bank-details-list {
        list-style: none;
        margin: 10px 0; }
        .cash-flow-wrapper .tab-content .box-content .bank-details-list .sanctionLimitEdit {
          margin-left: 10px; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list .sanctionLimitEdit svg {
            color: #1d1d1d; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list .sanctionLimitEdit:hover {
            cursor: pointer; }
        .cash-flow-wrapper .tab-content .box-content .bank-details-list > li {
          display: flex;
          align-items: center;
          margin: 5px 0; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li label,
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li span {
            font-size: 14px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            margin: 0;
            flex: 1; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li label {
            font-family: "Helvetica Bold"; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li span {
            font-family: "Helvetica"; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li svg {
            color: #b22929; }
          .cash-flow-wrapper .tab-content .box-content .bank-details-list > li .months-list {
            flex: 1;
            display: flex;
            list-style: none;
            width: 100%; }
            .cash-flow-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
              font-family: Helvetica;
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: normal;
              color: #ffffff;
              flex: 1;
              background-color: #4687F4;
              text-align: center;
              border-right: 0.5px solid #fff; }
  .cash-flow-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 35px;
    padding: 25px 20px; }
    .cash-flow-wrapper .box-wrapper .radio-btns {
      display: flex;
      justify-content: end;
      align-items: flex-end;
      margin-top: 20px; }
      .cash-flow-wrapper .box-wrapper .radio-btns label {
        margin-right: 30px;
        margin-top: 10px; }
    .cash-flow-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0; }
      .cash-flow-wrapper .box-wrapper .box-header.tool-tip-box {
        display: flex;
        justify-content: space-between; }
      .cash-flow-wrapper .box-wrapper .box-header .tool-tip {
        margin-top: -3px; }
    .cash-flow-wrapper .box-wrapper .chart-details {
      margin-top: 20px; }
      .cash-flow-wrapper .box-wrapper .chart-details span {
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: normal;
        color: #1d1d1d; }
    .cash-flow-wrapper .box-wrapper .chart-box {
      margin-top: 25px;
      min-height: 200px; }
    .cash-flow-wrapper .box-wrapper .box-footer {
      margin: 20px -20px -25px -20px;
      background-color: #4687F4;
      border-radius: 0 0 15px 15px; }
      .cash-flow-wrapper .box-wrapper .box-footer ul {
        display: flex;
        margin-bottom: 0; }
        .cash-flow-wrapper .box-wrapper .box-footer ul li {
          flex: 1;
          padding: 10px 15px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .cash-flow-wrapper .box-wrapper .box-footer ul li label {
            font-family: Helvetica;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #ffffff;
            margin-bottom: 10px; }
          .cash-flow-wrapper .box-wrapper .box-footer ul li span {
            font-family: Helvetica;
            font-size: 24px;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #ffffff;
            display: flex;
            align-items: center; }
            .cash-flow-wrapper .box-wrapper .box-footer ul li span .icon {
              margin-top: -3px; }
        .cash-flow-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .cash-flow-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px; }
          .cash-flow-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
    .cash-flow-wrapper .box-wrapper h4 {
      font-family: "Helvetica Bold";
      font-size: 18px;
      line-height: 0.92;
      letter-spacing: normal;
      color: #000000;
      margin-top: 0; }
    .cash-flow-wrapper .box-wrapper .select-box {
      margin-top: 15px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      border: 1px solid #e0e0e0;
      padding: 3px; }
    .cash-flow-wrapper .box-wrapper .ReactTable {
      margin: 20px auto 40px; }
      .cash-flow-wrapper .box-wrapper .ReactTable .sticky {
        position: sticky !important;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: white;
        color: #4687F4; }
      .cash-flow-wrapper .box-wrapper .ReactTable .rt-thead .rt-th {
        position: relative;
        background-color: #4687F4;
        font-family: "Helvetica Bold";
        font-size: 14px;
        line-height: 1.25;
        letter-spacing: 0;
        color: #ffffff;
        padding: 12px 0;
        border-right-color: #fff; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
          content: "\f0d7";
          position: absolute;
          font-family: FontAwesome;
          right: 9px;
          top: 10px;
          font-size: 16px; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
          content: "\f0d8";
          position: absolute;
          font-family: FontAwesome;
          right: 9px;
          top: 10px;
          font-size: 16px; }
      .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody {
        overflow: visible !important; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .sticky {
          position: sticky !important;
          left: 0;
          top: 0;
          z-index: 1;
          background-color: white;
          color: #4687F4; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .subIdentifier {
          position: absolute;
          top: 50px;
          left: 40px;
          color: #1d1d1d;
          font-size: 12px; }
        .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr {
          font-family: Helvetica;
          font-size: 14px;
          line-height: 1.25;
          letter-spacing: 0;
          text-align: center;
          color: #1d1d1d; }
          .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
            border-right-color: transparent; }
            .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
              color: #fff;
              border-radius: 25px;
              width: 23px;
              height: 24px;
              display: inline-block;
              text-align: center;
              padding-top: 2px; }
              .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons .bold {
                font-weight: 700 !important; }
              .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
                background-color: #8bd591; }
              .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
                background-color: #ed5a5a; }
            .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td:first-child {
              font-weight: 700 !important; }
          .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr.-odd {
            background-color: #F7F9FC; }
          .cash-flow-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr.-even {
            background-color: #fff; }
    .cash-flow-wrapper .box-wrapper .filter-box {
      text-align: center; }
      .cash-flow-wrapper .box-wrapper .filter-box select {
        width: 50%;
        border-radius: 10px;
        border: 1px solid #bcbcbc;
        margin-right: 10px;
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1d1d1d;
        padding: 7px; }
      .cash-flow-wrapper .box-wrapper .filter-box button {
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 7px 15px 9px;
        background-color: #4687f4;
        margin-left: 10px; }
  .cash-flow-wrapper .ratio {
    height: 37px;
    font-family: Helvetica;
    font-size: 38px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    text-align: left;
    color: #4687F4; }
  .cash-flow-wrapper .edit-ratio {
    cursor: pointer; }

.red-flag__wrapper {
  border-radius: 10px; }
  .red-flag__wrapper.disable {
    display: none; }
  .red-flag__wrapper::-webkit-scrollbar-track {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; }
  .red-flag__wrapper::-webkit-scrollbar-thumb {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; }
  .red-flag__wrapper .heading {
    font-family: Helvetica;
    font-size: 22px;
    color: #4687f4;
    padding-bottom: 1rem; }
  .red-flag__wrapper .information__wrapper {
    position: relative; }
    .red-flag__wrapper .information__wrapper .sub-heading {
      padding: 1rem 0rem;
      font-weight: 600 !important;
      font-size: 18px;
      color: #001061; }
    .red-flag__wrapper .information__wrapper .uploaded-files-table .ReactTable .rt-tbody .rt-tr-group .rt-td {
      text-align: center;
      padding: 12px 4px !important; }
    .red-flag__wrapper .information__wrapper .data {
      display: flex;
      position: relative;
      flex-wrap: wrap; }
      .red-flag__wrapper .information__wrapper .data .info-columns {
        padding: 0.2rem 2rem 0.2rem 0rem;
        width: 24rem; }
        .red-flag__wrapper .information__wrapper .data .info-columns .labels {
          font-weight: bold !important;
          font-size: 14px;
          color: #1d1d1d;
          padding: 0.2rem; }
        .red-flag__wrapper .information__wrapper .data .info-columns .value {
          font-size: 18px;
          color: #1d1d1d;
          padding: 0.2rem; }
          .red-flag__wrapper .information__wrapper .data .info-columns .value.match {
            color: red; }
    .red-flag__wrapper .information__wrapper.border-bottom {
      border-top: 1.5px solid #bcbcbc; }
    .red-flag__wrapper .information__wrapper .green {
      color: rgba(0, 205, 180, 0.57);
      position: absolute;
      top: 8px;
      right: 15px;
      transition: transform 0.5s ease-in-out; }
      .red-flag__wrapper .information__wrapper .green.rotate {
        transform: rotate(180deg); }

.edit-ratio-form {
  text-align: center; }
  .edit-ratio-form .input-container {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
    flex-direction: column; }
  .edit-ratio-form label {
    font-size: 16px;
    padding-bottom: 6px;
    text-align: left; }
  .edit-ratio-form .input {
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    border: solid 1px #bcbcbc;
    background-color: #ffffff; }
  .edit-ratio-form .submit {
    width: 110px;
    height: 40px;
    border-radius: 25px;
    color: white;
    background-color: #29a9e0;
    border: none; }
  .edit-ratio-form .span {
    font-size: 16px;
    text-align: center;
    margin: 10px;
    display: block; }
  .edit-ratio-form .submit1 {
    width: 163px;
    height: 40px;
    color: white;
    background-color: #29a9e0;
    background: #001061;
    border-radius: 30px;
    border: none; }
  .edit-ratio-form span {
    padding: 10px; }

.overview-applicant-modal .cross-icon {
  right: 20px !important;
  top: 5px !important; }

.overview-applicant-modal.edit-ratio-modal h3 {
  font-size: 22px;
  padding-bottom: 10px; }

.red-flag__wrapper .ReactTable {
  margin: 2px auto 0; }
  .red-flag__wrapper .ReactTable .rt-thead .rt-th {
    padding: 10px; }
    .red-flag__wrapper .ReactTable .rt-thead .rt-th:first-child {
      text-align: center !important;
      padding-left: 0px; }
  .red-flag__wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
    border-right-color: transparent;
    padding: 10px;
    text-align: center; }

.ReactModalPortal .limit-utilization__modal-table {
  margin-top: 20px;
  min-width: 100%; }
  .ReactModalPortal .limit-utilization__modal-table-row {
    text-align: center;
    border-bottom: 5px solid #fff;
    background-color: #fafafa; }
    .ReactModalPortal .limit-utilization__modal-table-row:first-child {
      background-color: #f0f0f0; }
    .ReactModalPortal .limit-utilization__modal-table-row .table-header {
      color: #fff;
      background-color: #001061;
      font-weight: 500 !important;
      padding: 20px; }
    .ReactModalPortal .limit-utilization__modal-table-row-data {
      color: #1d1d1d;
      border-right: 4px solid #fff; }
      .ReactModalPortal .limit-utilization__modal-table-row-data input {
        border-radius: 10px;
        text-align: center;
        background-color: transparent;
        border: 1px solid black;
        padding: 5px; }

.ReactModalPortal .limit-utilization__modal .save-btn {
  width: 225px;
  border-radius: 25px;
  background-color: #4687F4;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #ffffff;
  border: none;
  padding: 10px;
  margin-top: 20px;
  justify-content: center; }

.credit-behaviour-wrapper {
  margin-top: 30px; }
  .credit-behaviour-wrapper h4 {
    font-family: "Helvetica Bold";
    font-size: 18px;
    line-height: 0.92;
    letter-spacing: normal;
    color: #000;
    margin-top: 0; }
  .credit-behaviour-wrapper .btns-box button {
    width: 100%;
    margin-bottom: 10px;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .credit-behaviour-wrapper .btns-box button.add-btn {
      background-color: #00cdbe; }
    .credit-behaviour-wrapper .btns-box button.edit-btn {
      background-color: #29a9e0; }
    .credit-behaviour-wrapper .btns-box button.reset-btn {
      background-color: #ff0000; }
    .credit-behaviour-wrapper .btns-box button div {
      margin-right: 5px; }
  .credit-behaviour-wrapper .nav-tabs {
    margin: 30px 30px 0;
    border-color: #bcbcbc; }
    .credit-behaviour-wrapper .nav-tabs > li {
      margin: 0 4px -1px;
      background-color: #e5e5e5;
      border-radius: 5px 5px 0 0;
      position: relative; }
      .credit-behaviour-wrapper .nav-tabs > li a {
        color: #1d1d1d;
        border-radius: 5px 5px 0 0;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        padding: 10px 20px; }
        .credit-behaviour-wrapper .nav-tabs > li a label {
          font-family: "Helvetica Bold";
          margin-bottom: 0; }
      .credit-behaviour-wrapper .nav-tabs > li.active::after {
        content: "";
        width: 75%;
        height: 2px;
        background-color: #4687F4;
        position: absolute;
        bottom: 0;
        left: 13%; }
      .credit-behaviour-wrapper .nav-tabs > li.active a {
        color: #1d1d1d;
        margin-right: 0;
        font-family: "Helvetica Bold"; }
  .credit-behaviour-wrapper .tab-content {
    border-right: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
    border-top: 2px solid #e5e5e5;
    border-radius: 25px;
    margin-top: -2px;
    margin-bottom: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px; }
    .credit-behaviour-wrapper .tab-content .box-content {
      background-color: #fff;
      border-radius: 12px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
      .credit-behaviour-wrapper .tab-content .box-content .box-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
        .credit-behaviour-wrapper .tab-content .box-content .box-header .box-heading {
          margin-top: 0;
          font-family: "Helvetica Bold";
          font-size: 16px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #00cdbe; }
        .credit-behaviour-wrapper .tab-content .box-content .box-header .edit-icon {
          color: #5f5f5f;
          border-bottom: 1px solid #5f5f5f;
          cursor: pointer;
          display: inline-block;
          margin-bottom: 3px; }
      .credit-behaviour-wrapper .tab-content .box-content .bank-details-list {
        list-style: none;
        margin: 10px 0; }
        .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li {
          display: flex;
          align-items: center;
          margin: 5px 0; }
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li label,
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li span {
            font-size: 14px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            margin: 0;
            flex: 1; }
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li label {
            font-family: "Helvetica Bold"; }
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li span {
            font-family: "Helvetica"; }
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li svg {
            color: #b22929; }
          .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li .months-list {
            flex: 1;
            display: flex;
            list-style: none;
            width: 100%; }
            .credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
              font-family: Helvetica;
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: normal;
              color: #ffffff;
              flex: 1;
              background-color: #8bd591;
              text-align: center;
              border-right: 0.5px solid #fff; }
  .credit-behaviour-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 35px;
    padding: 25px 20px; }
    .credit-behaviour-wrapper .box-wrapper .heatMapArrow {
      text-align: center; }
      .credit-behaviour-wrapper .box-wrapper .heatMapArrow .arrow {
        font-size: 40px; }
    .credit-behaviour-wrapper .box-wrapper.secondary {
      padding: 0; }
      .credit-behaviour-wrapper .box-wrapper.secondary .box-header {
        background-color: #eeeeee;
        padding: 15px;
        border-radius: 15px 15px 0 0; }
        .credit-behaviour-wrapper .box-wrapper.secondary .box-header h4 {
          margin-bottom: 0; }
      .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0; }
        .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li {
          padding: 15px;
          border-right: 1px solid #eeeeee;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li label {
            font-family: Helvetica;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #1d1d1d;
            margin-bottom: 10px; }
          .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li span {
            font-family: Helvetica;
            font-size: 14px;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #1d1d1d;
            display: flex;
            align-items: center; }
            .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li span .icon {
              margin-top: -3px; }
          .credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li:last-child {
            border-right: none; }
    .credit-behaviour-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0; }
    .credit-behaviour-wrapper .box-wrapper .chart-details {
      margin-top: 20px; }
      .credit-behaviour-wrapper .box-wrapper .chart-details span {
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: normal;
        color: #1d1d1d; }
    .credit-behaviour-wrapper .box-wrapper .chart-box {
      margin-top: 25px; }
    .credit-behaviour-wrapper .box-wrapper .box-footer {
      margin: 20px -20px -25px -20px;
      background-color: #4687F4;
      border-radius: 0 0 15px 15px; }
      .credit-behaviour-wrapper .box-wrapper .box-footer ul {
        display: flex;
        margin-bottom: 0; }
        .credit-behaviour-wrapper .box-wrapper .box-footer ul li {
          flex: 1;
          padding: 10px 15px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .credit-behaviour-wrapper .box-wrapper .box-footer ul li label {
            font-family: Helvetica;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #ffffff;
            margin-bottom: 10px; }
          .credit-behaviour-wrapper .box-wrapper .box-footer ul li span {
            font-family: Helvetica;
            font-size: 24px;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #ffffff;
            display: flex;
            align-items: center; }
            .credit-behaviour-wrapper .box-wrapper .box-footer ul li span .icon {
              margin-top: -3px; }
        .credit-behaviour-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .credit-behaviour-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px; }
          .credit-behaviour-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
    .credit-behaviour-wrapper .box-wrapper .select-box {
      margin-top: 15px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      border: 1px solid #e0e0e0;
      padding: 3px; }
  .credit-behaviour-wrapper .ReactTable {
    margin: 20px auto; }
    .credit-behaviour-wrapper .ReactTable .rt-thead .rt-th {
      position: relative;
      background-color: #4687F4;
      font-family: "Helvetica Bold";
      font-size: 12px;
      line-height: 1.25;
      letter-spacing: 0;
      color: #ffffff;
      padding: 12px 0;
      border-right-color: #fff;
      white-space: unset; }
      .credit-behaviour-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
        content: "\f0d7";
        \position: absolute;
        font-family: FontAwesome;
        right: 9px;
        top: 10px;
        font-size: 16px; }
      .credit-behaviour-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
        content: "\f0d8";
        position: absolute;
        font-family: FontAwesome;
        right: 9px;
        top: 10px;
        font-size: 16px; }
    .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr {
      font-family: Helvetica;
      font-size: 13px;
      line-height: 1.25;
      letter-spacing: 0;
      text-align: center;
      color: #1d1d1d;
      padding: 7px 0; }
      .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
        border-right-color: transparent; }
        .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
          color: #fff;
          border-radius: 25px;
          width: 23px;
          height: 24px;
          display: inline-block;
          text-align: center;
          padding-top: 2px; }
          .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
            background-color: #78e35f; }
          .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
            background-color: #ff0000; }
      .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr.-odd {
        background-color: #f7f9fc; }
      .credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr.-even {
        background-color: #ffffff; }
  .credit-behaviour-wrapper .applicants-table .rt-thead .rt-th {
    font-size: 12px;
    word-wrap: break-word; }
    .credit-behaviour-wrapper .applicants-table .rt-thead .rt-th.-sort-asc::after, .credit-behaviour-wrapper .applicants-table .rt-thead .rt-th.-sort-desc::after {
      right: 5px;
      top: 20px; }
  .credit-behaviour-wrapper .applicants-table .rt-tbody .rt-tr .rt-td {
    white-space: unset; }
  .credit-behaviour-wrapper .filters-box .icon-download {
    float: right; }
    .credit-behaviour-wrapper .filters-box .icon-download svg {
      color: #2d9086 !important; }
  .credit-behaviour-wrapper .filters-box label {
    font-family: "Helvetica Bold";
    font-size: 14px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #1d1d1d; }
  .credit-behaviour-wrapper .filters-box ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .credit-behaviour-wrapper .filters-box ul li {
      margin-right: 10px; }
    .credit-behaviour-wrapper .filters-box ul label {
      font-family: Helvetica;
      font-size: 12px; }
  .credit-behaviour-wrapper .filters-box button {
    font-family: Helvetica;
    font-size: 14px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #00cdbe;
    background-color: transparent;
    border-radius: 15px;
    border: 1px solid #00cdbe;
    padding: 3px 5px; }
  .credit-behaviour-wrapper .deliquency-trend {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 0; }
    .credit-behaviour-wrapper .deliquency-trend li {
      flex: 1;
      padding: 9px 0; }
  .credit-behaviour-wrapper .text-input-field {
    resize: none;
    max-height: 100px;
    width: 90%;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px; }
  .credit-behaviour-wrapper .heading {
    font-size: 12px;
    color: #ffffff; }
  .credit-behaviour-wrapper .subheading {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding: 2px 4px; }
    .credit-behaviour-wrapper .subheading .liveLoans {
      color: #00cdbe;
      font-size: 12px;
      font-weight: 800 !important; }
    .credit-behaviour-wrapper .subheading span {
      min-width: 45%; }
      .credit-behaviour-wrapper .subheading span:first-child {
        border-right: 1px solid #fff; }

.boldText {
  font-weight: 600 !important;
  color: #1d1d1d;
  font-size: 14px;
  display: inline-block;
  margin-right: 1em; }

.credit-capacity-wrapper {
  margin-top: 30px; }
  .credit-capacity-wrapper .credit-capacity-modal {
    width: 85%;
    border-radius: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 25px;
    position: relative; }
    .credit-capacity-wrapper .credit-capacity-modal .select-box {
      border-radius: 10px;
      padding: 5px; }
    .credit-capacity-wrapper .credit-capacity-modal .box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #bcbcbc;
      padding: 0 10px 5px; }
      .credit-capacity-wrapper .credit-capacity-modal .box-header h3 {
        font-family: Helvetica;
        font-size: 24px;
        line-height: 1;
        letter-spacing: -0.2px;
        color: #001061;
        margin: 0; }
      .credit-capacity-wrapper .credit-capacity-modal .box-header .cross-icon {
        color: #bcbcbc;
        cursor: pointer; }
    .credit-capacity-wrapper .credit-capacity-modal table {
      width: 100%;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fafafa;
      margin: 25px 0;
      overflow-x: scroll;
      overflow-y: hidden; }
      .credit-capacity-wrapper .credit-capacity-modal table::-webkit-scrollbar {
        height: 3px; }
      .credit-capacity-wrapper .credit-capacity-modal table::-webkit-scrollbar-button {
        display: none; }
      .credit-capacity-wrapper .credit-capacity-modal table::-webkit-scrollbar-track {
        background: transparent; }
      .credit-capacity-wrapper .credit-capacity-modal table::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #4687F4; }
      .credit-capacity-wrapper .credit-capacity-modal table thead tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #001061; }
        .credit-capacity-wrapper .credit-capacity-modal table thead tr th {
          flex: 1;
          font-family: "Helvetica Bold";
          font-size: 14px;
          line-height: 1.25;
          letter-spacing: 0;
          color: #ffffff;
          text-align: center;
          padding: 10px 6px;
          border-right: 1px solid #fff;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .credit-capacity-wrapper .credit-capacity-modal table thead tr th:last-child {
            border-right: none; }
      .credit-capacity-wrapper .credit-capacity-modal table tbody tr {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .credit-capacity-wrapper .credit-capacity-modal table tbody tr td {
          flex: 1;
          font-family: Helvetica;
          font-size: 14px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #1d1d1d;
          padding: 15px 5px;
          position: relative; }
          .credit-capacity-wrapper .credit-capacity-modal table tbody tr td .select-box {
            display: inline-block;
            border: 1px solid #bcbcbc;
            border-radius: 7px;
            width: 100%;
            padding: 9px;
            font-family: "Helvetica";
            font-size: 14px;
            line-height: 1.14;
            letter-spacing: normal; }
          .credit-capacity-wrapper .credit-capacity-modal table tbody tr td .input-field,
          .credit-capacity-wrapper .credit-capacity-modal table tbody tr td .date-field {
            padding: 12px; }
          .credit-capacity-wrapper .credit-capacity-modal table tbody tr td label {
            top: 15px;
            width: 80%; }
    .credit-capacity-wrapper .credit-capacity-modal .btns-box {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 5px; }
      .credit-capacity-wrapper .credit-capacity-modal .btns-box button {
        background-color: #4687F4;
        padding: 10px;
        border: none;
        border-radius: 25px;
        font-family: Helvetica;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: 0;
        color: #ffffff;
        width: 100px; }
  .credit-capacity-wrapper .content-wrapper h4 {
    font-family: "Helvetica Bold";
    font-size: 18px;
    line-height: 0.92;
    letter-spacing: normal;
    color: #000000;
    margin-top: 0; }
  .credit-capacity-wrapper .content-wrapper button {
    border: none;
    border-radius: 15px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff; }
  .credit-capacity-wrapper .content-wrapper table {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
    margin: 10px 0 35px;
    overflow: hidden; }
    .credit-capacity-wrapper .content-wrapper table thead tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #eeeeee; }
      .credit-capacity-wrapper .content-wrapper table thead tr th {
        flex: 1;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 0.92;
        letter-spacing: normal;
        color: #001061;
        padding: 12px 15px;
        border-right: 1px solid #fff; }
        .credit-capacity-wrapper .content-wrapper table thead tr th:nth-child(2), .credit-capacity-wrapper .content-wrapper table thead tr th:nth-child(3) {
          flex: 1.25; }
        .credit-capacity-wrapper .content-wrapper table thead tr th:first-child {
          border-top-left-radius: 15px; }
        .credit-capacity-wrapper .content-wrapper table thead tr th:last-child {
          border-top-right-radius: 15px;
          border: none;
          flex: 0.9; }
    .credit-capacity-wrapper .content-wrapper table tbody tr {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .credit-capacity-wrapper .content-wrapper table tbody tr td {
        flex: 1;
        font-family: Helvetica;
        font-size: 24px;
        line-height: 0.92;
        letter-spacing: normal;
        color: #001061;
        padding: 12px 20px; }
        .credit-capacity-wrapper .content-wrapper table tbody tr td:nth-child(2), .credit-capacity-wrapper .content-wrapper table tbody tr td:nth-child(3) {
          flex: 1.25; }
        .credit-capacity-wrapper .content-wrapper table tbody tr td:last-child {
          flex: 0.9; }
        .credit-capacity-wrapper .content-wrapper table tbody tr td input {
          padding: 7px 12px; }
  .credit-capacity-wrapper .content-wrapper .btns-box {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px; }
    .credit-capacity-wrapper .content-wrapper .btns-box button {
      background-color: #4687F4;
      padding: 7px 12px; }
  .credit-capacity-wrapper .content-wrapper .nav-tabs {
    margin: 30px 30px 0;
    border-color: #bcbcbc; }
    .credit-capacity-wrapper .content-wrapper .nav-tabs > li {
      margin: 0 4px -1px;
      background-color: #e5e5e5;
      border-radius: 5px 5px 0 0;
      position: relative; }
      .credit-capacity-wrapper .content-wrapper .nav-tabs > li a {
        color: #1d1d1d;
        border-radius: 5px 5px 0 0;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        padding: 10px 20px; }
        .credit-capacity-wrapper .content-wrapper .nav-tabs > li a label {
          font-family: "Helvetica Bold";
          margin-bottom: 0; }
      .credit-capacity-wrapper .content-wrapper .nav-tabs > li.active::after {
        content: "";
        width: 75%;
        height: 2px;
        background-color: #4687F4;
        position: absolute;
        bottom: 0;
        left: 13%; }
      .credit-capacity-wrapper .content-wrapper .nav-tabs > li.active a {
        color: #1d1d1d;
        margin-right: 0;
        font-family: "Helvetica Bold"; }
  .credit-capacity-wrapper .content-wrapper .tab-content {
    border-right: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
    border-top: 2px solid #e5e5e5;
    border-radius: 25px;
    margin-top: -2px;
    margin-bottom: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px; }
    .credit-capacity-wrapper .content-wrapper .tab-content .box-content {
      background-color: #fafafa;
      border-radius: 12px;
      padding: 15px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
      .credit-capacity-wrapper .content-wrapper .tab-content .box-content .box-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
        .credit-capacity-wrapper .content-wrapper .tab-content .box-content .box-header .box-heading {
          margin-top: 0;
          font-family: "Helvetica Bold";
          font-size: 16px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #00cdbe; }
        .credit-capacity-wrapper .content-wrapper .tab-content .box-content .box-header .edit-icon {
          color: #5f5f5f;
          border-bottom: 1px solid #5f5f5f;
          cursor: pointer;
          display: inline-block;
          margin-bottom: 3px; }
      .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list {
        list-style: none;
        margin: 10px 0; }
        .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li {
          display: flex;
          align-items: center;
          margin: 5px 0; }
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li label,
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li span {
            font-size: 14px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            margin: 0;
            flex: 1; }
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li label {
            font-family: "Helvetica Bold"; }
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li span {
            font-family: "Helvetica"; }
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li svg {
            color: #b22929; }
          .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li .months-list {
            flex: 1;
            display: flex;
            list-style: none;
            width: 100%; }
            .credit-capacity-wrapper .content-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
              font-family: Helvetica;
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: normal;
              color: #ffffff;
              flex: 1;
              background-color: #8bd591;
              text-align: center;
              border-right: 0.5px solid #fff; }
  .credit-capacity-wrapper .content-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 35px;
    padding: 25px 20px; }
    .credit-capacity-wrapper .content-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0; }
      .credit-capacity-wrapper .content-wrapper .box-wrapper .box-header button {
        background-color: #4687F4;
        padding: 5px 10px;
        margin-bottom: 10px; }
    .credit-capacity-wrapper .content-wrapper .box-wrapper .chart-box {
      margin-top: 25px; }
    .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer {
      margin: 20px -20px -25px -20px;
      background-color: #001061;
      border-radius: 0 0 15px 15px; }
      .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul {
        display: flex;
        margin-bottom: 0; }
        .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul li {
          flex: 1;
          padding: 10px 15px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul li label {
            font-family: Helvetica;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #ffffff;
            margin-bottom: 10px; }
          .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul li span {
            font-family: Helvetica;
            font-size: 24px;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #ffffff;
            display: flex;
            align-items: center; }
            .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul li span .icon {
              margin-top: -3px; }
        .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px; }
          .credit-capacity-wrapper .content-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
  .credit-capacity-wrapper .content-wrapper .ReactTable {
    margin: 20px auto 0; }
    .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table {
      overflow-x: scroll; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table::-webkit-scrollbar {
        height: 10px; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table::-webkit-scrollbar-button {
        display: none; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table::-webkit-scrollbar-track {
        background: transparent; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-table::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #4687F4; }
    .credit-capacity-wrapper .content-wrapper .ReactTable .rt-thead {
      box-shadow: none !important; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-thead .rt-th {
        position: relative;
        background-color: #4687F4;
        font-family: "Helvetica Bold";
        font-size: 14px;
        line-height: 1.25;
        letter-spacing: 0;
        color: #ffffff;
        padding: 12px 0;
        border-right-color: #fff; }
        .credit-capacity-wrapper .content-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
          content: "\f0d7";
          position: absolute;
          font-family: FontAwesome;
          right: 9px;
          top: 10px;
          font-size: 16px; }
        .credit-capacity-wrapper .content-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
          content: "\f0d8";
          position: absolute;
          font-family: FontAwesome;
          right: 9px;
          top: 10px;
          font-size: 16px; }
    .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr {
      font-family: Helvetica;
      font-size: 13px;
      line-height: 1.25;
      letter-spacing: 0;
      text-align: center;
      color: #1d1d1d;
      padding: 0; }
      .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
        border-right-color: transparent; }
        .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
          color: #fff;
          border-radius: 25px;
          width: 23px;
          height: 24px;
          display: inline-block;
          text-align: center;
          padding-top: 2px; }
          .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
            background-color: #78e35f; }
          .credit-capacity-wrapper .content-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
            background-color: #ff0000; }
  .credit-capacity-wrapper .content-wrapper .obligations-table .rt-thead .rt-th {
    white-space: normal;
    font-size: 12px; }
    .credit-capacity-wrapper .content-wrapper .obligations-table .rt-thead .rt-th.-sort-asc::after, .credit-capacity-wrapper .content-wrapper .obligations-table .rt-thead .rt-th.-sort-desc::after {
      right: 5px;
      top: 20px; }
  .credit-capacity-wrapper .content-wrapper .obligations-table .rt-tbody .rt-tr .rt-td {
    white-space: normal; }
  .credit-capacity-wrapper .content-wrapper .obligations-table .editButton {
    color: #001061;
    border-bottom: 1px dotted black;
    margin-left: 10px; }
    .credit-capacity-wrapper .content-wrapper .obligations-table .editButton:hover {
      font-size: 120%;
      color: #00cdbe; }
  .credit-capacity-wrapper .content-wrapper .obligations-data {
    margin: 0; }
    .credit-capacity-wrapper .content-wrapper .obligations-data > div {
      background-color: #f0f0f0;
      padding-top: 5px;
      padding-bottom: 5px; }
      .credit-capacity-wrapper .content-wrapper .obligations-data > div label {
        font-family: Helvetica;
        font-size: 12px;
        color: #1d1d1d; }
      .credit-capacity-wrapper .content-wrapper .obligations-data > div span {
        width: 100px;
        display: inline-block;
        margin-left: 15px;
        text-align: left;
        font-family: "Helvetica Bold";
        font-size: 14px;
        color: #00cdbe; }
      .credit-capacity-wrapper .content-wrapper .obligations-data > div:last-child {
        background-color: #fafafa; }
  .credit-capacity-wrapper .content-wrapper .accounts-table {
    border: none; }
    .credit-capacity-wrapper .content-wrapper .accounts-table .rt-tr .rt-td {
      display: flex;
      align-items: center;
      justify-content: center; }
      .credit-capacity-wrapper .content-wrapper .accounts-table .rt-tr .rt-td:last-child {
        background-color: transparent !important; }
      .credit-capacity-wrapper .content-wrapper .accounts-table .rt-tr .rt-td button {
        background-color: #4687F4;
        width: 65px;
        padding: 5px 0; }
  .credit-capacity-wrapper .content-wrapper .filters-box {
    margin-top: 20px; }
    .credit-capacity-wrapper .content-wrapper .filters-box .icon-download {
      float: right; }
      .credit-capacity-wrapper .content-wrapper .filters-box .icon-download svg {
        color: #2d9086 !important; }
    .credit-capacity-wrapper .content-wrapper .filters-box label {
      font-family: "Helvetica Bold";
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d; }
    .credit-capacity-wrapper .content-wrapper .filters-box ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .credit-capacity-wrapper .content-wrapper .filters-box ul label {
        font-family: Helvetica; }
    .credit-capacity-wrapper .content-wrapper .filters-box button {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #00cdbe;
      background-color: transparent;
      border-radius: 15px;
      border: 1px solid #00cdbe;
      padding: 3px 5px; }
  .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul {
    margin: 30px;
    list-style: none; }
    .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li {
      margin: 35px 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li:first-child {
        margin-top: 15px; }
      .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li:last-child .rangeslider {
        border: 1px solid #001061; }
        .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li:last-child .rangeslider .rangeslider__handle {
          background-color: #001061; }
        .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li:last-child .rangeslider .rangeslider__fill {
          background-color: #001061; }
      .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li label {
        flex: 0.75;
        text-align: right;
        font-family: Helvetica;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1d1d1d;
        margin-bottom: 0; }
      .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li .rangeslider {
        flex: 1.25;
        margin: 0 0 0 10px;
        height: 7px;
        background-color: transparent;
        border: 1px solid #4687F4;
        box-shadow: none; }
        .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li .rangeslider .rangeslider__handle {
          background-color: #4687F4;
          width: 15px;
          height: 15px;
          box-shadow: none;
          border: none;
          transform: translate3d(-10%, -50%, 0); }
          .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li .rangeslider .rangeslider__handle::after {
            display: none; }
          .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li .rangeslider .rangeslider__handle .rangeslider__handle-label {
            margin: -20px 0 0 -10px;
            white-space: nowrap; }
        .credit-capacity-wrapper .content-wrapper .emi-scenario-box ul li .rangeslider .rangeslider__fill {
          background-color: #4687F4; }
  .credit-capacity-wrapper .content-wrapper .emi-scenario-box table thead tr th {
    font-size: 14px;
    padding: 12px 10px; }
  .credit-capacity-wrapper .content-wrapper .emi-scenario-box table tbody tr td {
    font-size: 18px;
    padding: 12px 10px; }
  .credit-capacity-wrapper .content-wrapper .emi-scenario-box .emiValueWrapper {
    font-size: 20px;
    margin: 30px; }
    .credit-capacity-wrapper .content-wrapper .emi-scenario-box .emiValueWrapper .emiValueBox {
      border: 1px solid #00cdbe;
      padding: 10px;
      border-radius: 5px; }

.text-input-field {
  resize: none;
  max-height: 100px;
  width: 90%;
  overflow: hidden;
  border-radius: 5px;
  padding: 5px; }

img {
  height: 15px; }

.flag-wrapper.content-container {
  margin: 50px auto 20px;
  width: 85%; }

.flag-wrapper .section-box {
  margin-bottom: 35px; }
  .flag-wrapper .section-box .section-header {
    text-align: center; }
    .flag-wrapper .section-box .section-header h4 {
      background: linear-gradient(to right, #00cdbe, #001061);
      width: 350px;
      margin-bottom: 0;
      font-family: Helvetica;
      font-size: 18px;
      line-height: 1.21;
      letter-spacing: normal;
      color: #ffffff;
      padding: 5px 0 3px;
      border-radius: 25px;
      cursor: pointer;
      display: inline-block;
      text-align: center; }
      .flag-wrapper .section-box .section-header h4 .icon {
        display: inline-block; }
  .flag-wrapper .section-box .react-slidedown {
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 15px;
    padding: 40px 20px 0;
    margin-top: -15px; }
  .flag-wrapper .section-box.open .section-header .icon {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-name: iconDown;
    animation-name: iconDown;
    animation-duration: .35s;
    transform: rotate(0deg); }
  .flag-wrapper .section-box.closed .section-header .icon {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-name: iconUp;
    animation-name: iconUp;
    animation-duration: .35s;
    transform: rotate(180deg); }

.flag-wrapper h4 {
  font-family: "Helvetica Bold";
  font-size: 18px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #000;
  margin-top: 0; }

.flag-wrapper .nav-tabs {
  margin: 30px 30px 0;
  border-color: #bcbcbc; }
  .flag-wrapper .nav-tabs > li {
    margin: 0 4px -1px;
    background-color: #e5e5e5;
    border-radius: 5px 5px 0 0;
    position: relative; }
    .flag-wrapper .nav-tabs > li a {
      color: #1d1d1d;
      border-radius: 5px 5px 0 0;
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      padding: 10px 20px; }
      .flag-wrapper .nav-tabs > li a label {
        font-family: "Helvetica Bold";
        margin-bottom: 0; }
    .flag-wrapper .nav-tabs > li.active::after {
      content: '';
      width: 75%;
      height: 2px;
      background-color: #00cdbe;
      position: absolute;
      bottom: 0;
      left: 13%; }
    .flag-wrapper .nav-tabs > li.active a {
      color: #1d1d1d;
      margin-right: 0;
      font-family: "Helvetica Bold"; }

.flag-wrapper .tab-content {
  border-right: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  border-left: 1px solid #bcbcbc;
  border-top: 2px solid #e5e5e5;
  border-radius: 25px;
  margin-top: -2px;
  margin-bottom: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 20px; }
  .flag-wrapper .tab-content .box-content {
    background-color: #fafafa;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .flag-wrapper .tab-content .box-content .box-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
      .flag-wrapper .tab-content .box-content .box-header .box-heading {
        margin-top: 0;
        font-family: "Helvetica Bold";
        font-size: 16px;
        line-height: 0.92;
        letter-spacing: normal;
        color: #00cdbe; }
      .flag-wrapper .tab-content .box-content .box-header .edit-icon {
        color: #5f5f5f;
        border-bottom: 1px solid #5f5f5f;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 3px; }
    .flag-wrapper .tab-content .box-content .bank-details-list {
      list-style: none;
      margin: 10px 0; }
      .flag-wrapper .tab-content .box-content .bank-details-list > li {
        display: flex;
        align-items: center;
        margin: 5px 0; }
        .flag-wrapper .tab-content .box-content .bank-details-list > li label,
        .flag-wrapper .tab-content .box-content .bank-details-list > li span {
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          margin: 0;
          flex: 1; }
        .flag-wrapper .tab-content .box-content .bank-details-list > li label {
          font-family: "Helvetica Bold"; }
        .flag-wrapper .tab-content .box-content .bank-details-list > li span {
          font-family: "Helvetica"; }
        .flag-wrapper .tab-content .box-content .bank-details-list > li svg {
          color: #b22929; }
        .flag-wrapper .tab-content .box-content .bank-details-list > li .months-list {
          flex: 1;
          display: flex;
          list-style: none;
          width: 100%; }
          .flag-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
            font-family: Helvetica;
            font-size: 12px;
            line-height: 1.67;
            letter-spacing: normal;
            color: #ffffff;
            flex: 1;
            background-color: #8bd591;
            text-align: center;
            border-right: 0.5px solid #fff; }

.flag-wrapper .box-wrapper {
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 25px 20px; }
  .flag-wrapper .box-wrapper.secondary {
    padding: 0; }
    .flag-wrapper .box-wrapper.secondary .box-header {
      background-color: #eeeeee;
      padding: 15px;
      border-radius: 15px 15px 0 0; }
      .flag-wrapper .box-wrapper.secondary .box-header h4 {
        margin-bottom: 0;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d; }
    .flag-wrapper .box-wrapper.secondary .box-content ul {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0; }
      .flag-wrapper .box-wrapper.secondary .box-content ul li {
        padding: 15px;
        border-right: 1px solid #eeeeee;
        flex: 1; }
        .flag-wrapper .box-wrapper.secondary .box-content ul li .boldText {
          font-weight: 600 !important;
          color: #1d1d1d;
          font-size: 14px;
          display: inline-block;
          margin-right: 1em; }
        .flag-wrapper .box-wrapper.secondary .box-content ul li label {
          font-family: Helvetica;
          font-size: 14px;
          line-height: 1.33;
          letter-spacing: 0;
          color: #1d1d1d;
          margin-bottom: 10px; }
        .flag-wrapper .box-wrapper.secondary .box-content ul li span {
          font-family: Helvetica;
          font-size: 14px;
          font-stretch: normal;
          line-height: 1.21;
          letter-spacing: normal;
          color: #1d1d1d;
          display: flex;
          align-items: center; }
          .flag-wrapper .box-wrapper.secondary .box-content ul li span .icon {
            margin-top: -3px; }
        .flag-wrapper .box-wrapper.secondary .box-content ul li:last-child {
          border-right: none; }
  .flag-wrapper .box-wrapper .secondary {
    margin-top: 10px; }
  .flag-wrapper .box-wrapper .box-header {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .flag-wrapper .box-wrapper .box-content {
    margin: 15px 0 0; }
    .flag-wrapper .box-wrapper .box-content .content-row {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .flag-wrapper .box-wrapper .box-content .content-row label,
      .flag-wrapper .box-wrapper .box-content .content-row span {
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d; }
      .flag-wrapper .box-wrapper .box-content .content-row label {
        font-family: "Helvetica Bold";
        margin-bottom: 0; }
      .flag-wrapper .box-wrapper .box-content .content-row span {
        font-family: Helvetica; }

.flag-wrapper .flag-box {
  white-space: nowrap; }
  .flag-wrapper .flag-box .greenLabel {
    color: #19ce35 !important; }
  .flag-wrapper .flag-box .redLabel {
    color: #ed5a5a !important; }
  .flag-wrapper .flag-box label {
    font-family: "Helvetica Bold";
    font-size: 14px;
    line-height: 0.9;
    letter-spacing: 0;
    margin-right: 5px;
    margin-bottom: 0; }

.flag-wrapper .cc-score-box {
  margin-top: 20px;
  margin-bottom: 5px; }
  .flag-wrapper .cc-score-box label {
    font-family: "Helvetica Bold";
    font-size: 14px;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1d1d1d; }
  .flag-wrapper .cc-score-box span {
    font-family: "Helvetica Bold";
    font-size: 26px;
    line-height: 1.21;
    letter-spacing: normal;
    color: #ed5a5a;
    word-wrap: break-word;
    max-width: 20px; }
  .flag-wrapper .cc-score-box ul {
    list-style: none;
    display: flex;
    margin: 30px 0 0; }
    .flag-wrapper .cc-score-box ul li {
      flex: 1;
      border: 0.5px solid #ddd;
      padding: 2px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0; }
      .flag-wrapper .cc-score-box ul li:first-child {
        flex: 2.5;
        color: #1d1d1d; }
      .flag-wrapper .cc-score-box ul li:nth-child(n+2) {
        background-color: #000;
        color: #fff; }
      .flag-wrapper .cc-score-box ul li span {
        position: absolute;
        top: -30px;
        font-family: "Helvetica Bold";
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: 0;
        color: #ed5a5a;
        white-space: nowrap; }
        .flag-wrapper .cc-score-box ul li span div {
          display: block !important;
          margin-top: -3px; }
      .flag-wrapper .cc-score-box ul li:nth-child(2), .flag-wrapper .cc-score-box ul li:nth-child(3) {
        background-color: #e8505b; }
      .flag-wrapper .cc-score-box ul li:nth-child(4) {
        background-color: #ed625a; }
      .flag-wrapper .cc-score-box ul li:nth-child(5) {
        background-color: #ed745a; }
      .flag-wrapper .cc-score-box ul li:nth-child(6) {
        background-color: #ed7c5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(7) {
        background-color: #ed845a; }
      .flag-wrapper .cc-score-box ul li:nth-child(8) {
        background-color: #ed8c5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(9) {
        background-color: #ed945a; }
      .flag-wrapper .cc-score-box ul li:nth-child(10) {
        background-color: #ed9c5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(11) {
        background-color: #edac5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(12) {
        background-color: #edbc5a; }
      .flag-wrapper .cc-score-box ul li:nth-child(13) {
        background-color: #edc45a; }
      .flag-wrapper .cc-score-box ul li:nth-child(14) {
        background-color: #edd45a; }
      .flag-wrapper .cc-score-box ul li:nth-child(15) {
        background-color: #c9de4d; }
      .flag-wrapper .cc-score-box ul li:nth-child(16) {
        background-color: #9bdd42; }
      .flag-wrapper .cc-score-box ul li:nth-child(17) {
        background-color: #7ace3d; }
      .flag-wrapper .cc-score-box ul li:nth-child(18) {
        background-color: #5dc145; }
      .flag-wrapper .cc-score-box ul li:nth-child(19) {
        background-color: #54b23e; }
      .flag-wrapper .cc-score-box ul li:nth-child(20) {
        background-color: #48a537; }
      .flag-wrapper .cc-score-box ul li:nth-child(21) {
        background-color: #3f9625; }
      .flag-wrapper .cc-score-box ul li:nth-child(22) {
        background-color: #267e2d; }
      .flag-wrapper .cc-score-box ul li:nth-child(23) {
        background-color: #0f6226; }

.flag-wrapper .timeline-box {
  position: relative;
  height: 40px;
  margin: 60px auto;
  width: 85%; }
  .flag-wrapper .timeline-box::before {
    content: '';
    position: absolute;
    height: 1px;
    border-top: 1px solid #bcbcbc;
    width: 100%;
    top: 50%; }
  .flag-wrapper .timeline-box .timelines {
    position: absolute;
    text-align: center; }
    .flag-wrapper .timeline-box .timelines span:first-of-type {
      padding: 10px 15px;
      border-radius: 50%;
      background-color: 00cdbe;
      background-color: #00cdbe;
      display: inline-block;
      position: relative;
      color: white; }
    .flag-wrapper .timeline-box .timelines span:last-of-type {
      display: block;
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      margin-top: 10px; }
    .flag-wrapper .timeline-box .timelines label {
      display: block;
      font-family: "Helvetica Bold";
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      margin-top: 5px; }
  .flag-wrapper .timeline-box .timeline-1 {
    left: -35px; }
  .flag-wrapper .timeline-box .timeline-2 {
    left: 60%; }
  .flag-wrapper .timeline-box .timeline-3 {
    right: -35px; }

.flag-wrapper .vertical-divider-left {
  position: relative; }
  .flag-wrapper .vertical-divider-left::before {
    position: absolute;
    left: 0;
    content: '';
    height: 100%;
    width: 1px;
    border: 1px solid #e0e0e0; }

.flag-wrapper .business-profile-flag-box .box-content .content-row label,
.flag-wrapper .business-profile-flag-box .box-content .content-row span {
  flex: 1; }

.flag-wrapper .business-risk-flag-box .box-content {
  padding: 15px; }

.flag-wrapper .credit-behaviour-flag-box .box-content {
  margin: 25px 0; }
  .flag-wrapper .credit-behaviour-flag-box .box-content .content-row {
    margin-bottom: 10px; }
    .flag-wrapper .credit-behaviour-flag-box .box-content .content-row label {
      flex: 1.5; }
    .flag-wrapper .credit-behaviour-flag-box .box-content .content-row span {
      flex: .5;
      text-align: right; }

.flag-wrapper .credit-behaviour-flag-box .top5-list-box {
  margin: 15px 10px; }
  .flag-wrapper .credit-behaviour-flag-box .top5-list-box label {
    font-family: 'Helvetica Bold'; }
  .flag-wrapper .credit-behaviour-flag-box .top5-list-box ul li {
    font-family: Helvetica;
    font-size: 12px;
    color: #1d1d1d; }

.flag-wrapper .credit-capacity-flag-box .box-content {
  margin: 50px 0; }
  .flag-wrapper .credit-capacity-flag-box .box-content .content-row {
    margin-bottom: 10px; }
    .flag-wrapper .credit-capacity-flag-box .box-content .content-row label {
      flex: 1.5; }
    .flag-wrapper .credit-capacity-flag-box .box-content .content-row span {
      flex: .5;
      text-align: right; }

.flag-wrapper .credit-capacity-flag-box .vertical-divider-left::before {
  top: 10%;
  height: 80%; }

.flag-wrapper .dpd-box > div {
  margin: 20px 0; }
  .flag-wrapper .dpd-box > div:last-child {
    margin-bottom: 0; }

.flag-wrapper .dpd-box label {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.33;
  color: #1d1d1d; }

.flag-wrapper .dpd-box span {
  font-family: Helvetica;
  font-size: 26px;
  line-height: 1.21;
  letter-spacing: normal;
  color: #19ce35;
  display: block; }

.flag-wrapper .dpd-box .dpd-list {
  list-style: none;
  display: flex;
  background: linear-gradient(to right, #f7f2f2, #f53c3c);
  padding: 3px 0;
  border-radius: 7px;
  margin: 12px 0; }
  .flag-wrapper .dpd-box .dpd-list li {
    flex: 1;
    text-align: center;
    color: #fff;
    font-size: 18px;
    position: relative; }
    .flag-wrapper .dpd-box .dpd-list li:first-child::before {
      position: absolute;
      content: "DPD 0";
      left: -10px;
      bottom: -25px;
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0;
      color: #1d1d1d; }
    .flag-wrapper .dpd-box .dpd-list li:first-child::after {
      position: absolute;
      content: "DPD 30";
      right: -10px;
      bottom: -25px;
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0;
      color: #1d1d1d; }
    .flag-wrapper .dpd-box .dpd-list li:nth-child(2)::after {
      position: absolute;
      content: "DPD 60";
      right: -10px;
      bottom: -25px;
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0;
      color: #1d1d1d; }
    .flag-wrapper .dpd-box .dpd-list li:last-child::after {
      position: absolute;
      content: "DPD 90";
      right: -10px;
      bottom: -25px;
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0;
      color: #1d1d1d; }

.flag-wrapper .dpd-box .content-row {
  margin-bottom: 10px; }

.flag-wrapper .bubble-chart-box {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin: 40px 0; }
  .flag-wrapper .bubble-chart-box .list-item {
    text-align: center; }
    .flag-wrapper .bubble-chart-box .list-item span,
    .flag-wrapper .bubble-chart-box .list-item label {
      display: block;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d;
      text-align: center; }
    .flag-wrapper .bubble-chart-box .list-item span {
      font-family: "Helvetica"; }
    .flag-wrapper .bubble-chart-box .list-item label {
      font-family: "Helvetica Bold";
      margin-bottom: 0;
      margin-top: 5px; }
    .flag-wrapper .bubble-chart-box .list-item .bubble {
      color: #fafafa;
      display: inline-block;
      padding: 15px 0;
      border-radius: 50%; }
    .flag-wrapper .bubble-chart-box .list-item .bubble-transparent {
      font-size: 26px;
      font-family: "Helvetica Bold";
      line-height: 0.66;
      letter-spacing: -0.1px;
      color: #ed5a5a;
      margin-bottom: 5px; }

.final-decision-wrapper {
  color: #1d1d1d; }
  .final-decision-wrapper.content-container {
    margin: 50px auto 20px;
    width: 85%; }
  .final-decision-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: #ffffff;
    margin-bottom: 20px;
    padding: 25px 20px; }
    .final-decision-wrapper .box-wrapper .fprBox {
      margin-top: 23px; }
      .final-decision-wrapper .box-wrapper .fprBox .content {
        margin: 20px 0; }
        .final-decision-wrapper .box-wrapper .fprBox .content:first-child {
          margin-top: 0; }
        .final-decision-wrapper .box-wrapper .fprBox .content:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .box-wrapper .fprBox .content label,
        .final-decision-wrapper .box-wrapper .fprBox .content span {
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          display: block; }
        .final-decision-wrapper .box-wrapper .fprBox .content label {
          font-family: "Helvetica Bold"; }
        .final-decision-wrapper .box-wrapper .fprBox .content span {
          font-family: Helvetica; }
    .final-decision-wrapper .box-wrapper.secondary {
      padding: 0; }
      .final-decision-wrapper .box-wrapper.secondary .btns-box {
        background: #4687F4;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff; }
      .final-decision-wrapper .box-wrapper.secondary .table-box {
        overflow-x: scroll;
        margin-top: 20px; }
        .final-decision-wrapper .box-wrapper.secondary .table-box .btns-box {
          text-align: center;
          margin-top: 15px; }
          .final-decision-wrapper .box-wrapper.secondary .table-box .btns-box button {
            font-family: Helvetica;
            font-size: 14px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #ffffff;
            border: none;
            border-radius: 25px;
            padding: 10px;
            min-width: 100px;
            background: #4687F4; }
        .final-decision-wrapper .box-wrapper.secondary .table-box .table {
          width: 100%; }
        .final-decision-wrapper .box-wrapper.secondary .table-box::-webkit-scrollbar {
          height: 8px; }
        .final-decision-wrapper .box-wrapper.secondary .table-box::-webkit-scrollbar-button {
          display: none; }
        .final-decision-wrapper .box-wrapper.secondary .table-box::-webkit-scrollbar-track {
          background: transparent; }
        .final-decision-wrapper .box-wrapper.secondary .table-box::-webkit-scrollbar-thumb {
          border-radius: 10px; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table thead::-webkit-scrollbar {
        height: 300px; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table thead .tr {
        background-color: #e5e5e5; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table thead .tr .th {
          border: 1px solid #fff;
          font-family: "Helvetica Bold";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.25;
          letter-spacing: 0;
          color: #1d1d1d;
          text-align: center;
          padding: 10px; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td {
        font-family: "Helvetica";
        line-height: 1.25;
        letter-spacing: 0;
        color: #1d1d1d;
        text-align: center;
        padding: 10px;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td:last-child {
          border-right: none; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td .residenceStatus {
          font-weight: 800 !important;
          color: #980e18; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td .btn {
          width: 150px;
          font-family: Helvetica;
          font-size: 14px;
          line-height: 1.19;
          color: #ffffff;
          border: none;
          padding: 9px;
          /* background-color: #29a9e0; */
          background: #29a9e0;
          border-radius: 5px; }
        .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody .tr .td .select-box {
          width: 100px; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody tr:nth-child(odd) {
        background: #f7f9fc; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table .tbody tr:nth-child(even) {
        background: #ffffff; }
      .final-decision-wrapper .box-wrapper.secondary .primary-table.bank-table tbody tr:last-child {
        border-top: 1px solid #bcbcbc; }
      .final-decision-wrapper .box-wrapper.secondary .box-header {
        padding: 15px;
        border-radius: 15px 15px 0 0;
        background: #eeeeee; }
        .final-decision-wrapper .box-wrapper.secondary .box-header ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;
          width: 100%; }
          .final-decision-wrapper .box-wrapper.secondary .box-header ul li {
            margin: 0 10px; }
            .final-decision-wrapper .box-wrapper.secondary .box-header ul li:first-child {
              margin-left: 0; }
            .final-decision-wrapper .box-wrapper.secondary .box-header ul li:last-child {
              margin-right: 0; }
            .final-decision-wrapper .box-wrapper.secondary .box-header ul li h4 {
              margin-top: 0; }
        .final-decision-wrapper .box-wrapper.secondary .box-header h4 {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 1.17;
          color: #4687F4; }
        .final-decision-wrapper .box-wrapper.secondary .box-header small {
          font-family: Helvetica;
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: -0.1px;
          color: #1d1d1d;
          display: block; }
      .final-decision-wrapper .box-wrapper.secondary .box-content ul {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0; }
        .final-decision-wrapper .box-wrapper.secondary .box-content ul li {
          padding: 15px;
          border-right: 1px solid #eeeeee;
          flex: 1; }
          .final-decision-wrapper .box-wrapper.secondary .box-content ul li label {
            font-family: Helvetica;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #4687F4;
            margin-bottom: 10px; }
          .final-decision-wrapper .box-wrapper.secondary .box-content ul li span {
            font-family: Helvetica;
            font-size: 14px;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #4687F4;
            display: flex;
            align-items: center; }
            .final-decision-wrapper .box-wrapper.secondary .box-content ul li span .icon {
              margin-top: -3px; }
          .final-decision-wrapper .box-wrapper.secondary .box-content ul li:last-child {
            border-right: none; }
    .final-decision-wrapper .box-wrapper .box-header {
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .final-decision-wrapper .box-wrapper .box-header h4 {
        font-family: "Helvetica Bold";
        font-size: 18px;
        line-height: 0.92;
        letter-spacing: normal;
        color: #000000;
        margin-top: 0; }
      .final-decision-wrapper .box-wrapper .box-header .rba {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        color: #ffffff;
        border: none;
        border-radius: 15px;
        background-color: #4687F4;
        padding: 5px 10px;
        margin-bottom: 5px;
        width: 100px;
        height: 30px; }
      .final-decision-wrapper .box-wrapper .box-header .decisions {
        background-color: #fafafa;
        border: none; }
      .final-decision-wrapper .box-wrapper .box-header .buttonFPR {
        background-color: #fafafa;
        border: none; }
    .final-decision-wrapper .box-wrapper .box-footer {
      background-color: #fff;
      border-radius: 0 0 15px 15px; }
      .final-decision-wrapper .box-wrapper .box-footer .boldText {
        font-weight: 600 !important;
        color: #1d1d1d;
        font-size: 14px;
        display: inline-block;
        margin-right: 1em; }
      .final-decision-wrapper .box-wrapper .box-footer ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0; }
        .final-decision-wrapper .box-wrapper .box-footer ul .btnGreen {
          font-family: Helvetica;
          font-size: 14px;
          line-height: 1.19;
          color: #ffffff;
          border: none;
          border-radius: 100px;
          background-color: #4687F4;
          padding: 5px 10px;
          margin-bottom: 5px;
          height: 38px;
          width: 120px; }
        .final-decision-wrapper .box-wrapper .box-footer ul li {
          padding: 10px 15px;
          border-left: 1px solid #eeeeee;
          display: flex;
          flex-direction: column; }
          .final-decision-wrapper .box-wrapper .box-footer ul li label,
          .final-decision-wrapper .box-wrapper .box-footer ul li span {
            font-family: Helvetica;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #1d1d1d; }
          .final-decision-wrapper .box-wrapper .box-footer ul li label {
            font-size: 14px;
            margin-bottom: 10px; }
          .final-decision-wrapper .box-wrapper .box-footer ul li span {
            font-size: 14px;
            display: flex;
            align-items: center; }
            .final-decision-wrapper .box-wrapper .box-footer ul li span .select-box {
              display: inline-block;
              border: 1px solid #bcbcbc;
              border-radius: 7px;
              width: 134px;
              font-family: "Helvetica";
              font-size: 14px;
              line-height: 1.14;
              letter-spacing: normal;
              border: 1px solid #bcbcbc;
              box-sizing: border-box;
              border-radius: 5px;
              height: 30px; }
        .final-decision-wrapper .box-wrapper .box-footer ul span {
          font-family: Helvetica;
          font-size: 24px;
          font-stretch: normal;
          line-height: 1.21;
          letter-spacing: normal;
          color: #ffffff;
          display: flex;
          align-items: center; }
          .final-decision-wrapper .box-wrapper .box-footer ul span .icon {
            margin-top: -3px; }
        .final-decision-wrapper .box-wrapper .box-footer ul:first-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .final-decision-wrapper .box-wrapper .box-footer ul:first-child li:first-child {
            border-bottom-left-radius: 15px; }
          .final-decision-wrapper .box-wrapper .box-footer ul:first-child li:last-child {
            border-bottom-right-radius: 15px; }
        .final-decision-wrapper .box-wrapper .box-footer ul:last-child {
          border-top: 0.5px solid rgba(255, 255, 255, 0.5);
          border-radius: 0 0 15px 15px; }
          .final-decision-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
            border-bottom-left-radius: 15px;
            border-left: none; }
          .final-decision-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
            border-bottom-right-radius: 15px; }
    .final-decision-wrapper .box-wrapper .input-field-box ::-webkit-input-placeholder {
      color: #bcbcbc; }
    .final-decision-wrapper .box-wrapper .input-field-box .dropdown .select-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      line-height: 1.14;
      letter-spacing: normal;
      padding: 15px 12px;
      text-overflow: ellipsis;
      overflow: hidden; }
    .final-decision-wrapper .box-wrapper .input-field-box .input-label {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      color: #1d1d1d;
      margin-top: -35px;
      font-weight: bold !important; }
      .final-decision-wrapper .box-wrapper .input-field-box .input-label .span {
        font-family: Arial;
        font-size: 14px;
        line-height: 24px;
        color: #1d1d1d; }
    .final-decision-wrapper .box-wrapper .input-field-box .input-heading {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 24px;
      color: #1d1d1d; }
    .final-decision-wrapper .box-wrapper .taller {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      margin-left: 10px;
      color: #1d1d1d;
      height: 200px; }
    .final-decision-wrapper .box-wrapper .input-box1 .dropdown .select-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #bcbcbc;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      line-height: 1.14;
      letter-spacing: normal;
      padding: 15px 12px;
      text-overflow: ellipsis;
      overflow: hidden; }
    .final-decision-wrapper .box-wrapper .input-box1 .input-label {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      margin-left: 10px;
      color: #1d1d1d;
      height: 300px;
      margin-top: -35px; }
      .final-decision-wrapper .box-wrapper .input-box1 .input-label .span {
        font-family: Arial;
        font-size: 14px;
        line-height: 24px;
        color: #1d1d1d; }
  .final-decision-wrapper .cc-score-box {
    margin-top: 20px;
    margin-bottom: 5px; }
    .final-decision-wrapper .cc-score-box .vl {
      border-left: 1px solid;
      height: 93px;
      position: absolute;
      left: 50%;
      margin-left: 33px;
      top: 0;
      color: #bcbcbc;
      margin-top: -12px; }
    .final-decision-wrapper .cc-score-box .guageChart {
      margin-left: -32px;
      padding: 16px; }
    .final-decision-wrapper .cc-score-box .chart {
      margin-left: 7px; }
    .final-decision-wrapper .cc-score-box label {
      font-family: "Helvetica Bold";
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: normal;
      color: #1d1d1d; }
    .final-decision-wrapper .cc-score-box span {
      font-family: "Helvetica Bold";
      font-size: 26px;
      line-height: 1.21;
      letter-spacing: normal;
      color: #F0CE70; }
    .final-decision-wrapper .cc-score-box ul {
      list-style: none;
      display: flex;
      margin: 30px 0 0; }
      .final-decision-wrapper .cc-score-box ul li {
        flex: 1;
        border: 0.5px solid #ddd;
        padding: 2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: 0; }
        .final-decision-wrapper .cc-score-box ul li:first-child {
          flex: 2.5;
          color: #1d1d1d; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(n + 2) {
          background-color: #000;
          color: #fff; }
        .final-decision-wrapper .cc-score-box ul li span {
          position: absolute;
          top: -30px;
          font-family: "Helvetica Bold";
          font-size: 14px;
          line-height: 1.14;
          letter-spacing: 0;
          color: #ed5a5a;
          white-space: nowrap; }
          .final-decision-wrapper .cc-score-box ul li span div {
            display: block !important;
            margin-top: -3px; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(2), .final-decision-wrapper .cc-score-box ul li:nth-child(3) {
          background-color: #F0CE70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(4) {
          background-color: #F0CE70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(5) {
          background-color: #F0CE70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(6) {
          background-color: #F0CE70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(7) {
          background-color: #F0CE70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(8) {
          background-color: #F0CE70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(9) {
          background-color: #F0CE70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(10) {
          background-color: #F0CE70; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(11) {
          background-color: #8BD591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(12) {
          background-color: #8BD591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(13) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(14) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(15) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(16) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(17) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(18) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(19) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(20) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(21) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(22) {
          background-color: #8bd591; }
        .final-decision-wrapper .cc-score-box ul li:nth-child(23) {
          background-color: #8bd591; }
  .final-decision-wrapper .vertical-divider-left {
    position: relative; }
    .final-decision-wrapper .vertical-divider-left::before {
      position: absolute;
      left: 0;
      content: "";
      height: 100%;
      width: 1px;
      border: 1px solid #e0e0e0; }
  .final-decision-wrapper .vertical-divider-right {
    position: relative; }
    .final-decision-wrapper .vertical-divider-right::before {
      position: absolute;
      right: 0;
      content: "";
      width: 1px;
      border: 1px solid #e0e0e0; }
  .final-decision-wrapper .highcharts-data-label,
  .final-decision-wrapper .highcharts-axis-labels {
    display: none; }
  .final-decision-wrapper .table-box {
    overflow-x: scroll;
    margin-top: 20px; }
    .final-decision-wrapper .table-box .btns-box {
      text-align: center;
      margin-top: 15px; }
      .final-decision-wrapper .table-box .btns-box button {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 10px;
        min-width: 100px;
        background: #4687F4; }
    .final-decision-wrapper .table-box table {
      width: 100%; }
    .final-decision-wrapper .table-box::-webkit-scrollbar {
      height: 8px; }
    .final-decision-wrapper .table-box::-webkit-scrollbar-button {
      display: none; }
    .final-decision-wrapper .table-box::-webkit-scrollbar-track {
      background: transparent; }
    .final-decision-wrapper .table-box::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #4687F4; }
  .final-decision-wrapper .primary-table thead::-webkit-scrollbar {
    height: 300px; }
  .final-decision-wrapper .primary-table thead tr {
    background-color: #e5e5e5; }
    .final-decision-wrapper .primary-table thead tr th {
      border: 1px solid #fff;
      font-family: "Helvetica Bold";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: 0;
      color: #1d1d1d;
      text-align: center;
      padding: 10px; }
  .final-decision-wrapper .primary-table tbody tr td {
    font-family: "Helvetica";
    line-height: 1.25;
    letter-spacing: 0;
    color: #1d1d1d;
    text-align: center;
    padding: 10px;
    border-right: 2px solid #fff; }
    .final-decision-wrapper .primary-table tbody tr td:last-child {
      border-right: none; }
    .final-decision-wrapper .primary-table tbody tr td .residenceStatus {
      font-weight: 800 !important;
      color: #980e18; }
    .final-decision-wrapper .primary-table tbody tr td .btn {
      width: 150px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      color: #ffffff;
      border: none;
      padding: 9px;
      /* background-color: #29a9e0; */
      background: #29a9e0;
      border-radius: 5px; }
    .final-decision-wrapper .primary-table tbody tr td .select-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      line-height: 1.14;
      letter-spacing: normal;
      padding: 10px 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%; }
  .final-decision-wrapper .primary-table tbody tr:nth-child(odd) {
    background: #f7f9fc; }
  .final-decision-wrapper .primary-table tbody tr:nth-child(even) {
    background: #ffffff; }
  .final-decision-wrapper .primary-table.bank-table tbody tr:last-child {
    border-top: 1px solid #bcbcbc; }
  .final-decision-wrapper .ReactTable {
    margin: 20px auto; }
    .final-decision-wrapper .ReactTable .rt-thead .rt-th {
      position: relative;
      background-color: #4687F4;
      font-family: "Helvetica Bold";
      font-size: 14px;
      line-height: 1.25;
      letter-spacing: 0;
      color: #ffffff;
      padding: 12px 0;
      border-right-color: #fff; }
      .final-decision-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
        content: "\f0d7";
        position: absolute;
        font-family: FontAwesome;
        right: 9px;
        top: 10px;
        font-size: 16px; }
      .final-decision-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
        content: "\f0d8";
        position: absolute;
        font-family: FontAwesome;
        right: 9px;
        top: 10px;
        font-size: 16px; }
    .final-decision-wrapper .ReactTable .rt-tbody .rt-tr {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.25;
      letter-spacing: 0;
      text-align: center;
      color: #1d1d1d;
      padding: 2px 0; }
      .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
        border-right-color: transparent;
        text-align: center;
        padding: 15px 0px !important;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td:last-child {
          border-right: none; }
        .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .boldText {
          font-weight: 600 !important;
          color: #1d1d1d;
          font-size: 16px;
          margin-right: 1em; }
        .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
          color: #fff;
          border-radius: 25px;
          width: 23px;
          height: 24px;
          display: inline-block;
          text-align: center;
          padding-top: 2px; }
          .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
            background-color: #78e35f; }
          .final-decision-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
            background-color: #ff0000; }
      .final-decision-wrapper .ReactTable .rt-tbody .rt-tr.-odd {
        background-color: #F7F9FC; }
      .final-decision-wrapper .ReactTable .rt-tbody .rt-tr.-even {
        background-color: #ffffff; }
  .final-decision-wrapper .table-box {
    margin-bottom: 20px;
    overflow: hidden; }
    .final-decision-wrapper .table-box table {
      width: 100%; }
      .final-decision-wrapper .table-box table thead tr {
        background-color: #eeeeee; }
        .final-decision-wrapper .table-box table thead tr th {
          position: relative;
          background-color: #4687F4;
          font-family: "Helvetica Bold";
          letter-spacing: 0;
          color: #ffffff;
          padding: 12px 0;
          border-right-color: #fff; }
      .final-decision-wrapper .table-box table tbody tr td {
        flex: 1 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 7px 5px;
        overflow: hidden;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        -webkit-transition-property: width, min-width, padding, opacity;
        transition-property: width, min-width, padding, opacity;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .table-box table tbody tr td:last-child {
          border-right: none; }
    .final-decision-wrapper .table-box .table-footer {
      padding: 20px;
      border-top: 1px solid #eee; }
      .final-decision-wrapper .table-box .table-footer ul li,
      .final-decision-wrapper .table-box .table-footer label {
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d; }
      .final-decision-wrapper .table-box .table-footer label {
        font-family: "Helvetica Bold";
        margin-bottom: 10px; }
      .final-decision-wrapper .table-box .table-footer ul li {
        font-family: Helvetica; }
  .final-decision-wrapper .table-boxs {
    margin-bottom: 20px;
    overflow: hidden; }
    .final-decision-wrapper .table-boxs .btns-box {
      text-align: center;
      margin-top: 15px; }
      .final-decision-wrapper .table-boxs .btns-box button {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 10px;
        min-width: 100px;
        background: #4687F4; }
    .final-decision-wrapper .table-boxs table {
      width: 100%; }
      .final-decision-wrapper .table-boxs table thead tr {
        background: #e3f7ff; }
        .final-decision-wrapper .table-boxs table thead tr th {
          position: relative;
          background-color: #4687F4;
          font-family: "Helvetica Bold";
          font-size: 14px;
          line-height: 1.25;
          letter-spacing: 0;
          color: #ffffff;
          padding: 12px 0;
          border-right-color: #fff; }
      .final-decision-wrapper .table-boxs table tbody tr td {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.25;
        letter-spacing: 0;
        text-align: center;
        color: #1d1d1d;
        padding: 7px 0;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .table-boxs table tbody tr td:last-child {
          border-right: none; }
        .final-decision-wrapper .table-boxs table tbody tr td button {
          background: #4687F4;
          border-radius: 50px;
          border-style: solid;
          width: 136px; }
        .final-decision-wrapper .table-boxs table tbody tr td:last-child {
          border-right: none; }
    .final-decision-wrapper .table-boxs .table-footer {
      padding: 20px;
      border-top: 1px solid #eee;
      background: #e3f7ff; }
      .final-decision-wrapper .table-boxs .table-footer ul li,
      .final-decision-wrapper .table-boxs .table-footer label {
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d; }
      .final-decision-wrapper .table-boxs .table-footer label {
        font-family: "Helvetica Bold";
        margin-bottom: 10px; }
      .final-decision-wrapper .table-boxs .table-footer ul li {
        font-family: Helvetica; }
  .final-decision-wrapper .highlights-box-strengths {
    padding: 8px;
    margin-bottom: 30px; }
    .final-decision-wrapper .highlights-box-strengths h4 {
      margin-top: 0;
      font-family: "Helvetica Bold";
      font-size: 16px;
      line-height: 1.17;
      margin-top: 0;
      color: #19ce35;
      text-align: center; }
    .final-decision-wrapper .highlights-box-strengths p {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d; }
    .final-decision-wrapper .highlights-box-strengths.strengths h4 {
      margin-top: 0;
      color: #19ce35; }
    .final-decision-wrapper .highlights-box-strengths.flags h4 {
      margin-top: 0;
      color: #ed5a5a; }
  .final-decision-wrapper .highlights-box-flags {
    padding: 8px;
    margin-bottom: 30px;
    margin-left: 22px; }
    .final-decision-wrapper .highlights-box-flags .box-content {
      margin-top: 5px; }
    .final-decision-wrapper .highlights-box-flags li {
      margin-top: 17px; }
    .final-decision-wrapper .highlights-box-flags h4 {
      margin-top: 0;
      font-family: "Helvetica Bold";
      font-size: 16px;
      line-height: 1.17;
      margin-top: 0;
      color: #ed5a5a;
      text-align: center; }
    .final-decision-wrapper .highlights-box-flags p {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d; }
    .final-decision-wrapper .highlights-box-flags.strengths h4 {
      margin-top: 0;
      color: #19ce35; }
    .final-decision-wrapper .highlights-box-flags.flags h4 {
      margin-top: 0;
      color: #ff0000; }
  .final-decision-wrapper .filters-box {
    margin-top: 48px; }
    .final-decision-wrapper .filters-box .dropdown {
      margin-top: 0; }
    .final-decision-wrapper .filters-box .btns-box {
      display: flex;
      justify-content: center;
      align-content: center;
      margin-top: 25px; }
      .final-decision-wrapper .filters-box .btns-box .blueButton {
        font-family: Helvetica;
        font-size: 14px;
        color: #ffffff;
        border: none;
        border-radius: 150px;
        background-color: #4687F4;
        padding: 10px 14px;
        min-width: 80px;
        letter-spacing: 2px; }
      .final-decision-wrapper .filters-box .btns-box svg {
        margin-top: -5px; }
  .final-decision-wrapper .referenceMargin {
    margin-bottom: 5px;
    margin-top: 12px; }
    .final-decision-wrapper .referenceMargin .references-box {
      margin: 5px 5px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 25px;
      /* border: 1px solid; */
      overflow: hidden;
      /* box-sizing: border-box; */
      position: relative;
      float: left;
      width: 330px;
      height: 320px;
      background-color: white; }
      .final-decision-wrapper .referenceMargin .references-box .content {
        margin: 20px 0; }
        .final-decision-wrapper .referenceMargin .references-box .content:first-child {
          margin-top: 0; }
        .final-decision-wrapper .referenceMargin .references-box .content:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .referenceMargin .references-box .content label,
        .final-decision-wrapper .referenceMargin .references-box .content span {
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          display: block; }
        .final-decision-wrapper .referenceMargin .references-box .content label {
          font-family: "Helvetica Bold"; }
        .final-decision-wrapper .referenceMargin .references-box .content span {
          font-family: Helvetica; }
      .final-decision-wrapper .referenceMargin .references-box .verified {
        background: #4687F4;
        border-radius: 50px;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 21px;
        border: solid;
        width: 100px;
        /* identical to box height */
        color: #ffffff;
        height: 30px; }
      .final-decision-wrapper .referenceMargin .references-box .verifying {
        border-radius: 50px;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 21px;
        border: solid;
        width: 100px;
        background-color: #ffffff;
        /* identical to box height */
        text-align: center;
        color: #1d1d1d;
        margin-left: -3px;
        border: 1px solid #4687F4; }
      .final-decision-wrapper .referenceMargin .references-box .edit {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 21px;
        background-color: #ffffff;
        /* identical to box height */
        text-align: center;
        color: #1d1d1d;
        margin-left: 125px;
        border: none; }
      .final-decision-wrapper .referenceMargin .references-box .reference {
        background: #ffffff;
        padding: 5px; }
        .final-decision-wrapper .referenceMargin .references-box .reference h4 {
          font-family: "Helvetica Bold";
          font-size: 12px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #4687F4;
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 5px; }
        .final-decision-wrapper .referenceMargin .references-box .reference label,
        .final-decision-wrapper .referenceMargin .references-box .reference span {
          word-wrap: break-word;
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          display: block; }
          .final-decision-wrapper .referenceMargin .references-box .reference label address,
          .final-decision-wrapper .referenceMargin .references-box .reference span address {
            line-height: inherit; }
        .final-decision-wrapper .referenceMargin .references-box .reference label {
          font-family: "Helvetica Bold"; }
        .final-decision-wrapper .referenceMargin .references-box .reference span {
          font-family: Helvetica; }
        .final-decision-wrapper .referenceMargin .references-box .reference:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .referenceMargin .references-box .reference ul {
          margin-bottom: 0; }
          .final-decision-wrapper .referenceMargin .references-box .reference ul li label {
            font-family: Helvetica;
            font-size: 10px;
            font-weight: bold;
            margin-right: 10px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            word-wrap: break-word; }
      .final-decision-wrapper .referenceMargin .references-box .reference1 {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background: #ffffff;
        padding: 5px; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 .verifying {
          border: 1px solid #4687F4;
          border-radius: 50px;
          font-family: Helvetica;
          font-size: 14px;
          line-height: 21px;
          border: solid;
          width: 100px;
          background-color: #ffffff;
          /* identical to box height */
          text-align: center;
          color: #1d1d1d;
          margin-left: -3px;
          border: 1px solid #4687F4; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 h5 {
          font-family: Helvetica;
          font-size: 18px;
          font-weight: bold;
          margin-right: 10px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          word-wrap: break-word; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 .buttonRow {
          margin-top: 10px; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 h4 {
          font-family: "Helvetica Bold";
          font-size: 12px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #4687F4;
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 5px; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 span {
          font-size: 10px;
          word-wrap: break-word; }
          .final-decision-wrapper .referenceMargin .references-box .reference1 span address {
            line-height: inherit; }
        .final-decision-wrapper .referenceMargin .references-box .reference1:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .referenceMargin .references-box .reference1 ul {
          margin-bottom: 0; }
          .final-decision-wrapper .referenceMargin .references-box .reference1 ul li label {
            font-family: Helvetica;
            font-size: 10px;
            font-weight: bold;
            margin-right: 10px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            word-wrap: break-word; }
  .final-decision-wrapper .other-information-box {
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 15px; }
    .final-decision-wrapper .other-information-box h4 {
      font-family: "Helvetica Bold";
      font-size: 16px;
      line-height: 1.17;
      color: #1d1d1d;
      margin-top: 0; }
    .final-decision-wrapper .other-information-box ul {
      margin-bottom: 0; }
      .final-decision-wrapper .other-information-box ul li label,
      .final-decision-wrapper .other-information-box ul li p {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #1d1d1d; }
  .final-decision-wrapper .file-dropzone-box h4 {
    font-family: "Helvetica Bold";
    font-size: 16px;
    line-height: 1.17;
    color: #1d1d1d;
    margin-top: 0; }
  .final-decision-wrapper .file-dropzone-box .dropdown {
    margin-top: 0; }
  .final-decision-wrapper .file-dropzone-box .dropzone {
    background-color: #efefef;
    text-align: center;
    margin-top: 15px;
    padding: 15px 0;
    cursor: pointer; }
    .final-decision-wrapper .file-dropzone-box .dropzone svg {
      color: #bcbcbc; }
    .final-decision-wrapper .file-dropzone-box .dropzone p {
      font-family: Helvetica;
      font-size: 12px;
      line-height: 1.39;
      color: #1d1d1d; }
  .final-decision-wrapper .file-dropzone-box .btns-box {
    text-align: center;
    margin-top: 15px; }
    .final-decision-wrapper .file-dropzone-box .btns-box button {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #ffffff;
      border: none;
      border-radius: 25px;
      padding: 10px;
      min-width: 100px;
      background: #4687F4; }
  .final-decision-wrapper .cam-box {
    text-align: center; }
    .final-decision-wrapper .cam-box span {
      color: #acacac;
      display: inline-block;
      margin-bottom: 15px; }
    .final-decision-wrapper .cam-box p {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #1d1d1d; }
    .final-decision-wrapper .cam-box .btns-box {
      margin-top: 15px; }
      .final-decision-wrapper .cam-box .btns-box button {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 10px 15px;
        background: #4687F4; }
  .final-decision-wrapper .react-switch-bg {
    border: 3px solid #4687F4;
    width: 90px !important;
    margin-bottom: 5px !important; }
    .final-decision-wrapper .react-switch-bg span {
      font-family: Arial;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: #4687F4; }
    .final-decision-wrapper .react-switch-bg div:first-child {
      top: 2px !important;
      left: 9px !important; }
    .final-decision-wrapper .react-switch-bg div:last-child {
      top: 2px !important;
      right: 9px !important; }
  .final-decision-wrapper .react-switch-handle {
    width: 45px !important;
    border-radius: 25px !important; }
  .final-decision-wrapper .switch-open .react-switch-handle {
    transform: translateX(45px) !important; }
  .final-decision-wrapper .conditions-list h3 {
    font-family: "Helvetica Bold";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: 0;
    color: #1d1d1d; }
  .final-decision-wrapper .conditions-list ul li {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.32;
    letter-spacing: 0;
    color: #1d1d1d;
    padding: 12px;
    border-radius: 7px;
    border: 1px solid #bcbcbc;
    margin-bottom: 10px; }
    .final-decision-wrapper .conditions-list ul li:last-child {
      margin-bottom: 0; }
  .final-decision-wrapper .decisions-box label {
    font-weight: bold !important; }
  .final-decision-wrapper .decisions-box .decisionContainer {
    margin-top: 21px;
    padding-left: 18px; }
    .final-decision-wrapper .decisions-box .decisionContainer .finalDecisionRow {
      margin-top: 10px;
      margin-bottom: 20px; }
    .final-decision-wrapper .decisions-box .decisionContainer .content-box {
      margin-top: 20px; }
      .final-decision-wrapper .decisions-box .decisionContainer .content-box .content {
        margin: 20px 0; }
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content:first-child {
          margin-top: 0; }
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content:last-child {
          margin-bottom: 0; }
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content label,
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content span {
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          display: block; }
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content label {
          font-family: "Helvetica Bold"; }
        .final-decision-wrapper .decisions-box .decisionContainer .content-box .content span {
          font-family: Helvetica; }
  .final-decision-wrapper .decisions-box .dropdown {
    margin-top: 0; }
  .final-decision-wrapper .decisions-box .radios {
    display: flex;
    align-items: center;
    margin: 10px 0; }
    .final-decision-wrapper .decisions-box .radios label {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.14;
      margin-bottom: 0;
      color: #1d1d1d;
      margin-left: 5px;
      cursor: pointer; }
    .final-decision-wrapper .decisions-box .radios h3 {
      font-family: Helvetica;
      font-size: 21px;
      line-height: 24px;
      color: #1d1d1d; }
  .final-decision-wrapper .decisions-box .input-field-box {
    margin: 15px 0; }
  .final-decision-wrapper .decisions-box .btns-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .final-decision-wrapper .decisions-box .btns-box button {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #ffffff;
      border: none;
      border-radius: 25px;
      width: 100px;
      padding: 10px;
      background: #29a9e0;
      margin-top: 17px;
      margin-right: 15px; }
    .final-decision-wrapper .decisions-box .btns-box .submit-btn {
      background-color: #29a9e0; }
    .final-decision-wrapper .decisions-box .btns-box .upload-btn {
      margin-top: 10px;
      background-color: #4687F4; }
  .final-decision-wrapper .tab-content {
    border-right: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    border-left: 1px solid #bcbcbc;
    border-top: 2px solid #e5e5e5;
    border-radius: 25px;
    margin-top: -2px;
    margin-bottom: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 20px; }
    .final-decision-wrapper .tab-content table {
      width: 100%; }
      .final-decision-wrapper .tab-content table thead tr {
        background-color: #4687F4;
        color: white;
        border-radius: 0px; }
        .final-decision-wrapper .tab-content table thead tr th {
          border-right: 1px solid white;
          padding: 1em;
          text-align: center; }
      .final-decision-wrapper .tab-content table tbody tr:nth-child(2n + 1) > td {
        background-color: #f0f0f0; }
      .final-decision-wrapper .tab-content table tbody tr td {
        padding: 0.5em;
        text-align: center;
        border-right: 2px solid #fff; }
        .final-decision-wrapper .tab-content table tbody tr td:last-child {
          border-right: none; }
    .final-decision-wrapper .tab-content .doc-wrapper {
      font-size: 11px;
      display: flex;
      border: 1px solid #bcbcbc;
      border-radius: 20px;
      max-width: 320px;
      margin-top: 1%;
      justify-content: flex-end; }
      .final-decision-wrapper .tab-content .doc-wrapper .pdf-icon {
        margin: 3px 3px 0px 2px;
        color: #ff0000;
        padding: 0px; }
      .final-decision-wrapper .tab-content .doc-wrapper .archive-file {
        padding-right: 0px;
        border-radius: 100px;
        height: 25px;
        width: 24px; }
      .final-decision-wrapper .tab-content .doc-wrapper .archive-file:hover {
        cursor: pointer; }
    .final-decision-wrapper .tab-content .actions-box {
      padding: 5px 10px;
      display: flex;
      justify-content: flex-end; }
      .final-decision-wrapper .tab-content .actions-box span {
        padding: 0 10px; }
      .final-decision-wrapper .tab-content .actions-box .icon-download {
        color: #2d9086;
        cursor: pointer; }
      .final-decision-wrapper .tab-content .actions-box .icon-upload {
        color: #4687F4;
        cursor: pointer; }
  .final-decision-wrapper .nav-tabs {
    margin: 0 30px;
    border-color: #bcbcbc; }
    .final-decision-wrapper .nav-tabs > li {
      margin: 0 4px -1px;
      background-color: #e5e5e5;
      border-radius: 5px 5px 0 0;
      position: relative; }
      .final-decision-wrapper .nav-tabs > li a {
        color: #1d1d1d;
        border-radius: 5px 5px 0 0;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        padding: 12px; }
        .final-decision-wrapper .nav-tabs > li a > div {
          padding: 10px 20px; }
        .final-decision-wrapper .nav-tabs > li a label {
          font-family: "Helvetica Bold";
          margin-bottom: 0; }
      .final-decision-wrapper .nav-tabs > li:first-child a > div {
        padding: 18px 20px; }
      .final-decision-wrapper .nav-tabs > li.active::after {
        content: "";
        width: 75%;
        height: 2px;
        background-color: #4687F4;
        position: absolute;
        bottom: 0;
        left: 13%; }
  .final-decision-wrapper .refresh-btn {
    background-color: #4687F4;
    float: right;
    border: none;
    border-radius: 20px;
    padding: 0.5em 2em;
    color: #fff;
    margin-bottom: 1em; }
  .final-decision-wrapper .docsNameHeader {
    color: #000000;
    font-size: 1.4em;
    margin: 2rem 0rem;
    text-transform: uppercase; }
  .final-decision-wrapper .mtl {
    margin-top: 2em; }

.btnGreen {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.19;
  color: #ffffff;
  border: none;
  border-radius: 15px;
  background-color: #4687F4;
  padding: 5px 10px;
  margin-bottom: 5px;
  width: 100px;
  height: 30px; }

.overview-applicant-modal.edit-ratio-form1 .cross-icon {
  position: absolute;
  right: 36px;
  top: 9px;
  color: #1d1d1d;
  cursor: pointer; }

.overview-applicant-modal.edit-ratio-form1 .content {
  width: 600px;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #eeeeee;
  background-color: #ffffff; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field {
  color: #1d1d1d; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field:disabled {
    background-color: #fff; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1 {
  overflow-x: scroll;
  margin-top: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1 .btns-box {
    text-align: center;
    margin-top: 15px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1 .btns-box button {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #ffffff;
      border: none;
      border-radius: 25px;
      padding: 10px;
      min-width: 100px;
      background: #4687F4; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1 table {
    width: 100%; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1::-webkit-scrollbar {
    height: 8px; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1::-webkit-scrollbar-button {
    display: none; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1::-webkit-scrollbar-track {
    background: transparent; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .table-box1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #4687F4; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table thead::-webkit-scrollbar {
  height: 300px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table thead tr {
  background-color: #e5e5e5; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table thead tr th {
    border: 1px solid #fff;
    font-family: "Helvetica Bold";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0;
    color: #1d1d1d;
    text-align: center;
    padding: 10px;
    background: #4687F4;
    font-family: Helvetica;
    text-align: center;
    color: #ffffff; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody .editIcon {
  height: 20px;
  width: 20px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr {
  margin-top: 10px; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td {
    color: #1d1d1d;
    text-align: center;
    padding: 10px;
    border-right: 2px solid #fff; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td:last-child {
      border-right: none; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .btnIcon {
      background-color: #fafafa;
      border: none;
      float: right; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td ::placeholder {
      margin-top: 200px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search {
      margin: 10px 0; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search > div {
        border: 1px solid #eee;
        padding: 3px 0;
        box-shadow: none;
        border-radius: 7px;
        height: 1px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search input:not([type="hidden"]) {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.19;
        letter-spacing: 0;
        text-align: left;
        color: #000; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__single-value,
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__menu {
        text-transform: capitalize; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__indicators {
        display: none; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__placeholder {
        color: #bcbcbc;
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0;
        text-align: left; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__control {
        position: relative;
        background: #ffffff;
        border: 1px solid #afafaf;
        box-sizing: border-box;
        border-radius: 5px; }
        .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__control::after {
          content: "\f0d7";
          position: absolute;
          font-family: FontAwesome;
          right: 10px;
          top: 13px;
          color: #bcbcbc;
          font-size: 12px; }
        .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .pin-code-select-search .select__control.select__control--is-focused {
          box-shadow: 0 0 5px 0 rgba(0, 16, 97, 0.5); }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .residenceStatus {
      font-weight: 800 !important;
      color: #980e18; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .btn {
      width: 150px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.19;
      color: #ffffff;
      border: none;
      padding: 9px;
      /* background-color: #29a9e0; */
      background: #29a9e0;
      border-radius: 5px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr td .select-box {
      width: 100px;
      width: 138px;
      height: 25px;
      background: #ffffff;
      border: 1px solid #afafaf;
      box-sizing: border-box;
      border-radius: 5px;
      height: 33px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr:nth-child(odd) {
  background: #f0f0f0; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table tbody tr:nth-child(even) {
  background: #fafafa; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .primary-table.bank-table tbody tr:last-child {
  border-top: 1px solid #bcbcbc; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header {
  padding: 15px;
  border-radius: 15px 15px 0 0; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul li {
      margin: 0 10px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul li:first-child {
        margin-left: 0; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul li:last-child {
        margin-right: 0; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header ul li h4 {
        margin-top: 0; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.17;
    color: #4687F4; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header small {
    font-family: Helvetica;
    font-size: 14px;
    line-height: 1.19;
    letter-spacing: -0.1px;
    color: #1d1d1d;
    display: block; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content .input {
  width: 50px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li {
    padding: 15px;
    border-right: 1px solid #eeeeee;
    flex: 1; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li label {
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0;
      color: #4687F4;
      margin-bottom: 10px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li span {
      font-family: Helvetica;
      font-size: 24px;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: #4687F4;
      display: flex;
      align-items: center; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li span .icon {
        margin-top: -3px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-content ul li:last-child {
      border-right: none; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header h4 {
    font-family: "Helvetica Bold";
    font-size: 18px;
    line-height: 0.92;
    letter-spacing: normal;
    color: #4687F4;
    margin-top: 0; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-header button {
    font-family: Helvetica;
    font-size: 14px;
    line-height: 1.19;
    color: #ffffff;
    border: none;
    border-radius: 15px;
    background-color: #4687F4;
    padding: 5px 10px;
    margin-bottom: 5px;
    width: 71px;
    height: 38px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer {
  background-color: #fff;
  border-radius: 0 0 15px 15px; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li {
      padding: 10px 15px;
      border-left: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li label,
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li span {
        font-family: Helvetica;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: #1d1d1d; }
        .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li label .select-box,
        .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li span .select-box {
          height: 20px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li label {
        font-size: 14px;
        margin-bottom: 10px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li span {
        font-size: 24px;
        display: flex;
        align-items: center; }
        .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul li span .select-box {
          height: 20px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul span {
      font-family: Helvetica;
      font-size: 24px;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: #ffffff;
      display: flex;
      align-items: center; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul span .icon {
        margin-top: -3px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul:last-child {
      border-top: 0.5px solid rgba(255, 255, 255, 0.5);
      border-radius: 0 0 15px 15px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul:last-child li:first-child {
        border-bottom-left-radius: 15px; }
      .overview-applicant-modal.edit-ratio-form1 .box-wrapper .box-footer ul:last-child li:last-child {
        border-bottom-right-radius: 15px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field-box {
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 7px;
  width: 100%;
  padding: 15px 12px;
  font-family: "Helvetica";
  font-size: 10px;
  line-height: 1.14;
  /* color: #BCBCBC; */
  letter-spacing: normal;
  height: auto;
  color: #bcbcbc; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field-box ::-webkit-input-placeholder {
    color: #bcbcbc; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field-box .dropdown .select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #bcbcbc;
    border: 1px solid #eee;
    border-radius: 7px;
    font-size: 14px;
    font-family: Helvetica;
    line-height: 1.14;
    letter-spacing: normal;
    padding: 15px 12px;
    text-overflow: ellipsis;
    overflow: hidden; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field-box .input-label {
    font-family: Helvetica;
    font-size: 14px;
    line-height: 1.19;
    color: #1d1d1d;
    margin-top: -35px; }
    .overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-field-box .input-label .span {
      font-family: Arial;
      font-size: 14px;
      line-height: 24px;
      color: #1d1d1d; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .taller {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.19;
  margin-left: 10px;
  color: #1d1d1d;
  height: 200px; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-box1 ::-webkit-input-placeholder {
  color: #bcbcbc; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-box1 .dropdown .select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #bcbcbc;
  border: 1px solid #eee;
  border-radius: 7px;
  font-size: 14px;
  font-family: Helvetica;
  line-height: 1.14;
  letter-spacing: normal;
  padding: 15px 12px;
  text-overflow: ellipsis;
  overflow: hidden; }

.overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-box1 .input-label {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.19;
  margin-left: 10px;
  color: #1d1d1d;
  height: 300px;
  margin-top: -35px; }
  .overview-applicant-modal.edit-ratio-form1 .box-wrapper .input-box1 .input-label .span {
    font-family: Arial;
    font-size: 14px;
    line-height: 24px;
    color: #1d1d1d; }

.textArea-input-field {
  margin-bottom: 2px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
  height: 60px;
  resize: none;
  overflow-y: hidden;
  width: 100%;
  padding: 5px; }

.blueButton {
  font-family: Helvetica;
  font-size: 14px;
  color: #ffffff;
  border: none;
  border-radius: 150px;
  background-color: #4687F4;
  padding: 10px 14px;
  min-width: 80px;
  letter-spacing: 2px; }

.promoter_img {
  height: 45rem;
  display: flex;
  margin: 15px auto;
  border-radius: 1rem; }
  .promoter_img.width {
    width: 90%; }

.profitloss-wrapper {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 3px 50px 15px;
  margin: 10px auto; }

.profitloss-nav {
  display: flex;
  justify-content: space-between;
  height: 72px;
  cursor: pointer;
  margin-bottom: 17px; }
  .profitloss-nav-left {
    padding: 35px 0px 0px 4px;
    font-size: 24px;
    line-height: 32px;
    color: #00cdbe; }
  .profitloss-nav-right {
    padding: 29px 1px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px; }
    .profitloss-nav-right .border-right {
      border-radius: 20px 0px 0px 2px; }
    .profitloss-nav-right_figures {
      color: #181818;
      padding-right: 8px; }
    .profitloss-nav-right_figures_size {
      font-size: 12px;
      margin-left: 3px; }
    .profitloss-nav-right_flex-buttons {
      border: 1px solid #00cdbe;
      border-radius: 4px;
      display: flex; }
      .profitloss-nav-right_flex-buttons .active-button {
        background-color: #00cdbe;
        color: white;
        padding: 2px 5px; }
      .profitloss-nav-right_flex-buttons .normal-button {
        background-color: #ffffff;
        border: 1px solid #00cdbe;
        color: #181818;
        padding: 2px 5px; }
    .profitloss-nav-right_logo {
      color: #00cdbe;
      margin: 0px 6px 0px 12px; }
    .profitloss-nav-right_logo_size {
      margin-top: 7px; }

.text-bold {
  font-weight: 600 !important; }

.highcharts-container {
  margin: 0 auto; }

.datatable-pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1); }
  .datatable-pagination li {
    flex: 1;
    text-align: center; }
    .datatable-pagination li button {
      width: 100%;
      padding: 10px;
      border: none; }

.profitloss-main-table .ReactTable {
  border: none; }
  .profitloss-main-table .ReactTable .rt-table .rt-thead.-header {
    box-shadow: none; }
    .profitloss-main-table .ReactTable .rt-table .rt-thead.-header .rt-tr .rt-th {
      border-right: none !important; }
  .profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group {
    margin-bottom: 2px;
    border-bottom: none; }
    .profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td {
      border-right: none;
      padding: 20px 0px 6px 0px !important; }
    .profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td.rt-expandable .rt-expander {
      border: 1px solid #dddddd;
      margin: 0px 20px 0px 0px;
      border-radius: 50%;
      padding-left: 16px; }
      .profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td.rt-expandable .rt-expander::after {
        transform: translate(-50%, -50%) rotate(360deg); }
    .profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even {
      background-color: #fafafa; }
      .profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td {
        border-right: none;
        padding: 20px 0px 6px 0px !important; }
      .profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td.rt-expandable .rt-expander {
        border: 1px solid #dddddd;
        margin: 0px 13px 0px 0px;
        border-radius: 50%;
        padding-left: 16px; }
        .profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td.rt-expandable .rt-expander::after {
          transform: translate(-50%, -50%) rotate(360deg); }

.profitloss-main-table .ReactTable .rt-tr.-odd + .profitloss-sub-table {
  border: 4px solid #f0f0f0;
  border-top: none; }

.profitloss-main-table .ReactTable .rt-tr.-even + .profitloss-sub-table {
  border: 4px solid #fafafa;
  border-top: none; }

.profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td.rt-expandable .rt-expander:after,
.profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td.rt-expandable .-open:after,
.profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .-even .rt-td.rt-expandable .rt-expander:after,
.profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .-even .rt-td.rt-expandable .-open:after {
  content: "";
  border-top: 7px solid #00cdbe;
  top: 57%;
  left: 50%; }

.profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td.rt-expandable .-open:after,
.profitloss-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td.rt-expandable .-open:after {
  transform: translate(-50%, -50%) rotate(180deg);
  top: 47%;
  left: 50%;
  border-top: 7px solid #00cdbe; }

.profitloss-main-table
.ReactTable
.rt-tr.-odd
+ .profitloss-sub-table
.ReactTable
.rt-tr.-odd {
  background-color: #fafafa; }

.profitloss-main-table
.ReactTable
.rt-tr.-odd
+ .profitloss-sub-table
.ReactTable
.rt-tr.-even {
  background-color: #ffffff; }

.profitloss-main-table
.ReactTable
.rt-tr.-even
+ .profitloss-sub-table
.ReactTable
.rt-tr.-odd {
  background-color: #f0f0f0; }

.profitloss-main-table
.ReactTable
.rt-tr.-even
+ .profitloss-sub-table
.ReactTable
.rt-tr.-even {
  background-color: #ffffff; }

.profitloss-sub-table .ReactTable .rt-thead {
  display: none; }

.profitloss-sub-table .ReactTable .rt-tbody .rt-td {
  border-right: none; }

.profitloss-sub-table .ReactTable .rt-expander {
  display: none; }

.balanceSheet-main-table .ReactTable {
  border: none; }
  .balanceSheet-main-table .ReactTable .rt-table .rt-thead.-header {
    box-shadow: none; }
    .balanceSheet-main-table .ReactTable .rt-table .rt-thead.-header .rt-tr {
      margin-top: 4px;
      border-radius: 11px;
      border-bottom: 5px solid #fafafa; }
      .balanceSheet-main-table .ReactTable .rt-table .rt-thead.-header .rt-tr .rt-th {
        border-right: none !important; }
  .balanceSheet-main-table .ReactTable .rt-table .rt-tbody {
    margin-bottom: 13px; }
    .balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      margin: 0px 3px 5px 3px;
      line-height: 18px; }
      .balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td {
        padding: 20px 0px 6px 0px !important;
        border-right: none; }
      .balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td.rt-expandable .rt-expander {
        border: 1px solid #dddddd;
        border-radius: 50%;
        margin: 9px 20px 0px 0px;
        padding: 2px 0px 0px 15px; }
        .balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td.rt-expandable .rt-expander::after {
          transform: translate(-50%, -50%) rotate(360deg); }
      .balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td {
        padding: 20px 0px 6px 0px !important;
        border-right: none; }
      .balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td.rt-expandable .rt-expander {
        border: 1px solid #dddddd;
        border-radius: 50%;
        margin: 9px 20px 0px 0px;
        padding: 2px 0px 0px 15px; }
        .balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td.rt-expandable .rt-expander::after {
          transform: translate(-50%, -50%) rotate(360deg); }

.balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td.rt-expandable .rt-expander:after,
.balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .-even .rt-td.rt-expandable .rt-expander:after {
  content: "";
  border-top: 8px solid #00cdbe;
  top: 57%;
  left: 50%; }

.balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td.rt-expandable .-open:after,
.balanceSheet-main-table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td.rt-expandable .-open:after {
  transform: translate(-50%, -50%) rotate(180deg);
  top: 47%;
  left: 50%;
  border-top: 8px solid #00cdbe; }

.balanceSheet-main-table .ReactTable .rt-tr {
  margin-top: 4px;
  border-radius: 15px;
  border-bottom: 5px solid #fafafa; }

.balanceSheet-main-table .ReactTable .rt-tr.-odd {
  background-color: white; }

.balanceSheet-main-table
.ReactTable
.rt-tr.-odd
+ .balanceSheet-sub-table
.ReactTable
.rt-tr.-even {
  background-color: #f0f0f0; }

.balanceSheet-main-table
.ReactTable
.rt-tr.-even
+ .balanceSheet-sub-table
.ReactTable
.rt-tr.-odd {
  background-color: #fafafa; }

.balanceSheet-main-table
.ReactTable
.rt-tr.-even
+ .balanceSheet-sub-table
.ReactTable
.rt-tr.-even {
  background-color: #f0f0f0; }

.balanceSheet-main-table
.ReactTable
.rt-tr.-odd
+ .balanceSheet-sub-table
.ReactTable
.rt-tbody
.rt-tr-group {
  box-shadow: none;
  margin: 0px 2px 1px 2px;
  border-bottom: none; }

.balanceSheet-main-table
.ReactTable
.rt-tr.-even
+ .balanceSheet-sub-table
.ReactTable
.rt-tbody
.rt-tr-group {
  box-shadow: none;
  margin: 0px 2px 1px 2px;
  border-bottom: none; }

.balanceSheet-sub-table .ReactTable .rt-thead {
  display: none; }

.balanceSheet-sub-table .ReactTable .rt-tbody .rt-td {
  border-right: none; }

.balanceSheet-sub-table .ReactTable .rt-expander {
  display: none; }

.balanceSheet-sub-table .ReactTable .rt-tr {
  border-bottom: none; }

.balanceSheet-sub-table .ReactTable .rt-tbody .rt-tr-group .rt-tr.-odd {
  border-radius: 0px;
  background-color: #fafafa; }

.balanceSheet-sub-table .ReactTable .rt-tbody .rt-tr-group .rt-tr.-even {
  border-radius: 0px; }

.body .ReactTable .rt-tbody .rt-td {
  border-right: none; }

.financial-analysis-ratio__wrapper {
  position: relative;
  width: 100%;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: normal; }
  .financial-analysis-ratio__wrapper .box-wrapper {
    background: #fafafa;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 25px 30px;
    color: #00cdbe; }
    .financial-analysis-ratio__wrapper .box-wrapper.inner-box {
      background-color: #ffffff;
      margin-top: 10px;
      padding: 18px 35px;
      border-radius: 10px;
      position: relative; }
      .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; }
        .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper .child-space-between {
          display: flex;
          justify-content: space-between;
          background: #3670cc;
          border-radius: 10px 10px 0 0; }
          .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper .child-space-between div:first-child {
            border-right: 1px solid #e5e5e5; }
          .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper .child-space-between .charts-heading {
            padding: 13px 25px;
            color: white;
            font-size: 16px;
            width: 50%; }
        .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper .chart__wrapper {
          background-color: #000000;
          background: #ffffff;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          margin: 15px 20px 10px 0px;
          min-width: 25rem;
          width: 47%;
          color: black;
          font-size: 14px; }
          .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper .chart__wrapper .numerics {
            height: 19rem;
            border-right: 1px solid #e5e5e5; }
            .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper .chart__wrapper .numerics .ratios-values {
              padding: 10px 20px; }
              .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper .chart__wrapper .numerics .ratios-values .year {
                padding-top: 6px;
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
                .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper .chart__wrapper .numerics .ratios-values .year .value {
                  font-weight: bold !important;
                  margin-right: 11%;
                  color: #181818; }
          .financial-analysis-ratio__wrapper .box-wrapper.inner-box .charts-outer__wrapper .chart__wrapper .chart-graph {
            padding: 20px; }
      .financial-analysis-ratio__wrapper .box-wrapper.inner-box .arrow {
        position: absolute;
        top: 15px;
        right: 40px;
        cursor: pointer; }
        .financial-analysis-ratio__wrapper .box-wrapper.inner-box .arrow.rotate {
          transform: rotate(180deg); }
    .financial-analysis-ratio__wrapper .box-wrapper .icons {
      position: absolute;
      right: 90px;
      top: 25px; }
      .financial-analysis-ratio__wrapper .box-wrapper .icons .icon-styles {
        padding-left: 20px; }
  .financial-analysis-ratio__wrapper .no-display {
    display: none; }

.assessee-details__wrapper {
  position: relative; }
  .assessee-details__wrapper .proofs__cards {
    display: flex;
    flex-wrap: wrap; }
    .assessee-details__wrapper .proofs__cards .details__card {
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      margin: 15px 20px 10px 0px;
      max-width: 365px;
      min-width: 270px;
      width: 30%;
      font-size: 15px; }
      .assessee-details__wrapper .proofs__cards .details__card .head {
        border-bottom: #dfdfdf 1px solid;
        padding: 10px 15px; }
        .assessee-details__wrapper .proofs__cards .details__card .head .heading {
          font-weight: bold !important;
          margin-right: 10%;
          border: none !important; }
        .assessee-details__wrapper .proofs__cards .details__card .head .verified {
          font-size: 12px;
          color: white;
          border-radius: 15px;
          background-color: #00cdbe;
          padding: 5px 15px; }
          .assessee-details__wrapper .proofs__cards .details__card .head .verified span {
            padding-left: 10px; }
      .assessee-details__wrapper .proofs__cards .details__card .body {
        padding: 15px;
        color: #000000;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        .assessee-details__wrapper .proofs__cards .details__card .body .item {
          padding: 5px; }
          .assessee-details__wrapper .proofs__cards .details__card .body .item .title {
            font-weight: bold !important; }
  .assessee-details__wrapper .box-wrapper {
    margin-top: 15px; }
    .assessee-details__wrapper .box-wrapper .trader-name .icons {
      top: 40px; }
    .assessee-details__wrapper .box-wrapper .head {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .assessee-details__wrapper .box-wrapper .head .heading {
        font-size: 18px;
        border: none; }
      .assessee-details__wrapper .box-wrapper .head .verified {
        font-size: 12px;
        color: white;
        border-radius: 15px;
        background-color: #00cdbe;
        padding: 5px 15px; }
        .assessee-details__wrapper .box-wrapper .head .verified span {
          padding-left: 10px; }
    .assessee-details__wrapper .box-wrapper .body {
      padding-top: 20px;
      padding-bottom: 20px; }
      .assessee-details__wrapper .box-wrapper .body .ReactTable .rt-tbody {
        min-width: 100% !important; }
      .assessee-details__wrapper .box-wrapper .body .ReactTable .rt-th {
        border: none !important;
        font-size: 14px;
        padding: 15px !important; }
      .assessee-details__wrapper .box-wrapper .body .ReactTable .rt-th,
      .assessee-details__wrapper .box-wrapper .body .ReactTable .rt-tbody {
        width: 1%; }
        .assessee-details__wrapper .box-wrapper .body .ReactTable .rt-th.collapse,
        .assessee-details__wrapper .box-wrapper .body .ReactTable .rt-tbody.collapse {
          width: 0.0000000001%; }
      .assessee-details__wrapper .box-wrapper .body .ReactTable .rt-tbody {
        font-size: 14px;
        color: #181818; }
      .assessee-details__wrapper .box-wrapper .body .ReactTable .rt-noData {
        position: absolute;
        top: 145%;
        width: 220px; }

.password-reset-modal {
  justify-content: center;
  margin: 0px auto; }

.btnn {
  width: 110px;
  height: 40px;
  font-family: Helvetica;
  font-size: 18px;
  line-height: .89;
  letter-spacing: -.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: none; }

.right {
  float: right; }

.padding-bottom-50 {
  padding-bottom: 50px; }

a:hover {
  color: white;
  text-decoration: none; }

a {
  text-decoration: none;
  color: white; }

.blue-btn {
  background-color: #001061; }

.green-btn {
  background-color: #4687f4; }

.horizontal-space-20 {
  padding: 0px 20px; }

.password-reset-box {
  margin: auto;
  width: 45%;
  border-radius: 20px;
  background-color: white;
  padding: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }

.p-20 {
  padding: 20px; }

.center {
  justify-content: center;
  margin: auto;
  width: auto; }

.m-b-60 {
  margin-bottom: 60px; }

.p-b-30 {
  padding-bottom: 30px; }

.input-w-93 {
  width: 93%; }

.text-align-center {
  text-align: left; }

::placeholder {
  font-size: 13px; }

@media screen and (max-width: 600px) {
  .password-reset-box {
    margin: auto;
    width: 90%;
    border-radius: 20px;
    background-color: white;
    padding: auto;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .padding-bottom-50 {
    padding-bottom: 20px; } }

.admin-wrapper input::placeholder,
.admin-wrapper textarea::placeholder {
  color: #bababa; }

.admin-wrapper #adminTabs {
  width: 80%;
  margin: 0px auto; }

.admin-wrapper .main-heading {
  font-family: "Helvetica Bold";
  font-size: 32px;
  line-height: 51px;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #001061;
  padding: 0 10px 20px; }

.admin-wrapper .userName {
  color: #001061;
  margin: 1em 0em;
  font-weight: 600 !important; }

.admin-wrapper .password-validation__text {
  color: #8A8A8A;
  margin: 10px; }

.admin-wrapper .btn-wrapper {
  display: flex;
  justify-content: center; }
  .admin-wrapper .btn-wrapper .btn {
    background: #29a9e0;
    color: #fff;
    padding: 0.5em 2em;
    border-radius: 20px;
    border: none;
    margin-top: 1em; }

.admin-wrapper .content-box {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #ffffff;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 15px; }
  .admin-wrapper .content-box .select-box__assign-user {
    opacity: 0;
    width: 100px; }
  .admin-wrapper .content-box .user-plus__assign-user {
    transform: translateX(45px); }
  .admin-wrapper .content-box .rt-tbody .rt-tr {
    cursor: context-menu !important; }
  .admin-wrapper .content-box .icons {
    cursor: pointer; }
  .admin-wrapper .content-box .box-header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .admin-wrapper .content-box .box-header h4 {
      font-family: "Helvetica Bold";
      font-size: 16px;
      line-height: 28px;
      margin: 0; }
    .admin-wrapper .content-box .box-header button {
      background: #4687f4;
      border-radius: 20px;
      font-family: Helvetica;
      font-size: 16px;
      line-height: 28px;
      color: #fff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 6px 5px 3px; }
  .admin-wrapper .content-box .box-content {
    margin-top: 20px; }
    .admin-wrapper .content-box .box-content .content-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px; }
      .admin-wrapper .content-box .box-content .content-row > label {
        font-family: Helvetica;
        font-size: 16px;
        line-height: 24px; }
      .admin-wrapper .content-box .box-content .content-row .react-switch-bg {
        border: 1px solid #9a9a9a;
        width: 40px !important;
        height: 20px !important; }
        .admin-wrapper .content-box .box-content .content-row .react-switch-bg span {
          font-family: Arial;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: #4687f4; }
        .admin-wrapper .content-box .box-content .content-row .react-switch-bg div:first-child {
          top: 2px !important;
          left: 9px !important; }
        .admin-wrapper .content-box .box-content .content-row .react-switch-bg div:last-child {
          top: 2px !important;
          right: 9px !important; }
      .admin-wrapper .content-box .box-content .content-row .react-switch-handle {
        width: 21px !important;
        height: 18px !important;
        border-radius: 25px !important; }
      .admin-wrapper .content-box .box-content .content-row .switch-open .react-switch-handle {
        transform: translateX(19px) !important; }
      .admin-wrapper .content-box .box-content .content-row .switch-open .react-switch-bg {
        border: 1px solid #29a9e0; }
    .admin-wrapper .content-box .box-content table {
      width: 100%;
      margin-bottom: 10px; }
      .admin-wrapper .content-box .box-content table thead {
        background-color: #fafafa; }
        .admin-wrapper .content-box .box-content table thead tr th {
          font-family: "Helvetica Bold";
          font-size: 16px;
          line-height: 24px;
          color: #1d1d1d;
          padding: 10px; }
      .admin-wrapper .content-box .box-content table tbody tr {
        border-bottom: 1px solid #efefef; }
        .admin-wrapper .content-box .box-content table tbody tr:last-child {
          border-bottom: none; }
        .admin-wrapper .content-box .box-content table tbody tr td {
          font-family: Helvetica;
          font-size: 16px;
          line-height: 24px;
          color: #1d1d1d;
          padding: 20px 10px; }
          .admin-wrapper .content-box .box-content table tbody tr td .actions-box {
            display: flex;
            align-items: center;
            justify-content: space-between; }
            .admin-wrapper .content-box .box-content table tbody tr td .actions-box span {
              cursor: pointer; }
          .admin-wrapper .content-box .box-content table tbody tr td .checkbox-box {
            justify-content: center; }
          .admin-wrapper .content-box .box-content table tbody tr td .select-box {
            display: inline-block;
            border: 1px solid #bcbcbc;
            border-radius: 7px;
            width: 75%;
            padding: 12px 15px;
            font-family: "Helvetica";
            font-size: 14px;
            line-height: 1.14;
            letter-spacing: normal; }
          .admin-wrapper .content-box .box-content table tbody tr td .rangeslider {
            height: 7px;
            background-color: transparent;
            border: 1px solid #4687F4;
            box-shadow: none;
            margin: 0 auto; }
            .admin-wrapper .content-box .box-content table tbody tr td .rangeslider .rangeslider__handle {
              background-color: #4687F4;
              width: 15px;
              height: 15px;
              box-shadow: none;
              border: none;
              transform: translate3d(-10%, -50%, 0); }
              .admin-wrapper .content-box .box-content table tbody tr td .rangeslider .rangeslider__handle::after {
                display: none; }
              .admin-wrapper .content-box .box-content table tbody tr td .rangeslider .rangeslider__handle .rangeslider__handle-label {
                margin: -20px 0 0 -3px;
                white-space: nowrap; }
            .admin-wrapper .content-box .box-content table tbody tr td .rangeslider .rangeslider__fill {
              background-color: #4687F4; }
  .admin-wrapper .content-box.parameterization-wrapper .box-content table tbody tr td:last-child {
    width: 200px; }
  .admin-wrapper .content-box .status-btn {
    min-width: 100px;
    padding: 0.3em 0em;
    color: #ffffff;
    border: none;
    border-radius: 20px; }
  .admin-wrapper .content-box .datatable-pagination {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
    .admin-wrapper .content-box .datatable-pagination li {
      flex: 1;
      text-align: center; }
      .admin-wrapper .content-box .datatable-pagination li button {
        width: 100%;
        padding: 10px;
        border: none; }

.admin-wrapper .roles-modal {
  width: 600px;
  padding: 30px;
  margin: 20px;
  background-color: #fff;
  border-radius: 20px;
  overflow-x: scroll; }
  .admin-wrapper .roles-modal::-webkit-scrollbar {
    height: 3px; }
  .admin-wrapper .roles-modal::-webkit-scrollbar-button {
    display: none; }
  .admin-wrapper .roles-modal::-webkit-scrollbar-track {
    background: transparent; }
  .admin-wrapper .roles-modal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #4687F4; }
  .admin-wrapper .roles-modal .box-header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .admin-wrapper .roles-modal .box-header h4 {
      font-family: "Helvetica";
      font-size: 24px;
      line-height: 21px;
      color: #4687f4;
      margin: 0; }
    .admin-wrapper .roles-modal .box-header .cross-icon {
      color: #bcbcbc;
      cursor: pointer; }
  .admin-wrapper .roles-modal .form-box .input-row {
    margin-top: 20px; }
    .admin-wrapper .roles-modal .form-box .input-row .input-field {
      margin-top: 3px; }
  .admin-wrapper .roles-modal .form-box .checkbox-box {
    margin-top: 10px; }
  .admin-wrapper .roles-modal .form-box .select-box {
    display: inline-block;
    border: 1px solid #bcbcbc;
    border-radius: 7px;
    width: 100%;
    padding: 9px 10px;
    font-family: "Helvetica";
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: normal;
    margin-top: 3px; }
  .admin-wrapper .roles-modal .form-box .btns-box {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center; }
    .admin-wrapper .roles-modal .form-box .btns-box button {
      width: 150px;
      font-family: Helvetica;
      font-size: 18px;
      line-height: 24px;
      border: none;
      border-radius: 50px;
      padding: 12px; }
      .admin-wrapper .roles-modal .form-box .btns-box button.cancel-btn {
        color: #777777;
        background-color: #ececec; }
      .admin-wrapper .roles-modal .form-box .btns-box button.confirm-btn {
        color: #fff;
        background-color: #4687f4; }

.admin-wrapper .modal-smaller {
  max-width: 400px !important; }

.mt-3 {
  margin-top: 1em; }

.camWrapper {
  max-width: 85%;
  margin: 40px auto; }
  .camWrapper .printPage {
    float: right;
    background: transparent;
    border-radius: 20px;
    border: 1px solid #00cdbe;
    padding: 0.5em 1em; }
  .camWrapper .box-wrapper {
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(90deg, #001061 0%, #00cdbe 100%) bottom #fff no-repeat;
    background-size: 100% 10px;
    padding: 1em 2em 4em;
    margin-top: 2em; }
    .camWrapper .box-wrapper .box-heading {
      color: #00cdbe; }
    .camWrapper .box-wrapper hr {
      margin: 10px 0px; }
    .camWrapper .box-wrapper .row {
      margin-top: 1.2em; }
      .camWrapper .box-wrapper .row .box-subheading {
        font-family: Helvetica;
        font-size: 14px;
        color: #1d1d1d;
        font-weight: bold !important; }
    .camWrapper .box-wrapper .annexure-row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #999999;
      line-height: 2.5em; }
      .camWrapper .box-wrapper .annexure-row .checkBox {
        margin-right: 10rem;
        height: 1.5rem;
        width: 1.5rem;
        background-color: #fff; }
    .camWrapper .box-wrapper .strengths {
      color: #00b628;
      margin-bottom: 2%; }
    .camWrapper .box-wrapper .flags {
      color: #c0553e;
      margin-bottom: 2%; }
    .camWrapper .box-wrapper .reference-wrapper {
      border-radius: 20px;
      margin-right: 2em;
      min-height: 450px; }
    .camWrapper .box-wrapper .headingBusiness {
      margin-bottom: 1em;
      color: #001061;
      font-weight: 800 !important; }
    .camWrapper .box-wrapper .verify {
      border: 1px solid #00abad; }
    .camWrapper .box-wrapper .notVerify {
      border: 1px solid #ad1f00; }
    .camWrapper .box-wrapper .verifyText {
      position: absolute;
      bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #00abad;
      width: 100%;
      margin-left: -15px;
      border-radius: 0px 0px 17px 17px;
      height: 30px; }
    .camWrapper .box-wrapper .notVerifyText {
      position: absolute;
      bottom: 0px;
      color: #fff;
      background-color: #ad1f00;
      width: 100%;
      margin-left: -15px;
      border-radius: 0px 0px 17px 17px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .camWrapper .box-wrapper .businessAnalysisWrapper {
      border: 3px solid #00abad;
      border-radius: 10px; }
      .camWrapper .box-wrapper .businessAnalysisWrapper td.table-category {
        border-bottom: 2px solid #bababa;
        padding: 1em; }
      .camWrapper .box-wrapper .businessAnalysisWrapper td.table-value {
        border-bottom: 2px solid #bababa;
        border-left: 2px solid #bababa;
        padding: 1em; }
    .camWrapper .box-wrapper table {
      width: 100%; }
      .camWrapper .box-wrapper table thead th {
        background-color: #001061;
        color: #fff;
        padding: 10px 0 !important;
        border-right: 1px solid #fff !important;
        text-align: center; }
      .camWrapper .box-wrapper table tbody {
        background-color: #fafafa; }
        .camWrapper .box-wrapper table tbody td {
          text-align: center;
          padding: 1em;
          border: 1px solid #bababa; }
    .camWrapper .box-wrapper .months-list {
      flex: 1;
      display: flex;
      list-style: none;
      width: 100%;
      flex-wrap: wrap; }
      .camWrapper .box-wrapper .months-list li {
        font-family: Helvetica;
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: normal;
        color: #ffffff;
        flex: 1;
        background-color: #00cdbe;
        text-align: center;
        border-right: 0.5px solid #fff; }
    .camWrapper .box-wrapper .aggregate-table__heading {
      padding: 15px;
      background-color: #001061;
      text-align: center;
      color: #fff; }
    .camWrapper .box-wrapper td.aggregate-table__months {
      font-weight: 900 !important; }
  .camWrapper .heading span {
    line-height: 40px;
    font-family: Helvetica;
    font-size: 2em;
    font-weight: bold !important;
    padding-left: 1.5em;
    color: #001061; }
  .camWrapper .heading-hr {
    border: none;
    height: 2px;
    background-color: #001061;
    /* Modern Browsers */ }
  .camWrapper .caseId {
    padding-left: 2em;
    color: #001061;
    font-size: 1.1em; }
    .camWrapper .caseId .caseId-Box {
      background-color: #001061;
      color: #fff;
      padding: 0.5em 1em;
      border-radius: 5px;
      margin-left: 5px; }
  .camWrapper .case-status {
    padding-right: 2em;
    color: #001061;
    font-size: 1.1em; }
    .camWrapper .case-status .case-status-box {
      background-color: #00cdbe;
      color: #fff;
      padding: 0.5em 1em;
      border-radius: 5px;
      margin-left: 5px; }
  .camWrapper .section-heading {
    margin-top: 1em;
    font-size: 2em;
    color: #00cdbe;
    font-weight: bold !important;
    padding: 0px 1em;
    display: flex;
    justify-content: space-between; }
    .camWrapper .section-heading .alphabets {
      letter-spacing: 0.8em;
      margin-left: 0.5em;
      color: #bbbbbb;
      font-weight: bold !important; }
      .camWrapper .section-heading .alphabets .letter {
        color: #fff;
        background-color: #00cdbe;
        border-radius: 100px;
        margin-right: 0.7em;
        font-weight: bold !important; }
        .camWrapper .section-heading .alphabets .letter span {
          letter-spacing: normal;
          padding: 2px 7px; }
    .camWrapper .section-heading .section-hr {
      width: 60%;
      background: #999999; }
    .camWrapper .section-heading .section-details {
      color: #001061;
      font-size: 0.6em;
      vertical-align: middle;
      display: table-cell;
      padding-top: 0.4em;
      width: 20%;
      margin-left: 1em;
      font-weight: bold !important; }
  .camWrapper .cc-score-box {
    margin-top: 20px;
    margin-bottom: 5px; }
    .camWrapper .cc-score-box .vl {
      border-left: 1px solid;
      height: 93px;
      position: absolute;
      left: 50%;
      margin-left: 33px;
      top: 0; }
    .camWrapper .cc-score-box .guageChart {
      margin-left: -32px;
      padding: 16px; }
    .camWrapper .cc-score-box .chart {
      margin-left: 7px; }
    .camWrapper .cc-score-box label {
      font-family: "Helvetica Bold";
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: normal;
      color: #1d1d1d; }
    .camWrapper .cc-score-box span {
      font-family: "Helvetica Bold";
      font-size: 26px;
      line-height: 1.21;
      letter-spacing: normal;
      color: #ed5a5a; }
    .camWrapper .cc-score-box ul {
      list-style: none;
      display: flex;
      margin: 30px 0 0; }
      .camWrapper .cc-score-box ul li {
        flex: 1;
        border: 0.5px solid #ddd;
        padding: 2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: 0; }
        .camWrapper .cc-score-box ul li:first-child {
          flex: 2.5;
          color: #1d1d1d; }
        .camWrapper .cc-score-box ul li:nth-child(n + 2) {
          background-color: #000;
          color: #fff; }
        .camWrapper .cc-score-box ul li span {
          position: absolute;
          top: -30px;
          font-family: "Helvetica Bold";
          font-size: 14px;
          line-height: 1.14;
          letter-spacing: 0;
          color: #ed5a5a;
          white-space: nowrap; }
          .camWrapper .cc-score-box ul li span div {
            display: block !important;
            margin-top: -3px; }
        .camWrapper .cc-score-box ul li:nth-child(2), .camWrapper .cc-score-box ul li:nth-child(3) {
          background-color: #e8505b; }
        .camWrapper .cc-score-box ul li:nth-child(4) {
          background-color: #ed625a; }
        .camWrapper .cc-score-box ul li:nth-child(5) {
          background-color: #ed745a; }
        .camWrapper .cc-score-box ul li:nth-child(6) {
          background-color: #ed7c5a; }
        .camWrapper .cc-score-box ul li:nth-child(7) {
          background-color: #ed845a; }
        .camWrapper .cc-score-box ul li:nth-child(8) {
          background-color: #ed8c5a; }
        .camWrapper .cc-score-box ul li:nth-child(9) {
          background-color: #ed945a; }
        .camWrapper .cc-score-box ul li:nth-child(10) {
          background-color: #ed9c5a; }
        .camWrapper .cc-score-box ul li:nth-child(11) {
          background-color: #edac5a; }
        .camWrapper .cc-score-box ul li:nth-child(12) {
          background-color: #edbc5a; }
        .camWrapper .cc-score-box ul li:nth-child(13) {
          background-color: #edc45a; }
        .camWrapper .cc-score-box ul li:nth-child(14) {
          background-color: #edd45a; }
        .camWrapper .cc-score-box ul li:nth-child(15) {
          background-color: #c9de4d; }
        .camWrapper .cc-score-box ul li:nth-child(16) {
          background-color: #9bdd42; }
        .camWrapper .cc-score-box ul li:nth-child(17) {
          background-color: #7ace3d; }
        .camWrapper .cc-score-box ul li:nth-child(18) {
          background-color: #5dc145; }
        .camWrapper .cc-score-box ul li:nth-child(19) {
          background-color: #54b23e; }
        .camWrapper .cc-score-box ul li:nth-child(20) {
          background-color: #48a537; }
        .camWrapper .cc-score-box ul li:nth-child(21) {
          background-color: #3f9625; }
        .camWrapper .cc-score-box ul li:nth-child(22) {
          background-color: #267e2d; }
        .camWrapper .cc-score-box ul li:nth-child(23) {
          background-color: #0f6226; }

.flex-space-between {
  display: flex;
  justify-content: space-between; }

.ReactTable .rt-table {
  overflow-x: scroll; }
  .ReactTable .rt-table::-webkit-scrollbar {
    height: 8px; }
  .ReactTable .rt-table::-webkit-scrollbar-button {
    display: none; }
  .ReactTable .rt-table::-webkit-scrollbar-track {
    background: transparent; }
  .ReactTable .rt-table::-webkit-scrollbar-thumb {
    background: #4687F4; }

.ReactTable .rt-th {
  background-color: #4687F4;
  color: #fff;
  padding: 10px 0 !important;
  border-right: 1px solid #fff !important; }
  .ReactTable .rt-th.-sort-asc::after {
    content: "\f0d7";
    position: absolute;
    font-family: FontAwesome;
    right: 9px;
    top: 10px;
    font-size: 16px; }
  .ReactTable .rt-th.-sort-desc::after {
    content: "\f0d8";
    position: absolute;
    font-family: FontAwesome;
    right: 9px;
    top: 10px;
    font-size: 16px; }

.ReactTable .rt-tbody .rt-tr {
  cursor: pointer; }
  .ReactTable .rt-tbody .rt-tr .rt-td {
    text-align: center;
    padding: 25px 0 !important;
    white-space: normal; }
    .ReactTable .rt-tbody .rt-tr .rt-td .edit-icon {
      color: #5f5f5f;
      border-bottom: 1px solid #5f5f5f;
      padding-bottom: 2px; }
    .ReactTable .rt-tbody .rt-tr .rt-td .search-icon {
      color: #5f5f5f; }

.ReactTable .rt-tr.-odd {
  background-color: #F7F9FC; }

.bold {
  font-weight: bold !important; }

.mts {
  margin-top: 1em; }

.mtl {
  margin-top: 2em; }

.m-0 {
  margin: 0px !important; }

.fiWrapper {
  text-align: left;
  margin: 0px;
  padding: 0px; }
  .fiWrapper .fiStatus {
    color: #001061;
    font-weight: 700 !important;
    text-transform: capitalize; }

.fontBold {
  font-weight: bold !important; }

@page {
  size: A4;
  margin: 0; }

@media print {
  div {
    page-break-inside: avoid; }
  svg {
    max-width: 90vw; }
  .ReactTable {
    max-width: 95vw; }
    .ReactTable .rt-table {
      max-width: 95vw; }
  table {
    font-size: 1vw; }
  .rt-table {
    font-size: 1vw; }
  .ReactTable {
    font-size: 1vw; }
  .camWrapper {
    max-width: 100%; }
  .printPage {
    display: none; }
  html,
  body {
    padding: 0px;
    margin: 0px;
    width: 210mm;
    height: 297mm; } }

.cpo-dashboard__wrapper .ReactTable .rt-thead .rt-th {
  position: relative;
  background-color: #4687F4; }

.cpo-dashboard__wrapper .datatable-pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1); }
  .cpo-dashboard__wrapper .datatable-pagination li {
    flex: 1;
    text-align: center; }
    .cpo-dashboard__wrapper .datatable-pagination li button {
      width: 100%;
      padding: 10px;
      border: none; }

.cpo-dashboard__wrapper .nav-tabs {
  margin: 30px 30px 0;
  border-color: #bcbcbc; }
  .cpo-dashboard__wrapper .nav-tabs > li {
    margin: 0 4px -1px;
    background-color: #e5e5e5;
    border-radius: 5px 5px 0 0;
    position: relative; }
    .cpo-dashboard__wrapper .nav-tabs > li a {
      color: #1d1d1d;
      border-radius: 5px 5px 0 0;
      font-family: Helvetica;
      font-size: 14px;
      line-height: 1.19;
      letter-spacing: 0;
      padding: 10px 20px; }
      .cpo-dashboard__wrapper .nav-tabs > li a label {
        font-family: "Helvetica Bold";
        margin-bottom: 0; }
    .cpo-dashboard__wrapper .nav-tabs > li.active::after {
      content: "";
      width: 75%;
      height: 2px;
      background-color: #4687F4;
      position: absolute;
      bottom: 0;
      left: 13%; }
    .cpo-dashboard__wrapper .nav-tabs > li.active a {
      color: #4687F4;
      margin-right: 0;
      font-family: "Helvetica Bold"; }

.cpo-dashboard__wrapper .select-box__assign-user {
  opacity: 0;
  width: 100px; }

.cpo-dashboard__wrapper .user-plus__assign-user {
  transform: translateX(45px); }

.banking-exceptions.heading {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 28px;
  border-bottom: 1px solid #e0e0e0;
  color: #000;
  display: flex;
  justify-content: space-between; }
  .banking-exceptions.heading .options {
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    color: #1d1d1d;
    width: 44%;
    margin: auto 0; }
    .banking-exceptions.heading .options a {
      color: black; }
    .banking-exceptions.heading .options .icon-color {
      color: #4687F4;
      opacity: 0.8; }
  .banking-exceptions.heading .headings.bold-heading {
    font-size: 2.2rem;
    font-style: "Helvetica"; }

.details {
  padding-bottom: 25px !important; }
  .details .client-name-style {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #001061; }
  .details .inner-heading {
    background-color: #eeeeee;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; }
    .details .inner-heading .delete-text {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      margin: auto;
      color: #1d1d1d; }
  .details .delete-icon {
    color: #ff2918;
    opacity: 0.8; }
  .details .inner-data__wrapper {
    padding: 4%;
    padding-top: 20px !important; }
    .details .inner-data__wrapper .inner-data-labels {
      font-style: normal;
      font-weight: 700 !important;
      font-size: 16px;
      color: #555555; }
    .details .inner-data__wrapper .inner-data-values {
      color: #1d1d1d;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.02em; }
    .details .inner-data__wrapper .row .add-pdf__wrapper {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      background: #fafafa;
      border-radius: 10px;
      color: #001061; }
      .details .inner-data__wrapper .row .add-pdf__wrapper .add-pdf {
        padding: 10px !important;
        display: flex;
        justify-content: space-between; }
        .details .inner-data__wrapper .row .add-pdf__wrapper .add-pdf input[type="file"] {
          z-index: 0 !important;
          top: 20%;
          right: 10%;
          height: 40px;
          width: 70px; }
          .details .inner-data__wrapper .row .add-pdf__wrapper .add-pdf input[type="file"]:hover {
            cursor: pointer; }
        .details .inner-data__wrapper .row .add-pdf__wrapper .add-pdf .btn-danger {
          background: #4687F4;
          border-radius: 5px;
          border: 1px  solid; }
        .details .inner-data__wrapper .row .add-pdf__wrapper .add-pdf .add-pdf-text {
          margin: auto;
          font-weight: 600 !important; }
      .details .inner-data__wrapper .row .add-pdf__wrapper #uploadedPdfList {
        padding: 5px 7px;
        overflow: auto;
        max-height: 20vh;
        border-top: 1px solid #454545; }
        .details .inner-data__wrapper .row .add-pdf__wrapper #uploadedPdfList .uploadedFileList {
          list-style-type: none;
          color: #9da0a7;
          border: 1px solid #bcbcbc;
          border-radius: 5px;
          overflow: hidden;
          padding: 5px;
          margin-top: 2px; }

.heading {
  font-size: 2.2rem;
  color: #000;
  font-style: Helvetica !important; }

.close-btns {
  display: flex;
  justify-content: center;
  margin-top: 40px; }
  .close-btns .bolder-text {
    font-weight: 670 !important; }

.otp-triggered__wrapper {
  width: 100%;
  margin: 40px auto 20px auto;
  text-align: center; }
  .otp-triggered__wrapper .otp-triggered .otp-triggered-heading {
    font-size: 16px;
    color: #000;
    font-weight: 700 !important;
    margin-bottom: 5px; }
  .otp-triggered__wrapper .otp-triggered .enter-otp {
    margin-left: 5px; }
    .otp-triggered__wrapper .otp-triggered .enter-otp input::placeholder {
      letter-spacing: 1px; }
    .otp-triggered__wrapper .otp-triggered .enter-otp #otp {
      margin-top: 20px;
      background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 50px 1px;
      background-repeat: repeat-x;
      background-position-x: 35px;
      border: none;
      width: 9ch;
      align-content: center;
      background: -webkit-repeating-linear-gradient(left, #696969, #696969 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat;
      background: repeating-linear-gradient(90deg, #696969, #696969 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat;
      color: #1d1d1d;
      font: 5ch consolas, monospace;
      letter-spacing: 0.5ch; }
  .otp-triggered__wrapper .otp-triggered .otp-not-received {
    font-size: 12px;
    color: #8e8e8e;
    font-weight: bolder !important; }
    .otp-triggered__wrapper .otp-triggered .otp-not-received button {
      color: #001061;
      font-weight: bolder !important;
      font-size: 12px;
      background: none;
      border: none;
      font: inherit;
      cursor: pointer; }
  .otp-triggered__wrapper .otp-triggered .general-text {
    color: #8e8e8e;
    font-size: 16px;
    line-height: 14px; }

@media only screen and (max-width: 700px) {
  .banking-exceptions.heading .options {
    font-size: 12px;
    color: #1d1d1d;
    width: 40%;
    margin: auto 0;
    flex-wrap: wrap; }
    .banking-exceptions.heading .options .icon-color {
      color: #4687F4; }
  .close-btns .bolder-text {
    font-weight: 600 !important; }
  .row .add-pdf__wrapper {
    width: 90%;
    margin: auto; }
    .row .add-pdf__wrapper .add-pdf .add-pdf-text {
      font-weight: 700 !important; } }

.otpLink-form {
  text-align: left; }
  .otpLink-form .input-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px;
    flex-direction: column; }
  .otpLink-form label {
    text-align: left;
    margin: 10px 0; }
  .otpLink-form input {
    padding: 10px;
    border-radius: 10px;
    border: solid 1px #bcbcbc;
    background-color: #ffffff; }
  .otpLink-form .submit {
    width: 150px;
    height: 35px;
    border-radius: 25px;
    color: white;
    background-color: #001061;
    border: 0px;
    margin-top: 20px; }
  .otpLink-form span {
    padding: 10px; }

.share-excel .input-email {
  width: 100%;
  margin-top: 3rem; }

.container .heading {
  border-bottom: 1px solid #e0e0e0; }
  .container .heading.div {
    display: flex;
    justify-content: space-between; }
    .container .heading.div .rightside_content {
      display: flex;
      justify-content: space-between;
      width: 16%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #1d1d1d; }

.container .circle {
  position: relative; }
  .container .circle .icons {
    position: absolute;
    left: 3px;
    size: 30;
    color: #29a9e0;
    cursor: pointer; }

.container .label_heading {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  color: #555555;
  padding-bottom: 2%; }

.container .value {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #555555; }
  .container .value.cibilTrigger_Success {
    margin-top: 10px;
    text-align: right;
    width: 150px;
    font-weight: 700 !important; }

.container .col-md-6.col-6 .col-md-6.labelBox {
  margin-top: 5%; }

.container .col-md-6.col-6 .row.agentDetail_contentBox .name_box {
  margin-top: 2%; }

.container .Details_div {
  display: flex;
  justify-content: space-between; }
  .container .Details_div .btn_div {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    margin-right: -2rem; }
    .container .Details_div .btn_div .editImage {
      margin-top: 5%;
      fill-opacity: 1;
      height: 21px; }
    .container .Details_div .btn_div .otp-sent {
      letter-spacing: 0.02em;
      font-weight: bold !important;
      font-size: 14px;
      line-height: 12px;
      color: #001061; }

.delete_box {
  width: 500px;
  margin: 200px; }
  .delete_box .deleteStatement {
    margin-top: 20px; }
  .delete_box .btn_delete {
    margin-top: 20px; }

.new-bank-account__wrapper {
  width: 100%; }
  .new-bank-account__wrapper .fields .label-heading {
    font-weight: bold;
    font-size: 16px;
    color: #323232; }

.cpo-documents-wrapper .cpo-content-box {
  width: 100% !important; }
  .cpo-documents-wrapper .cpo-content-box .cpo-box-heading {
    text-align: left;
    border-bottom: 1px solid #E0E0E0;
    color: #000;
    font-size: 2.2rem; }
    .cpo-documents-wrapper .cpo-content-box .cpo-box-heading span {
      font-size: 1.2rem;
      text-align: center;
      color: black;
      cursor: pointer; }
      .cpo-documents-wrapper .cpo-content-box .cpo-box-heading span img {
        margin-right: 0.7rem; }

.cpo-gst-wrapper .cpo-content-box {
  width: 100% !important; }
  .cpo-gst-wrapper .cpo-content-box .heading {
    text-align: left;
    color: #000;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 1rem;
    font-weight: 700; }

.co-applicant-wrapper .heading {
  border-bottom: 1px solid #BCBCBC;
  color: #000;
  font-weight: 700; }

.co-applicant-wrapper .input-box .input {
  margin-top: 3rem;
  width: 30%; }

.co-applicant-wrapper .input-drop-down {
  margin-top: 3rem; }

.co-applicant-wrapper .select-box_main {
  border: 1px solid #C1C1C1;
  border-radius: 7px;
  letter-spacing: normal;
  padding: 11px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 4.7rem;
  width: 100%; }

.co-applicant-wrapper .co-applicant-button {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem; }

.select-box_main {
  border: 1px solid #4687F4;
  border-radius: 7px;
  letter-spacing: normal;
  padding: 11px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 4.7rem;
  width: 100%; }

.gstn-details__wrappers .content-box {
  width: 750px;
  margin: 0 auto;
  padding: 35px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: relative; }
  @media screen and (max-width: 768px) {
    .gstn-details__wrappers .content-box {
      width: 95%;
      padding: 15px; } }
  .gstn-details__wrappers .content-box .back-btn-link {
    display: flex;
    align-items: center;
    position: absolute;
    left: 25px;
    top: 40px;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.14;
    color: #29a9e0;
    cursor: pointer; }
    .gstn-details__wrappers .content-box .back-btn-link svg {
      margin-top: -3px;
      height: 21px; }
  .gstn-details__wrappers .content-box .box-heading {
    font-family: Helvetica;
    font-size: 20px;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #001061;
    margin-top: 0;
    margin-bottom: 25px; }
    @media screen and (max-width: 768px) {
      .gstn-details__wrappers .content-box .box-heading {
        margin-top: 60px; } }
  .gstn-details__wrappers .content-box .card__wrapper {
    padding: 10px;
    margin-top: 10px;
    position: relative; }
    .gstn-details__wrappers .content-box .card__wrapper h5 {
      color: #000;
      font-size: 16px;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px; }
    .gstn-details__wrappers .content-box .card__wrapper .button__generic {
      background-color: #4687F4;
      color: #fff;
      padding: 5px 10px;
      border-radius: 100px;
      border: none;
      position: absolute;
      top: 10px;
      right: 10px; }
    .gstn-details__wrappers .content-box .card__wrapper .input-field {
      width: 300px; }
    .gstn-details__wrappers .content-box .card__wrapper .label {
      color: #1d1d1d;
      font-weight: 700 !important;
      font-size: 14px;
      padding: 0; }
    .gstn-details__wrappers .content-box .card__wrapper .value {
      font-size: 12px; }
    .gstn-details__wrappers .content-box .card__wrapper .gst-details {
      display: flex;
      justify-content: space-around; }
      .gstn-details__wrappers .content-box .card__wrapper .gst-details .gst-delete__button {
        color: #fff;
        background-color: #fe5a5a;
        border-radius: 100px;
        text-align: center;
        border: none;
        max-width: 15%; }
    .gstn-details__wrappers .content-box .card__wrapper .notification__section {
      max-width: 80%;
      margin: 20px auto;
      padding: 20px;
      background-color: #f4fcff;
      border-radius: 10px;
      position: relative;
      font-size: 14px; }
      .gstn-details__wrappers .content-box .card__wrapper .notification__section .left-part {
        position: absolute;
        top: 20px;
        left: 20px;
        color: #001061;
        font-weight: 600 !important; }
      .gstn-details__wrappers .content-box .card__wrapper .notification__section .right-part {
        margin-left: 40px; }
        .gstn-details__wrappers .content-box .card__wrapper .notification__section .right-part p {
          margin: 0; }
    .gstn-details__wrappers .content-box .card__wrapper .action-button {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 20px 0; }
      .gstn-details__wrappers .content-box .card__wrapper .action-button .button {
        border-radius: 100px;
        padding: 5px 10px;
        border: none; }
        .gstn-details__wrappers .content-box .card__wrapper .action-button .button__blue {
          background-color: #001061;
          color: #fff; }
        .gstn-details__wrappers .content-box .card__wrapper .action-button .button__transparent {
          border: 1px solid #001061;
          color: #1d1d1d;
          margin-left: 10px;
          background-color: #fff; }
      .gstn-details__wrappers .content-box .card__wrapper .action-button .resend__link {
        font-size: 12px;
        text-decoration: underline;
        color: #29a9e0;
        cursor: pointer; }
    .gstn-details__wrappers .content-box .card__wrapper .input__container {
      display: flex; }
      .gstn-details__wrappers .content-box .card__wrapper .input__container .input-field {
        width: 250px;
        margin-right: 20px; }
    .gstn-details__wrappers .content-box .card__wrapper .remove-icon {
      color: #ff0000;
      cursor: pointer;
      position: absolute;
      bottom: 10px;
      right: 10px; }
      @media screen and (max-width: 768px) {
        .gstn-details__wrappers .content-box .card__wrapper .remove-icon {
          top: 10px;
          right: 10px; } }
      .gstn-details__wrappers .content-box .card__wrapper .remove-icon:hover {
        -webkit-transform-origin: top center;
        transform-origin: top center;
        -webkit-animation-name: swing;
        animation-name: swing;
        animation-duration: 0.5s; }
    @media screen and (max-width: 768px) {
      .gstn-details__wrappers .content-box .card__wrapper .gst-validate__wrapper [class^='col-md'] {
        padding: 0;
        margin: 20px 0px 0px 0px; } }
    .gstn-details__wrappers .content-box .card__wrapper .gst-validate__wrapper .username-input .input-field {
      width: 100%; }
    .gstn-details__wrappers .content-box .card__wrapper .gst-validate__wrapper .w-60 {
      width: 70%; }
    .gstn-details__wrappers .content-box .card__wrapper .success-message {
      color: #001061;
      font-size: 14px; }
      @media screen and (max-width: 768px) {
        .gstn-details__wrappers .content-box .card__wrapper .success-message {
          display: none; } }
      .gstn-details__wrappers .content-box .card__wrapper .success-message-m {
        display: none; }
        @media screen and (max-width: 768px) {
          .gstn-details__wrappers .content-box .card__wrapper .success-message-m {
            display: block; } }
        .gstn-details__wrappers .content-box .card__wrapper .success-message-m svg {
          color: #4687F4; }
      .gstn-details__wrappers .content-box .card__wrapper .success-message svg {
        color: #4687F4; }
  .gstn-details__wrappers .content-box .save-btn {
    width: 225px;
    border-radius: 25px;
    background-color: #4687F4;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin-top: 10px;
    justify-content: center; }
  .gstn-details__wrappers .content-box .d-flex {
    display: flex; }
    .gstn-details__wrappers .content-box .d-flex > * {
      flex: 1;
      margin-right: 10px; }
  .gstn-details__wrappers .content-box .button__generic-blue {
    background-color: #4687F4;
    color: #fff;
    padding: 7px 10px;
    border-radius: 100px;
    border: none;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 10px; }
    @media screen and (max-width: 768px) {
      .gstn-details__wrappers .content-box .button__generic-blue {
        width: 100%;
        margin-left: 0px; } }
  .gstn-details__wrappers .content-box .w-50 {
    max-width: 40%;
    margin: 100px 0; }

.share-otp-modal__wrapper .btns-box {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center; }
  .share-otp-modal__wrapper .btns-box button {
    width: 119px;
    font-family: Helvetica;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    padding: 6px; }
    .share-otp-modal__wrapper .btns-box button.confirm-btn {
      color: #fff;
      background-color: #4687f4; }

.row {
  margin: 0px 0px; }

.validateOTPWrapper {
  margin: 6% auto 3% auto;
  width: 750px;
  justify-content: center; }
  .validateOTPWrapper .title {
    color: #001061;
    font-size: 3rem;
    line-height: 2%; }
  .validateOTPWrapper p {
    text-align: center;
    color: #1d1d1d; }

.cardWrapper {
  width: 750px;
  margin: 0 auto;
  padding: 35px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  margin-top: 2%; }
  .cardWrapper .btn {
    border-radius: 25px;
    background-color: #4687f4;
    font-family: Helvetica;
    font-size: 18px;
    line-height: 1.19;
    letter-spacing: 0;
    color: #ffffff;
    border: none;
    padding: 5px 20px;
    display: block;
    margin-top: 2%; }

.overview-applicant-modal.otpLink-modal {
  font-weight: bold !important;
  /* The switch - the box around the slider */
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */ }
  .overview-applicant-modal.otpLink-modal h3 {
    font-size: 24px;
    text-align: center;
    border-bottom: 0px;
    font-family: Helvetica;
    font-weight: bold;
    margin-bottom: 20px;
    font-weight: bold; }
  .overview-applicant-modal.otpLink-modal label {
    margin: 0px;
    padding: 0px; }
  .overview-applicant-modal.otpLink-modal .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px; }
  .overview-applicant-modal.otpLink-modal .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .overview-applicant-modal.otpLink-modal .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .overview-applicant-modal.otpLink-modal .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .overview-applicant-modal.otpLink-modal input:checked + .slider {
    background-color: #2196f3; }
  .overview-applicant-modal.otpLink-modal input:focus + .slider {
    box-shadow: 0 0 1px #2196f3; }
  .overview-applicant-modal.otpLink-modal input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px); }
  .overview-applicant-modal.otpLink-modal .slider.round {
    border-radius: 34px; }
  .overview-applicant-modal.otpLink-modal .slider.round:before {
    border-radius: 50%; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.boldHeading {
  font-weight: 700 !important;
  font-size: 1.5rem;
  margin-bottom: 1%; }

.subheading {
  font-size: 1.5rem; }

@media only screen and (max-width: 480px) {
  body {
    overflow-y: hidden; }
  .validateOTPWrapper {
    width: 100%;
    margin-top: 25px;
    padding: 15px; }
    .validateOTPWrapper .title {
      line-height: 1; }
    .validateOTPWrapper p {
      text-align: start;
      display: inline-block;
      margin-top: 10px; }
    .validateOTPWrapper br:nth-child(1) {
      display: none; }
  .cardWrapper {
    max-width: 90%;
    min-height: 50vh; }
    .cardWrapper .btn {
      border-radius: 5px !important;
      background-color: #4687f4;
      font-family: Helvetica;
      font-size: 18px;
      line-height: 1.19;
      color: #ffffff;
      border: none;
      padding: 10px 20px !important;
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 30px; } }

.business-account__wrapper {
  width: 60%;
  margin: 0 auto; }
  .business-account__wrapper .container {
    margin: 3% auto;
    padding: 2% 4%;
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .business-account__wrapper .container__heading {
      color: #001061;
      text-align: center;
      font-size: 2em;
      margin-top: 14px; }
    .business-account__wrapper .container .p-top--sub {
      padding: 0% 3% 2%; }
    .business-account__wrapper .container .m-top--main {
      margin: 1.5% 0%; }
    .business-account__wrapper .container .p-left {
      padding-left: 0px; }
    .business-account__wrapper .container .select-box {
      color: black;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      padding: 11px 10px;
      height: 47px;
      width: 100%; }
    .business-account__wrapper .container select option:first-child {
      color: #001061;
      font-family: Helvetica Bold;
      background-color: #eee;
      padding: 10px 12px; }
    .business-account__wrapper .container select option {
      margin: 40px;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      width: 100%;
      color: #565656; }
    .business-account__wrapper .container .sub-heading {
      color: #4687f4;
      padding-bottom: 10px;
      font-size: 2.4rem;
      margin-top: 14px;
      margin-bottom: 0px; }
    .business-account__wrapper .container .sub-qsn {
      margin: 6px auto;
      font-size: 1.6rem;
      margin-left: 2.8%; }
    .business-account__wrapper .container .sub-since {
      font-size: 1.5rem; }
    .business-account__wrapper .container .color-decor {
      color: #001061;
      font-size: 22px;
      margin-bottom: 0px; }
    .business-account__wrapper .container .btn-normal {
      border-radius: 8px;
      padding: 1.5% 3%;
      display: inline-block;
      margin-top: 10px;
      margin-left: 2.8%;
      font-size: 1.6rem;
      color: #565656;
      cursor: pointer;
      border: 1px solid #c8c8c8;
      background-color: #ffffff; }
    .business-account__wrapper .container .btn-active {
      border-radius: 8px;
      padding: 1.5% 3%;
      display: inline-block;
      margin-top: 10px;
      margin-left: 2.8%;
      font-size: 1.6rem;
      color: #565656;
      cursor: pointer;
      border: 1px solid #4687f4;
      background-color: rgba(0, 205, 190, 0.05); }
    .business-account__wrapper .container .back-btn-link {
      font-family: Helvetica;
      font-size: 1.6rem;
      color: #29a9e0;
      cursor: pointer;
      position: absolute;
      left: 1%;
      top: 2%; }
    .business-account__wrapper .container .remove-icon {
      color: #ff0000;
      cursor: pointer;
      display: inline-block;
      margin-left: 21px;
      margin-top: 15px; }
      .business-account__wrapper .container .remove-icon:hover {
        -webkit-transform-origin: top center;
        transform-origin: top center;
        -webkit-animation-name: swing;
        animation-name: swing;
        animation-duration: 0.5s; }
    .business-account__wrapper .container .flex-box {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dddddd; }
    .business-account__wrapper .container .flex-box-basic {
      display: flex;
      justify-content: space-between; }
    .business-account__wrapper .container .sub-heading-wrap {
      border-bottom: 1px solid #dddddd;
      font-size: 2.4rem;
      margin-top: 0px; }
    .business-account__wrapper .container .sub-heading_basic {
      font-size: 2.4rem;
      margin-top: 11px;
      color: #4687f4; }
    .business-account__wrapper .container .btn-add {
      color: #ffffff;
      border-radius: 25px;
      padding: 0.7% 2%;
      background-color: #4687f4;
      margin: 1.4%;
      cursor: pointer;
      display: inline-block;
      font-size: 1.5rem; }
    .business-account__wrapper .container .btn-box {
      text-align: center;
      margin: 2%;
      font-size: 1.6rem; }
      .business-account__wrapper .container .btn-box .btn-draft {
        color: #565656;
        border: 1px solid #29a9e0;
        border-radius: 25px;
        padding: 1.3% 4.5%;
        margin: 1%;
        cursor: pointer;
        display: inline-block; }
      .business-account__wrapper .container .btn-box .btn-save {
        color: #ffffff;
        border-radius: 25px;
        padding: 1.5% 3%;
        background-color: #29a9e0;
        margin: 1%;
        cursor: pointer;
        display: inline-block;
        border: none; }

@media screen and (max-width: 1153px) {
  .business-account__wrapper {
    width: 90%; } }

@media screen and (max-width: 500px) {
  .business-account__wrapper {
    width: 95%; }
    .business-account__wrapper .container__heading {
      font-size: 24px;
      padding: 20px 10px !important; }
    .business-account__wrapper .container {
      padding: 20px 10px !important; }
      .business-account__wrapper .container .back-btn-link {
        left: 1%;
        top: 0.7%; }
      .business-account__wrapper .container .sub-heading {
        font-size: 2.2rem; }
      .business-account__wrapper .container .color-decor {
        font-size: 21px; }
      .business-account__wrapper .container .flex-box {
        margin: 10px 0; }
      .business-account__wrapper .container .btn-add {
        margin: 3% 0; }
      .business-account__wrapper .container [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-top: 10px !important; }
      .business-account__wrapper .container .sub-heading-wrap .flex-box {
        display: block; } }

.business-cost {
  width: 60%;
  margin: 0 auto; }
  .business-cost .input-box {
    margin-top: 0px; }
  .business-cost .container {
    margin: 3% auto;
    padding: 2% 4%;
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .business-cost .container__heading {
      color: #001061;
      text-align: center;
      font-size: 2em;
      margin-top: 14px;
      margin-bottom: 25px; }
    .business-cost .container .sub-heading {
      color: #4687f4;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
      font-size: 2.4rem;
      margin-top: 14px; }
    .business-cost .container .m-top--main {
      margin: 1% 0; }
    .business-cost .container .sub-qsn {
      margin: 3% 0 1%;
      color: #292929;
      font-size: 1.6rem; }
    .business-cost .container .back-btn-link {
      font-family: Helvetica;
      font-size: 1.6rem;
      color: #29a9e0;
      cursor: pointer;
      position: absolute;
      left: 1%;
      top: 2%; }
    .business-cost .container .p-left {
      padding-left: 0px; }
    .business-cost .container .text-center {
      text-align: center; }
      .business-cost .container .text-center .btn-save {
        font-size: 1.6rem;
        color: #ffffff;
        border: none;
        display: inline-block;
        border-radius: 25px;
        padding: 1% 6%;
        margin-top: 3%;
        background-color: #29a9e0;
        cursor: pointer; }

@media screen and (max-width: 900px) {
  .business-cost {
    width: 90%; } }

@media screen and (max-width: 500px) {
  .business-cost {
    width: 95%; }
    .business-cost .container__heading {
      font-size: 24px; }
    .business-cost .container {
      padding: 20px 10px !important; }
      .business-cost .container .sub-heading {
        font-size: 2.2rem; }
      .business-cost .container .back-btn-link {
        left: 1%;
        top: 0.7%; }
    .business-cost [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-top: 10px !important; } }

.business-details__wrapper {
  width: 55%;
  margin: 0 auto; }
  .business-details__wrapper .react-datepicker-popper {
    top: -212px !important;
    left: -4px !important; }
  .business-details__wrapper .date-picker {
    pointer-events: none; }
  .business-details__wrapper .react-datepicker-popper[data-placement^="bottom"]
.react-datepicker__triangle {
    bottom: 0 !important;
    margin-bottom: -8px; }
  .business-details__wrapper .remove-icon {
    color: #ff0000;
    cursor: pointer;
    display: inline-block;
    margin-left: 21px;
    margin-top: 15px; }
    .business-details__wrapper .remove-icon:hover {
      -webkit-transform-origin: top center;
      transform-origin: top center;
      -webkit-animation-name: swing;
      animation-name: swing;
      animation-duration: .5s; }
  .business-details__wrapper .container {
    margin: 3% auto;
    padding: 2% 4%;
    width: 100%;
    position: relative;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .business-details__wrapper .container__heading {
      color: #001061;
      text-align: center;
      font-size: 2em;
      margin-top: 14px; }
    .business-details__wrapper .container .p-top--sub {
      padding: 2%; }
    .business-details__wrapper .container .m-top--main {
      margin: 1% 0%; }
    .business-details__wrapper .container .sub-heading {
      color: #4687f4;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
      font-size: 2.4rem;
      margin-top: 0px; }
    .business-details__wrapper .container .sub-heading-wrap {
      color: #4687f4;
      border-bottom: 1px solid #dddddd;
      font-size: 2.4rem;
      margin-top: 0px; }
    .business-details__wrapper .container .sub-heading_basic {
      font-size: 2.4rem;
      margin-top: 11px; }
    .business-details__wrapper .container .p-left {
      padding-left: 0px;
      padding-right: 15px; }
    .business-details__wrapper .container .select-box {
      color: black;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      padding: 11px 10px;
      height: 47px;
      width: 100%; }
    .business-details__wrapper .container select option:first-child {
      color: #001061;
      font-family: Helvetica Bold;
      background-color: #eee;
      padding: 10px 12px; }
    .business-details__wrapper .container select option {
      margin: 40px;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      width: 100%;
      color: #565656; }
    .business-details__wrapper .container .text-area {
      background-color: #fafafa;
      border: none;
      outline: none;
      width: 100%;
      padding: 1%;
      margin-top: 2%; }
      .business-details__wrapper .container .text-area input::placeholder {
        color: #bababa; }
    .business-details__wrapper .container .delete-container {
      display: flex;
      justify-content: space-between; }
      .business-details__wrapper .container .delete-container .address {
        color: #001061;
        font-size: 19px;
        margin-top: 10px;
        margin-bottom: 4px;
        display: inline-block; }
    .business-details__wrapper .container .flex-box {
      display: flex;
      justify-content: space-between; }
    .business-details__wrapper .container .btn-add {
      color: #ffffff;
      border-radius: 25px;
      padding: 0.7% 2%;
      background-color: #4687f4;
      margin: 1.4%;
      cursor: pointer;
      display: inline-block;
      font-size: 1.5rem; }
    .business-details__wrapper .container .btn-normal {
      border-radius: 8px;
      padding: 5% 0%;
      display: inline-block;
      margin-top: 3%;
      margin-bottom: 3%;
      color: #565656;
      width: 95%;
      text-align: center;
      font-size: 1.4rem;
      border: 1px solid #c8c8c8;
      background-color: #ffffff;
      cursor: pointer; }
    .business-details__wrapper .container .btn-active {
      border-radius: 8px;
      padding: 5% 0%;
      display: inline-block;
      margin-top: 3%;
      margin-bottom: 3%;
      color: #565656;
      width: 95%;
      text-align: center;
      font-size: 1.4rem;
      border: 1px solid #4687f4;
      background-color: rgba(0, 205, 190, 0.05);
      cursor: pointer; }
    .business-details__wrapper .container .back-btn-link {
      font-family: Helvetica;
      font-size: 1.6rem;
      color: #29a9e0;
      cursor: pointer;
      position: absolute;
      left: 1%;
      top: 1%; }
    .business-details__wrapper .container .btn-box {
      text-align: center;
      margin: 2%;
      font-size: 1.6rem; }
      .business-details__wrapper .container .btn-box .btn-draft {
        color: #565656;
        border: 1px solid #29a9e0;
        border-radius: 25px;
        padding: 1.5% 4%;
        margin: 1%;
        cursor: pointer;
        display: inline-block; }
      .business-details__wrapper .container .btn-box .btn-save {
        color: #ffffff;
        border-radius: 25px;
        padding: 1.5% 4%;
        margin: 1%;
        background-color: #29a9e0;
        cursor: pointer;
        display: inline-block; }

@media screen and (max-width: 1320px) {
  .business-details__wrapper {
    width: 80%; } }

@media screen and (max-width: 500px) {
  .business-details__wrapper {
    width: 95%; }
    .business-details__wrapper .container__heading {
      font-size: 24px; }
    .business-details__wrapper .container {
      padding: 20px 10px !important; }
      .business-details__wrapper .container .btn-normal,
      .business-details__wrapper .container .btn-active {
        margin-top: 0%;
        margin-bottom: 0%;
        padding: 3% 0%; }
      .business-details__wrapper .container .back-btn-link {
        left: 1%;
        top: 0.2%; }
      .business-details__wrapper .container .sub-heading {
        font-size: 2.2rem; }
      .business-details__wrapper .container .sub-heading-wrap .flex-box {
        display: block; }
    .business-details__wrapper [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-top: 10px !important; } }

.business-size {
  width: 60%;
  margin: 0 auto; }
  .business-size .container {
    margin: 3% auto;
    padding: 2% 4%;
    position: relative;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .business-size .container__heading {
      color: #001061;
      text-align: center;
      font-size: 2em;
      margin-top: 14px; }
    .business-size .container .text-decor {
      font-size: 1.6rem;
      padding: 2% 0% 1% 0%; }
    .business-size .container .text-decor-sub {
      font-size: 1.6rem;
      padding: 4% 0% 1% 0%; }
    .business-size .container .p-top--sub {
      padding: 1.5% 3%; }
    .business-size .container .m-top--main {
      margin: 1% 0% 3%; }
    .business-size .container .m-top--sub {
      margin: 1% 0% 1.8%; }
    .business-size .container .m-extra {
      margin: 1% 0% 3%; }
    .business-size .container .p-left {
      padding-left: 0px; }
    .business-size .container .sub-heading {
      color: #4687f4;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
      font-size: 2.4rem;
      margin-top: 14px; }
    .business-size .container .flex-box-basic {
      display: flex;
      justify-content: space-between; }
    .business-size .container .color-decor {
      color: #001061;
      font-size: 22px;
      margin-bottom: 0px; }
    .business-size .container .remove-icon {
      color: #ff0000;
      cursor: pointer;
      display: inline-block;
      margin-left: 21px;
      margin-top: 15px; }
      .business-size .container .remove-icon:hover {
        -webkit-transform-origin: top center;
        transform-origin: top center;
        -webkit-animation-name: swing;
        animation-name: swing;
        animation-duration: 0.5s; }
    .business-size .container .select-box {
      color: black;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      padding: 11px 10px;
      height: 47px;
      width: 100%; }
    .business-size .container select option:first-child {
      color: #001061;
      font-family: Helvetica Bold;
      background-color: #eee;
      padding: 10px 12px; }
    .business-size .container select option {
      margin: 40px;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      width: 100%;
      color: #565656; }
    .business-size .container .sub-heading_basic {
      font-size: 2.4rem;
      margin-top: 20px;
      margin-bottom: 0px; }
    .business-size .container .flex-box {
      display: flex;
      justify-content: space-between; }
    .business-size .container .back-btn-link {
      font-family: Helvetica;
      font-size: 1.6rem;
      color: #29a9e0;
      cursor: pointer;
      position: absolute;
      left: 1%;
      top: 1%; }
    .business-size .container .btn-normal {
      border-radius: 8px;
      padding: 1.5% 2%;
      display: inline-block;
      margin-right: 11px;
      margin-bottom: 14px;
      color: #565656;
      display: inline-block;
      text-align: center;
      font-size: 1.6rem;
      border: 1px solid #c8c8c8;
      background-color: #ffffff;
      cursor: pointer; }
    .business-size .container .btn-active {
      cursor: pointer;
      border-radius: 8px;
      padding: 1.5% 2%;
      display: inline-block;
      margin-right: 11px;
      margin-bottom: 14px;
      color: #565656;
      text-align: center;
      font-size: 1.6rem;
      border: 1px solid #4687f4;
      background-color: rgba(0, 205, 190, 0.05); }
    .business-size .container .btn-decor {
      width: 32%;
      float: right;
      margin-top: 5%; }
    .business-size .container .btn-add {
      color: #ffffff;
      border-radius: 25px;
      padding: 0.3% 4%;
      background-color: #4687f4;
      margin-top: 2.5%;
      cursor: pointer;
      display: inline-block;
      font-size: 1.7rem; }
    .business-size .container .btn-box {
      text-align: center;
      margin: 6%;
      font-size: 1.6rem; }
      .business-size .container .btn-box .btn-draft {
        color: #565656;
        border: 1px solid #29a9e0;
        border-radius: 25px;
        padding: 1.2% 7%;
        margin: 1%;
        cursor: pointer;
        display: inline-block; }
      .business-size .container .btn-box .btn-save {
        color: #ffffff;
        border-radius: 25px;
        padding: 1.4% 6%;
        background-color: #29a9e0;
        margin: 1%;
        cursor: pointer;
        display: inline-block; }
    .business-size .container .btn-small {
      padding: 5px 25px; }

@media screen and (max-width: 1182px) {
  .business-size {
    width: 85%; } }

@media screen and (max-width: 500px) {
  .business-size {
    width: 95%; }
    .business-size .container__heading {
      font-size: 24px; }
    .business-size .container {
      padding: 20px 10px !important; }
      .business-size .container .sub-heading {
        font-size: 2.2rem; }
        .business-size .container .sub-heading .flex-box {
          display: block; }
      .business-size .container .back-btn-link {
        left: 1%;
        top: 0.2%; }
      .business-size .container .btn-add {
        margin: 3% 0; }
      .business-size .container .btn-decor {
        float: left; }
      .business-size .container [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-top: 10px !important; } }

.onBoarding-wrapper {
  width: 60%;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 2%; }
  .onBoarding-wrapper .checkbox-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    .onBoarding-wrapper .checkbox-container__text {
      font-size: 12px; }
      .onBoarding-wrapper .checkbox-container__text span {
        color: #29a9e0;
        cursor: pointer; }
  .onBoarding-wrapper .date-picker {
    pointer-events: none; }
  .onBoarding-wrapper .react-datepicker-popper {
    top: -212px !important;
    left: -4px !important; }
  .onBoarding-wrapper .react-datepicker-popper[data-placement^="bottom"]
.react-datepicker__triangle {
    bottom: 0;
    margin-bottom: -8px; }
  .onBoarding-wrapper .red-flag__wrapper {
    border-radius: 10px; }
    .onBoarding-wrapper .red-flag__wrapper .heading {
      font-family: Helvetica;
      font-size: 22px;
      color: #00cdbe;
      padding-bottom: 1rem; }
  .onBoarding-wrapper .container {
    margin: 3% auto;
    padding: 2% 4%;
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .onBoarding-wrapper .container__heading {
      color: #001061;
      text-align: center;
      font-size: 2em;
      margin-top: 14px;
      margin-bottom: 21px; }
    .onBoarding-wrapper .container .font-bold {
      font-weight: bold !important;
      display: block; }
    .onBoarding-wrapper .container .container-sub {
      margin: 0 auto; }
    .onBoarding-wrapper .container .gst-fetch {
      float: right; }
    .onBoarding-wrapper .container .sub-heading {
      color: #4687f4;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
      font-size: 2.4rem;
      margin-top: 0px; }
    .onBoarding-wrapper .container .p-top--sub {
      padding: 2.5%; }
    .onBoarding-wrapper .container .m-top--main {
      margin: 1% 0; }
    .onBoarding-wrapper .container .text-decor {
      font-size: 1.5rem; }
    .onBoarding-wrapper .container .text-align {
      font-size: 1.5rem;
      padding: 0% 0% 0% 0.5%; }
    .onBoarding-wrapper .container .p-left {
      padding-left: 0px;
      padding-right: 15px; }
    .onBoarding-wrapper .container .red {
      color: red; }
    .onBoarding-wrapper .container .back-btn-link {
      font-family: Helvetica;
      font-size: 1.6rem;
      color: #29a9e0;
      cursor: pointer;
      position: absolute;
      left: 1%;
      top: 2%; }
    .onBoarding-wrapper .container .select-box {
      color: black;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      padding: 11px 10px;
      height: 47px;
      width: 100%; }
    .onBoarding-wrapper .container select option:first-child {
      color: #001061;
      font-family: Helvetica Bold;
      background-color: #eee;
      padding: 10px 12px; }
    .onBoarding-wrapper .container select option {
      margin: 40px;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      width: 100%;
      color: #565656; }
    .onBoarding-wrapper .container .text-center {
      text-align: center; }
      .onBoarding-wrapper .container .text-center .btn {
        font-size: 1.6rem;
        color: #ffffff;
        border: none;
        display: inline-block;
        border-radius: 25px;
        padding: 1% 6%;
        margin-top: 2%; }
      .onBoarding-wrapper .container .text-center .btn-save {
        background-color: #29a9e0;
        cursor: pointer; }
      .onBoarding-wrapper .container .text-center .btn-disabled {
        background-color: #bcbcbc; }
    .onBoarding-wrapper .container .cluster-details {
      padding: 1.8%; }
    .onBoarding-wrapper .container .gst-registration {
      margin-top: 3%;
      padding: 1.8%; }
      .onBoarding-wrapper .container .gst-registration .btn {
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 1.7% 6%;
        background-color: #00cdbe;
        font-size: 1.5rem;
        margin-top: 2%;
        outline: none; }
      .onBoarding-wrapper .container .gst-registration .flex-box {
        display: flex;
        justify-content: space-between; }
      .onBoarding-wrapper .container .gst-registration .btn-decor {
        float: right; }
      .onBoarding-wrapper .container .gst-registration .bold {
        font-weight: bold; }
      .onBoarding-wrapper .container .gst-registration .success-message {
        color: #00cdbe;
        text-align: right; }
      .onBoarding-wrapper .container .gst-registration input[type="text"] {
        text-transform: uppercase; }
    .onBoarding-wrapper .container .business-registration {
      margin-top: 3%;
      padding: 1.8%; }

.otp-wrapper .resend-otp {
  margin-top: 10%; }

.otp-wrapper #otp {
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  border: none;
  width: 9ch;
  font-size: 14px;
  align-content: center;
  background: -webkit-repeating-linear-gradient(left, #696969, #696969 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat;
  background: repeating-linear-gradient(90deg, #696969, #696969 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat;
  color: #1d1d1d;
  font: 5ch consolas, monospace;
  margin: 5% 0; }

.otp-wrapper .otp-heading {
  color: #4687f4;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 2%;
  font-size: 2rem; }

.otp-wrapper .otp-edit {
  color: #00cdbe;
  margin-left: 10%;
  cursor: pointer;
  display: inline; }

.otp-wrapper .otp-resend {
  float: right;
  padding-top: 19px; }

.otp-wrapper .otp-num {
  margin-right: 4px;
  font-weight: bold !important; }

.otp-wrapper .otp-button {
  font-size: 1.4rem;
  color: #ffffff;
  border: none;
  display: inline-block;
  border-radius: 25px;
  padding: 2% 9%;
  margin-top: 9%;
  background-color: #00cdbe;
  cursor: pointer;
  text-align: center; }

@media screen and (max-width: 1044px) {
  body {
    overflow-y: scroll; }
  .onBoarding-wrapper {
    width: 80%; } }

@media screen and (max-width: 500px) {
  .onBoarding-wrapper {
    width: 95%; }
    .onBoarding-wrapper .container__heading {
      font-size: 24px; }
    .onBoarding-wrapper .container {
      padding: 20px 10px !important; }
      .onBoarding-wrapper .container .back-btn-link {
        left: 1%;
        top: 0.7%; }
      .onBoarding-wrapper .container .sub-heading {
        font-size: 2.2rem; }
    .onBoarding-wrapper [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-top: 10px !important; } }

.photo-wrapper {
  padding: 6% 0;
  width: 60%;
  margin: 0 auto; }
  .photo-wrapper .container {
    margin: 3% auto;
    padding: 2% 4%;
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .photo-wrapper .container__heading {
      color: #001061;
      text-align: center;
      font-size: 2em;
      margin-top: 14px; }
    .photo-wrapper .container .p-left {
      padding-left: 0px; }
    .photo-wrapper .container .back-btn-link {
      font-family: Helvetica;
      font-size: 1.6rem;
      color: #29a9e0;
      cursor: pointer;
      position: absolute;
      left: 1%;
      top: 2%; }
    .photo-wrapper .container .dropdown button {
      border: 1px solid #bcbcbc; }
    .photo-wrapper .container .dropdown .select-box {
      margin: 3% auto !important;
      font-family: Helvetica;
      border-radius: 0px;
      padding: 17px 10px;
      line-height: 1.34;
      text-align: left;
      font-weight: 400 !important;
      border: 1px solid #bcbcbc;
      box-sizing: border-box;
      border-radius: 5px;
      width: 85%; }
    .photo-wrapper .container .document-box {
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      margin-top: 7%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start; }
      .photo-wrapper .container .document-box .label1 {
        min-height: 50px;
        background-color: #eeeeee;
        border-radius: 10px 10px 0px 0px;
        width: 100%; }
        .photo-wrapper .container .document-box .label1 p {
          margin-left: 20px !important;
          font-size: 14px;
          color: #001061;
          vertical-align: middle;
          font-weight: 800 !important; }
      .photo-wrapper .container .document-box .select-box {
        color: #bcbcbc;
        border: 1px solid #eee;
        border-radius: 7px;
        font-size: 14px;
        font-family: Helvetica;
        padding: 11px 10px;
        height: 47px;
        width: 89%;
        margin: 3% 4% 0%;
        margin-top: 20px; }
      .photo-wrapper .container .document-box .upload-button {
        background-color: #4687f4;
        border-radius: 20px;
        padding: 6px;
        align-content: center;
        cursor: pointer;
        margin: 6% 5%;
        max-width: fit-content; }
      .photo-wrapper .container .document-box:last-child {
        margin-bottom: 0; }
      .photo-wrapper .container .document-box label {
        font-family: Helvetica;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0;
        text-align: left;
        color: #f5f5f5;
        display: flex;
        align-items: center; }
        .photo-wrapper .container .document-box label span {
          margin-right: 5px;
          margin-top: -3px; }
      .photo-wrapper .container .document-box > div {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        .photo-wrapper .container .document-box > div > * {
          margin: 0 10px; }
          .photo-wrapper .container .document-box > div > *:first-child {
            margin-left: 0; }
          .photo-wrapper .container .document-box > div > *:last-child {
            margin-right: 0; }
        .photo-wrapper .container .document-box > div .dropdown,
        .photo-wrapper .container .document-box > div .input-field {
          max-width: 265px; }
        .photo-wrapper .container .document-box > div .input-field {
          border: 1px solid #bcbcbc;
          border-radius: 7px;
          padding: 15px 12px;
          font-family: "Helvetica";
          font-size: 14px;
          line-height: 1.14;
          letter-spacing: normal; }
        .photo-wrapper .container .document-box > div input[type="file"] {
          display: none; }
        .photo-wrapper .container .document-box > div .upload-btn {
          width: 90px;
          padding: 9px 10px;
          border-radius: 25px;
          background-color: #4687f4;
          font-size: 14px;
          border: none;
          line-height: 1.19;
          letter-spacing: 0;
          text-align: center;
          color: #ffffff; }
        .photo-wrapper .container .document-box > div .actions-box {
          min-width: 25px;
          margin-right: 0; }
          .photo-wrapper .container .document-box > div .actions-box .check-icon {
            background-color: #8bd591;
            color: #fff;
            cursor: pointer;
            border-radius: 25px;
            width: 25px;
            height: 26px;
            display: inline-block;
            text-align: center;
            padding-top: 2px;
            opacity: 0; }
            .photo-wrapper .container .document-box > div .actions-box .check-icon.visible {
              opacity: 1; }
    .photo-wrapper .container .fileContainer {
      display: flex;
      flex-wrap: wrap;
      margin: 3% 4% 0%; }
      .photo-wrapper .container .fileContainer .file-wrapper {
        border: 1px solid #bcbcbc;
        border-radius: 20px;
        width: 80%;
        display: flex;
        justify-content: space-between;
        padding: 2% 4%; }
      .photo-wrapper .container .fileContainer .remove-icon {
        display: inline-block;
        cursor: pointer;
        height: 20px;
        color: red; }
      .photo-wrapper .container .fileContainer .file-text {
        font-size: 12px;
        margin: 0; }
    .photo-wrapper .container .documentWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    .photo-wrapper .container .m-top--main {
      margin: 3% 0; }
    .photo-wrapper .container .p-top--sub {
      padding: 2.5%; }
    .photo-wrapper .container .sub-heading {
      color: #4687f4;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
      font-size: 2.4rem;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-left: 16px; }
    .photo-wrapper .container .text-area {
      background-color: #fafafa;
      border: none;
      outline: none;
      width: 100%;
      padding: 1%;
      margin-top: 2%;
      border-radius: 8px; }
      .photo-wrapper .container .text-area input::placeholder {
        color: #bababa; }
    .photo-wrapper .container .btn-box {
      font-size: 16px;
      text-align: center;
      margin: 5% 0% 0% 3%; }
      .photo-wrapper .container .btn-box .btn-save {
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 10px 70px;
        background-color: #29a9e0;
        margin: 1%;
        cursor: pointer;
        display: inline-block; }
      .photo-wrapper .container .btn-box .btn_side-box {
        border: none;
        border-radius: 25px;
        padding: 10px 37px;
        cursor: pointer;
        background-color: #4687f4;
        color: #ffffff; }
      .photo-wrapper .container .btn-box .btn-draft {
        color: #565656;
        border: 1px solid #29a9e0;
        border-radius: 25px;
        padding: 10px 37px;
        margin: 1%;
        cursor: pointer;
        display: inline-block; }

.image__wrapper #image-place img {
  width: 100%;
  height: 100%; }

.image__wrapper .image-type__wrapper {
  color: #4687f4;
  padding: 4% 1%; }

.image__wrapper video {
  width: 100%;
  height: auto; }

.image__wrapper .image-capture__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5rem 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center; }
  .image__wrapper .image-capture__footer svg {
    fill: #fff;
    height: 15%;
    width: 15%; }

.image__wrapper .upload-image__wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5rem 0;
  background-color: #000;
  display: flex;
  justify-content: center; }
  .image__wrapper .upload-image__wrapper .delete-image {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translateY(-50%); }
    .image__wrapper .upload-image__wrapper .delete-image svg {
      fill: #fff;
      stroke: #ed5a5a; }
  .image__wrapper .upload-image__wrapper .upload-image svg {
    fill: #fff;
    stroke: #4687f4; }

@media only screen and (min-width: 769px) {
  .image__wrapper .image-type__wrapper {
    color: #4687f4;
    padding: 1%; }
  .image__wrapper .image-capture__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem 0;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center; }
    .image__wrapper .image-capture__footer svg {
      fill: #fff;
      height: 5%;
      width: 5%; } }

@media screen and (max-width: 1135px) {
  .photo-wrapper {
    width: 90%; } }

@media screen and (max-width: 774px) {
  .photo-wrapper .container .back-btn-link {
    left: 1%;
    top: 0.7%; } }

.can-toggle {
  position: relative; }
  .can-toggle *,
  .can-toggle *:before,
  .can-toggle *:after {
    box-sizing: border-box; }
  .can-toggle input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
    .can-toggle input[type="checkbox"][disabled] ~ label {
      pointer-events: none; }
      .can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
        opacity: 0.4; }
    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
      content: attr(data-unchecked);
      left: 0; }
    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      content: attr(data-checked); }
  .can-toggle label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center; }
    .can-toggle label .can-toggle__switch {
      position: relative; }
      .can-toggle label .can-toggle__switch:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center; }
      .can-toggle label .can-toggle__switch:after {
        content: attr(data-unchecked);
        position: absolute;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0); }
  .can-toggle input[type="checkbox"][disabled] ~ label {
    color: rgba(119, 119, 119, 0.5); }
  .can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #777; }
    .can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #5e5e5e; }
  .can-toggle input[type="checkbox"]:hover ~ label {
    color: #6a6a6a; }
  .can-toggle input[type="checkbox"]:checked ~ label:hover {
    color: #55bc49; }
  .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #00CDBE; }
    .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #4fb743; }
  .can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #5fc054; }
    .can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #47a43d; }
  .can-toggle label .can-toggle__switch {
    transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    background: #848484; }
    .can-toggle label .can-toggle__switch:before {
      color: rgba(255, 255, 255, 0.5); }
    .can-toggle label .can-toggle__switch:after {
      -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
      transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
      color: #777; }
  .can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
  .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4); }
  .can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(65px, 0, 0); }
  .can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4); }
  .can-toggle label {
    font-size: 14px; }
    .can-toggle label .can-toggle__switch {
      height: 36px;
      flex: 0 0 134px;
      border-radius: 4px; }
      .can-toggle label .can-toggle__switch:before {
        left: 67px;
        font-size: 12px;
        line-height: 36px;
        width: 67px;
        padding: 0 12px; }
      .can-toggle label .can-toggle__switch:after {
        top: 2px;
        left: 2px;
        border-radius: 2px;
        width: 65px;
        line-height: 32px;
        font-size: 12px; }
      .can-toggle label .can-toggle__switch:hover:after {
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4); }
  .can-toggle.demo-rebrand-1 input[type="checkbox"][disabled] ~ label {
    color: rgba(186, 186, 186, 0.5); }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch,
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #bababa; }
    .can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch:after,
    .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
      color: #a1a1a1; }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label {
    color: #adadad; }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label:hover {
    color: #387ef3; }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #00CDBE; }
    .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
      color: #2e77f3; }
  .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #4687f4; }
    .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
      color: #1668f1; }
  .can-toggle.demo-rebrand-1 label .can-toggle__switch {
    transition: background-color 0.3s ease-in-out;
    background: #c7c7c7; }
    .can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
      color: rgba(255, 255, 255, 0.6); }
    .can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      color: #bababa; }

.cluster-dashboard__wrapper {
  font-size: 1.5rem; }
  .cluster-dashboard__wrapper .navigation-bar {
    display: flex;
    justify-content: space-between;
    color: #fff;
    background-color: #001061;
    padding: 0px 20px; }
    .cluster-dashboard__wrapper .navigation-bar .d-flex {
      display: flex; }
      .cluster-dashboard__wrapper .navigation-bar .d-flex .nav {
        padding: 10px 15px;
        cursor: pointer; }
      .cluster-dashboard__wrapper .navigation-bar .d-flex .active {
        border-bottom: 5px solid #fff; }
    .cluster-dashboard__wrapper .navigation-bar .button__generic {
      color: #fff;
      background-color: #4687f4;
      border: none;
      border-radius: 100px;
      padding: 4px 20px;
      margin: 10px 0; }

@media screen and (max-width: 500px) {
  .navigation-bar {
    padding: 0 !important; } }

.cluster-preview-wrapper {
  background-color: #f8f8f8; }
  .cluster-preview-wrapper .content-wrapper {
    width: 60%;
    margin: 0 auto;
    padding: 25px 0; }
    .cluster-preview-wrapper .content-wrapper .box-wrapper {
      margin-bottom: 25px;
      border-radius: 12px;
      box-shadow: 0 9px 16px 0 rgba(0, 0, 0, 0.16); }
      .cluster-preview-wrapper .content-wrapper .box-wrapper.border-top {
        padding-top: 10px;
        background: linear-gradient(to right, #00cdbe, #3670cc); }
        .cluster-preview-wrapper .content-wrapper .box-wrapper.border-top .box-content {
          border-radius: 0 0 12px 12px; }
      .cluster-preview-wrapper .content-wrapper .box-wrapper .m-top {
        margin-top: 2%; }
      .cluster-preview-wrapper .content-wrapper .box-wrapper .address {
        color: #001061;
        margin-left: 2%;
        font-size: 16px; }
      .cluster-preview-wrapper .content-wrapper .box-wrapper .p-around {
        padding-left: 6px;
        padding-right: 0px; }
      .cluster-preview-wrapper .content-wrapper .box-wrapper.border-left {
        padding-left: 10px;
        background: linear-gradient(#00cdbe, #3670cc); }
        .cluster-preview-wrapper .content-wrapper .box-wrapper.border-left .box-content {
          border-radius: 0 12px 12px 0; }
      .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content {
        padding: 20px;
        background-color: #fff; }
        .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-heading {
          font-family: "Helvetica Bold";
          font-size: 18px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #00cdbe;
          margin: 20px 0 10px 0; }
        .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-content {
          background-color: #fafafa;
          border-radius: 12px;
          margin-top: 20px; }
        .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-header {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-header .box-heading {
            margin-top: 0; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-header .edit-icon {
            color: #5f5f5f;
            border-bottom: 1px solid #5f5f5f;
            cursor: pointer;
            display: inline-block;
            margin-bottom: 3px; }
        .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details {
          padding: 15px 0px 15px 15px; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div {
            margin-bottom: 15px; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div:last-child {
              margin-bottom: 0; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div label,
            .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div span {
              font-size: 14px;
              line-height: 1.19;
              letter-spacing: 0;
              color: #1d1d1d;
              margin: 0; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div label {
              font-family: "Helvetica Bold";
              display: block; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div span {
              font-family: "Helvetica"; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div textarea {
              resize: none;
              width: 100%;
              margin-top: 10px;
              padding: 15px; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details > div .check-icon {
              margin-left: 25px;
              color: #fff; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details Table {
            table-layout: fixed; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details Table thead {
              font-weight: bolder !important; }
              .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details Table thead tr {
                font-weight: bolder !important; }
                .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details Table thead tr th {
                  font-weight: bolder !important;
                  border-bottom: none; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .box-details Table tbody tr td {
              border-top: none; }
        .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-right {
          position: relative; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-right::after {
            position: absolute;
            content: "";
            border-right: 1px solid rgba(0, 0, 0, 0.16);
            right: 0;
            top: 15px;
            width: 1px;
            height: 90%; }
        .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-left {
          position: relative; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .vertical-divider-left::before {
            position: absolute;
            content: "";
            left: 0;
            top: 15px;
            width: 1px;
            height: 90%;
            border-left: 1px solid rgba(0, 0, 0, 0.16); }
        .cluster-preview-wrapper .content-wrapper .box-wrapper .box-content .horizontal-divider {
          margin: 10px 0;
          border-top: 1px solid rgba(0, 0, 0, 0.16); }
      .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list {
        list-style: none;
        margin: 10px 0; }
        .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5px 0;
          padding-bottom: 5px;
          border-bottom: 1px solid #eee; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li label,
          .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li span {
            font-size: 14px;
            line-height: 1.19;
            letter-spacing: 0;
            color: #1d1d1d;
            margin: 0; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li label {
            font-family: "Helvetica Bold";
            flex: 0.75; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li span {
            font-family: "Helvetica"; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li span:first-of-type {
              flex: 1.25;
              margin-left: 10px; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li span:last-of-type {
              flex: 1; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li svg {
            color: #b22929; }
          .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li .months-list {
            display: flex;
            list-style: none;
            flex: 1.25; }
            .cluster-preview-wrapper .content-wrapper .box-wrapper .bank-details-list > li .months-list li {
              font-family: Helvetica;
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: normal;
              color: #ffffff;
              flex: 1;
              background-color: #19ce35;
              text-align: center;
              border-right: 0.5px solid #fff; }
    .cluster-preview-wrapper .content-wrapper .submit-btn {
      background: linear-gradient(to right, #36ddd1, #001061);
      font-family: Helvetica;
      font-size: 16px;
      min-width: 225px;
      line-height: 1.19;
      letter-spacing: 0;
      color: #ffffff;
      border-radius: 25px;
      border: none;
      padding: 9px;
      margin: 10px 0;
      text-transform: uppercase; }
    .cluster-preview-wrapper .content-wrapper .check-icon {
      color: #fff;
      border-radius: 25px;
      cursor: pointer;
      width: 25px;
      height: 26px;
      text-align: center;
      padding: 3px 3px 6px;
      background-color: #78e35f; }
    .cluster-preview-wrapper .content-wrapper .check-list {
      list-style: none;
      margin-bottom: 0; }
      .cluster-preview-wrapper .content-wrapper .check-list li {
        display: flex;
        justify-content: space-between;
        align-items: flex-start; }
        .cluster-preview-wrapper .content-wrapper .check-list li:not(:first-child) {
          margin: 10px 0; }
        .cluster-preview-wrapper .content-wrapper .check-list li:first-child {
          width: 70px;
          margin: 0 -15px 0 auto; }
        .cluster-preview-wrapper .content-wrapper .check-list li:last-child {
          margin-bottom: 0; }
        .cluster-preview-wrapper .content-wrapper .check-list li label {
          font-family: "Helvetica Bold";
          font-size: 14px;
          line-height: 1.19;
          letter-spacing: 0;
          color: #1d1d1d;
          margin: 0;
          width: 80%; }
        .cluster-preview-wrapper .content-wrapper .check-list li > span {
          width: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center; }
    .cluster-preview-wrapper .content-wrapper .entity-box .vertical-divider-left::before {
      left: -20px !important; }
    .cluster-preview-wrapper .content-wrapper .entity-box .vertical-divider-right::after {
      right: 20px !important; }

.preview-navigation-bar {
  display: flex;
  justify-content: space-between;
  color: #fff;
  background-color: #001061;
  padding: 0px 20px; }
  .preview-navigation-bar .d-flex {
    display: flex; }
    .preview-navigation-bar .d-flex .icon {
      margin: 14px 9px; }
    .preview-navigation-bar .d-flex .heading {
      font-size: 15px;
      margin-top: 15px;
      font-weight: bold !important; }
  .preview-navigation-bar .button__generic {
    color: #fff;
    background-color: #4687f4;
    border: none;
    border-radius: 100px;
    padding: 9px 44px;
    margin: 10px 8px; }
  .preview-navigation-bar .button__active {
    color: #fff;
    background-color: #001061;
    border: 1px solid #00cdbe;
    border-radius: 100px;
    padding: 9px 35px;
    margin: 10px 8px; }

.crq-modal {
  max-width: 300px; }
  .crq-modal .crq-modal__wrapper .button {
    border-radius: 8px;
    padding: 10px 15px;
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 1.6rem;
    color: #565656;
    cursor: pointer; }
  .crq-modal .crq-modal__wrapper .btn-normal {
    border: 1px solid #c8c8c8;
    background-color: #ffffff; }
  .crq-modal .crq-modal__wrapper .btn-active {
    border: 1px solid #00cdbe;
    background-color: rgba(0, 205, 190, 0.05); }
  .crq-modal .crq-modal__wrapper .wrong-answer {
    font-size: 20px;
    color: #ed5a5a;
    margin: 20px 0; }

@media screen and (max-width: 1136px) {
  .cluster-preview-wrapper .content-wrapper {
    width: 90%; } }

@media screen and (max-width: 774px) {
  .preview-navigation-bar .button__generic {
    padding: 6px 29px;
    margin: 10px 8px;
    float: right; }
  .preview-navigation-bar .button__active {
    padding: 6px 20px;
    margin: 10px 8px;
    float: right; } }

@media screen and (max-width: 500px) {
  .cluster-preview-wrapper .content-wrapper {
    width: 95%;
    padding: 0 !important; }
    .cluster-preview-wrapper .content-wrapper [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-top: 10px !important; }
  .cluster-preview-wrapper .preview-navigation-bar {
    display: grid; } }

.business-cost {
  width: 60%;
  margin: 0 auto; }
  .business-cost .input-box {
    margin-top: 10px; }
  .business-cost .container {
    margin: 3% auto;
    padding: 2% 2%;
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .business-cost .container__heading {
      color: #001061;
      text-align: center;
      font-size: 2em;
      margin-top: 14px;
      margin-bottom: 25px; }
    .business-cost .container .sub-heading {
      color: #4687f4;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
      font-size: 2.4rem;
      margin-top: 0px; }
      .business-cost .container .sub-heading h2 {
        margin: 0px;
        font-size: 26px; }
    .business-cost .container .m-top--main {
      margin: 2% 0; }
    .business-cost .container .back-btn-link {
      font-family: Helvetica;
      font-size: 1.6rem;
      color: #29a9e0;
      cursor: pointer;
      position: absolute;
      left: 1%;
      top: 1%; }
    .business-cost .container .border-grey {
      border-top: 1px solid #dddddd;
      padding-top: 7px;
      margin-top: 15px; }
    .business-cost .container [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .business-cost .container .pl-1 {
      padding-left: 20px !important; }
    .business-cost .container .pl-2 {
      padding-left: 20px !important; }
    .business-cost .container .mt-0 {
      margin-top: 0px !important; }
    .business-cost .container .bicycle-part-box {
      border: 1px solid #c8c8c8;
      border-radius: 5px;
      padding: 10px 15px;
      margin-top: 10px;
      cursor: pointer; }
      .business-cost .container .bicycle-part-box .bicycle-part-image {
        margin-right: 20px;
        height: 20px; }
    .business-cost .container .active {
      border: 1px solid #4687f4;
      background-color: #00cdbe10; }
    .business-cost .container .centre {
      display: flex;
      align-items: center;
      justify-content: center; }
      .business-cost .container .centre-box {
        text-align: center;
        margin-right: 10px; }
    .business-cost .container .blue-label {
      font-weight: 500;
      color: #001061;
      font-size: 20px;
      margin-top: 0px !important; }
    .business-cost .container .flex-box {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dddddd; }
    .business-cost .container .basic-heading {
      color: #4687f4;
      padding-bottom: 5px;
      font-size: 2.4rem;
      margin-top: 14px; }
    .business-cost .container .btn-add {
      color: #ffffff;
      border: none;
      border-radius: 25px;
      padding: 0.7% 2%;
      background-color: #4687f4;
      margin: 1.4%;
      cursor: pointer;
      display: inline-block;
      font-size: 1.5rem; }
    .business-cost .container .btn-box {
      font-size: 16px;
      text-align: center;
      margin: 3% 0% 3% 3%; }
      .business-cost .container .btn-box .btn-save {
        color: #ffffff;
        border: none;
        border-radius: 25px;
        padding: 10px 24px;
        background-color: #29a9e0;
        margin: 1%;
        cursor: pointer;
        display: inline-block; }
      .business-cost .container .btn-box .btn-draft {
        color: #565656;
        border: 1px solid #29a9e0;
        border-radius: 25px;
        padding: 9px 29px;
        margin: 1%;
        cursor: pointer;
        display: inline-block; }
    .business-cost .container .select-box {
      color: black;
      border: 1px solid #eee;
      border-radius: 7px;
      font-size: 14px;
      font-family: Helvetica;
      padding: 11px 10px;
      height: 47px;
      width: 100%; }

@media screen and (max-width: 900px) {
  .business-cost {
    width: 90%; }
    .business-cost .container .pl-2 {
      padding-left: 5px !important; } }

@media screen and (max-width: 500px) {
  .business-cost {
    width: 95%; }
    .business-cost .container__heading {
      font-size: 24px; }
    .business-cost .container {
      padding: 20px 10px !important; }
      .business-cost .container .pl-1 {
        padding-left: 0px !important; }
      .business-cost .container .flex-box {
        display: initial; }
      .business-cost .container .bicycle-part-box {
        font-size: 1.3rem; }
      .business-cost .container .sub-heading {
        font-size: 2.2rem;
        margin-top: -3px; }
        .business-cost .container .sub-heading h2 {
          font-size: 2rem;
          margin: 0px; }
      .business-cost .container [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important; }
      .business-cost .container .pl-2 {
        padding-left: 5px !important; }
      .business-cost .container .back-btn-link {
        left: 1%;
        top: 0.3%; }
      .business-cost .container .btn-box .btn-save {
        font-size: 14px;
        padding: 7px 20px; }
      .business-cost .container .btn-box .btn-draft {
        padding: 7px 22px;
        font-size: 14px; }
      .business-cost .container .btn-add {
        margin: 2% 0; } }

.lead-manager-overview .container_top {
  position: relative; }
  .lead-manager-overview .container_top .container_heading {
    text-align: center;
    color: #001061;
    margin-bottom: 20px; }
  .lead-manager-overview .container_top .back-btn-link {
    font-family: Helvetica;
    font-size: 1.6rem;
    color: #29a9e0;
    cursor: pointer;
    position: absolute;
    left: 13%;
    top: 25%; }

.lead-manager-overview .manager-wrapper {
  display: flex; }
  .lead-manager-overview .manager-wrapper .dropdown-menu {
    position: absolute;
    top: 100%;
    left: -132px; }
  .lead-manager-overview .manager-wrapper .nav > li > a {
    padding: 0px; }
  .lead-manager-overview .manager-wrapper #show {
    display: none; }
  .lead-manager-overview .manager-wrapper #margin-value {
    color: #001061;
    font-weight: bold !important;
    font-size: 12px;
    top: 8px;
    position: relative;
    padding-left: 7px; }
  .lead-manager-overview .manager-wrapper .shadow-box {
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    padding: 23px 7px; }
  .lead-manager-overview .manager-wrapper .sub-heading {
    margin: 0px 13px 0px 13px;
    font-family: "Helvetica Bold";
    font-size: 18px;
    line-height: 0.92;
    letter-spacing: normal;
    color: #00cdbe;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px; }
    .lead-manager-overview .manager-wrapper .sub-heading-basic {
      color: #001061;
      margin: 13px 0px 0px 12px; }
  .lead-manager-overview .manager-wrapper .m-bottom {
    margin-bottom: 14px; }
  .lead-manager-overview .manager-wrapper .icon-tick {
    color: #00cdbe;
    margin-left: 7px; }
  .lead-manager-overview .manager-wrapper .block {
    font-weight: bold !important;
    margin-top: 16px; }
    .lead-manager-overview .manager-wrapper .block div {
      font-size: 14px;
      padding-top: 4px; }
  .lead-manager-overview .manager-wrapper .p-left {
    padding-left: 4px; }
  .lead-manager-overview .manager-wrapper .content {
    background-color: #fbfcfe;
    flex: 1; }
    .lead-manager-overview .manager-wrapper .content .main__head {
      display: flex;
      border-top: 1px solid #f2f1ff;
      border-bottom: 1px solid #f2f1ff;
      padding: 12px 56px; }
      .lead-manager-overview .manager-wrapper .content .main__head--blocks {
        font-weight: bold !important;
        flex-basis: 13%; }
      .lead-manager-overview .manager-wrapper .content .main__head--value {
        font-size: 12px;
        padding-top: 9px; }
  .lead-manager-overview .manager-wrapper .container {
    display: flex;
    padding-top: 25px;
    width: 74.5%; }
    .lead-manager-overview .manager-wrapper .container--left {
      background-color: #fbfcfe;
      flex: 1; }
      .lead-manager-overview .manager-wrapper .container--left .promoter-details {
        margin-bottom: 20px; }
      .lead-manager-overview .manager-wrapper .container--left .income-details {
        margin-top: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-radius: 10px 10px 0px 0px; }
        .lead-manager-overview .manager-wrapper .container--left .income-details .ring-wrap {
          width: 92px; }
      .lead-manager-overview .manager-wrapper .container--left .monthly-business {
        border-radius: 0px !important; }
        .lead-manager-overview .manager-wrapper .container--left .monthly-business-heading {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          .lead-manager-overview .manager-wrapper .container--left .monthly-business-heading h4 {
            color: #001061;
            margin-left: 13px; }
          .lead-manager-overview .manager-wrapper .container--left .monthly-business-heading span {
            color: #898989;
            font-size: 9px;
            margin-right: 13px; }
      .lead-manager-overview .manager-wrapper .container--left .box {
        border-radius: 0px !important; }
      .lead-manager-overview .manager-wrapper .container--left .box-sub {
        border-radius: 0px 0px 10px 10px;
        margin-bottom: 20px; }
    .lead-manager-overview .manager-wrapper .container--right {
      background-color: #fbfcfe;
      flex-basis: 32%; }
      .lead-manager-overview .manager-wrapper .container--right .gating-check {
        margin: 0px 0px 20px 20px;
        padding: 16px 5px; }
        .lead-manager-overview .manager-wrapper .container--right .gating-check .black {
          font-weight: bold !important;
          margin-bottom: 2px; }
        .lead-manager-overview .manager-wrapper .container--right .gating-check .ring {
          border-radius: 20px;
          height: auto;
          outline: none;
          width: 86px;
          color: white;
          border-style: none;
          font-family: Helvetica;
          line-height: 1.19;
          letter-spacing: 0px;
          padding: 6px 6px;
          font-size: 13px;
          margin-top: 5px;
          text-align: center; }
        .lead-manager-overview .manager-wrapper .container--right .gating-check .gating-status__logo {
          height: 30px;
          cursor: pointer; }
      .lead-manager-overview .manager-wrapper .container--right .fund-requirement {
        color: #001061;
        margin: 0px 0px 20px 20px;
        padding: 23px 19px; }
        .lead-manager-overview .manager-wrapper .container--right .fund-requirement h4 {
          margin-top: 0;
          font-weight: bold !important; }
        .lead-manager-overview .manager-wrapper .container--right .fund-requirement--value {
          color: #00cdbe;
          font-weight: bold !important;
          font-size: 24px; }
      .lead-manager-overview .manager-wrapper .container--right .business-details {
        margin: 0px 0px 20px 20px; }
        .lead-manager-overview .manager-wrapper .container--right .business-details .sub--heading {
          font-family: "Helvetica Bold";
          font-size: 18px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #00cdbe;
          margin-top: 9px; }
        .lead-manager-overview .manager-wrapper .container--right .business-details--heading {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 5px;
          margin: 0px 13px 0px 13px; }
          .lead-manager-overview .manager-wrapper .container--right .business-details--heading h3 {
            margin-top: 0;
            color: #00cdbe; }
          .lead-manager-overview .manager-wrapper .container--right .business-details--heading--logo {
            border-radius: 50%;
            cursor: pointer;
            background-color: #d7e3f5; }
            .lead-manager-overview .manager-wrapper .container--right .business-details--heading--logo svg {
              height: 21px;
              width: 26px;
              margin: 3px;
              fill: #3671cc;
              padding-top: 3px; }
        .lead-manager-overview .manager-wrapper .container--right .business-details .show-more {
          border-top: 1px solid #f2f1ff;
          color: #29a9e0;
          font-size: 12px;
          padding: 14px 16px;
          cursor: pointer; }
          .lead-manager-overview .manager-wrapper .container--right .business-details .show-more svg {
            height: 25px;
            width: 30px;
            fill: #29a9e0; }
      .lead-manager-overview .manager-wrapper .container--right .media-docs {
        color: #001061;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px 0px 20px 20px;
        padding: 18px; }
        .lead-manager-overview .manager-wrapper .container--right .media-docs .error {
          color: red;
          font-size: 9px; }
        .lead-manager-overview .manager-wrapper .container--right .media-docs h4 {
          font-weight: bold !important; }
        .lead-manager-overview .manager-wrapper .container--right .media-docs img {
          width: 73px;
          height: 40px;
          cursor: pointer; }
        .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-location,
        .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-bank,
        .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-bag {
          display: flex;
          align-items: center; }
          .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-location svg,
          .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-bank svg,
          .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-bag svg {
            height: 27px;
            width: 34px; }
          .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-location span,
          .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-bank span,
          .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-bag span {
            font-weight: bold !important;
            font-size: 18px;
            margin-left: 7px; }
        .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-location svg {
          fill: #7b77f1;
          margin-right: 7px; }
        .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-bank svg {
          fill: #3671cc;
          margin-right: 7px; }
        .lead-manager-overview .manager-wrapper .container--right .media-docs .icon-bag svg {
          fill: #00cdbe;
          margin-right: 7px; }
        .lead-manager-overview .manager-wrapper .container--right .media-docs--icon {
          border-radius: 50%;
          cursor: pointer;
          background-color: #d7e3f5; }
          .lead-manager-overview .manager-wrapper .container--right .media-docs--icon svg {
            height: 21px;
            width: 26px;
            margin: 3px;
            fill: #3671cc;
            padding-top: 3px; }
      .lead-manager-overview .manager-wrapper .container--right .assets {
        padding: 0px !important;
        margin-left: 20px; }
        .lead-manager-overview .manager-wrapper .container--right .assets .m-0 {
          margin: 0px; }
        .lead-manager-overview .manager-wrapper .container--right .assets-base {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 89%;
          margin: 0 auto;
          padding-bottom: 14px; }
          .lead-manager-overview .manager-wrapper .container--right .assets-base .value {
            color: #00cdbe;
            font-weight: bold !important;
            font-size: 24px; }
          .lead-manager-overview .manager-wrapper .container--right .assets-base .sub-text {
            font-size: 10px;
            color: #4f4f4f; }
  .lead-manager-overview .manager-wrapper .investment-funds {
    display: flex;
    justify-content: space-between;
    width: 72.5%;
    margin: 0 auto; }
    .lead-manager-overview .manager-wrapper .investment-funds-container {
      flex-basis: 49%;
      margin-bottom: 20px; }
      .lead-manager-overview .manager-wrapper .investment-funds-container .credit-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        margin: 0px 13px 0px 13px; }
        .lead-manager-overview .manager-wrapper .investment-funds-container .credit-heading .main-heading {
          font-family: "Helvetica Bold";
          font-size: 18px;
          line-height: 0.92;
          letter-spacing: normal;
          color: #00cdbe;
          padding-bottom: 10px; }
        .lead-manager-overview .manager-wrapper .investment-funds-container .credit-heading span {
          color: #898989;
          font-size: 9px;
          padding-bottom: 10px; }
        .lead-manager-overview .manager-wrapper .investment-funds-container .credit-heading .save-comments {
          color: white;
          background-color: #29a9e0;
          border-radius: 100px;
          padding: 2px 18px;
          font-size: 14px;
          margin-top: -10px;
          cursor: pointer; }
        .lead-manager-overview .manager-wrapper .investment-funds-container .credit-heading .edit-comments {
          color: #29a9e0;
          cursor: pointer;
          margin-top: -10px; }
      .lead-manager-overview .manager-wrapper .investment-funds-container .bold {
        color: #011161;
        font-size: 24px;
        font-weight: bold;
        margin-top: 21px; }
      .lead-manager-overview .manager-wrapper .investment-funds-container .value {
        font-weight: 900 !important;
        font-size: 24px;
        color: #011161;
        padding: 14px 17px; }
  .lead-manager-overview .manager-wrapper .monthly-expense {
    width: 72.5%;
    margin: 0 auto;
    margin-bottom: 20px; }
    .lead-manager-overview .manager-wrapper .monthly-expense .p-left {
      padding-left: 0px; }
    .lead-manager-overview .manager-wrapper .monthly-expense .icon {
      padding-top: 11px; }
    .lead-manager-overview .manager-wrapper .monthly-expense .icon-green {
      color: #00cdbe;
      margin-left: -16px;
      margin-right: 5px; }
    .lead-manager-overview .manager-wrapper .monthly-expense .icon-blue {
      color: #3671cc;
      margin-left: -16px;
      margin-right: 5px; }
    .lead-manager-overview .manager-wrapper .monthly-expense .text-bold {
      color: #001061;
      font-weight: 900 !important;
      font-size: 14px;
      margin-left: 72px; }
    .lead-manager-overview .manager-wrapper .monthly-expense .text-bold-sub {
      color: #001061;
      font-weight: 900 !important;
      font-size: 14px;
      margin-left: 185px; }
    .lead-manager-overview .manager-wrapper .monthly-expense .border {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 12px; }
  .lead-manager-overview .manager-wrapper .comments {
    width: 72.5%;
    margin: 0 auto;
    margin-bottom: 20px; }
    .lead-manager-overview .manager-wrapper .comments h4 {
      color: #001061;
      font-weight: 600 !important; }
    .lead-manager-overview .manager-wrapper .comments p {
      font-size: 13px;
      color: #4f4f4f;
      padding: 15px 0px 0px 13px; }

.lead-manager-modal .media-document__wrapper h2 {
  color: #00cdbe;
  font-size: 24px;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 9px;
  margin-top: 0px !important; }

.lead-manager-modal .media-document__wrapper .shadow-box {
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 18px; }

.lead-manager-modal .media-document__wrapper .doc-heading {
  color: #001061;
  font-size: 22px;
  margin: 10px 0; }

.lead-manager-modal .media-document__wrapper .images {
  height: 100%;
  width: 95%;
  transform: rotate(270deg);
  margin-bottom: 20px; }

.lead-manager-modal .bank-details-wrapper h2 {
  color: #00cdbe;
  font-size: 24px;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 9px;
  margin-top: 0px !important; }

.lead-manager-modal .bank-details-wrapper .shadow-box {
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  padding: 23px 7px; }

.lead-manager-modal .bank-details-wrapper span {
  color: #001061;
  padding: 0px 0px 7px 4px;
  font-size: 18px;
  display: inline-block; }

.lead-manager-modal .bank-details-wrapper .p-left {
  padding: 0px 0px 0px 12px; }

.lead-manager-modal .bank-details-wrapper Table thead tr th {
  font-weight: bold !important;
  font-size: 16px;
  border-bottom: none; }

.lead-manager-modal .bank-details-wrapper .m-around {
  margin: 25px 0px; }

.lead-manager-modal .bank-details-wrapper .block {
  font-weight: bold !important;
  margin-top: 16px; }
  .lead-manager-modal .bank-details-wrapper .block div {
    font-size: 14px;
    padding-top: 4px; }

.dashboard-wrapper .gating-status__logo {
  height: 30px;
  position: relative;
  z-index: 1000; }

.dashboard-wrapper .daterange-bar-wrapper .container .daterange-box .excel-download {
  background-color: #00cdbe;
  border-radius: 10px;
  padding: 8px 25px;
  cursor: pointer; }
  .dashboard-wrapper .daterange-bar-wrapper .container .daterange-box .excel-download a {
    color: #fff; }

.gating-wrapper h3 {
  color: #00cdbe;
  font-size: 24px;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 9px;
  margin-top: 0px !important; }

.gating-wrapper .text-center {
  margin-top: 10%;
  display: flex;
  justify-content: space-evenly; }
  .gating-wrapper .text-center .btn {
    font-size: 1.6rem;
    color: #ffffff;
    border: none;
    display: inline-block;
    border-radius: 25px;
    padding: 1% 6%;
    margin-top: 3%;
    cursor: pointer;
    width: 30%; }
  .gating-wrapper .text-center .reject-btn {
    background-color: #ed5a5a; }
  .gating-wrapper .text-center .approve-btn {
    background-color: #00cdbe; }

.gating-check__wrapper {
  width: 60%;
  margin: 0 auto; }
  .gating-check__wrapper .card-wrapper {
    margin: 3% auto;
    padding: 2% 4%;
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .gating-check__wrapper .card-wrapper__heading {
      color: #001061;
      text-align: center;
      font-size: 2em;
      margin-top: 14px;
      margin-bottom: 21px; }
    .gating-check__wrapper .card-wrapper .sub-heading {
      color: #4687f4;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 10px;
      font-size: 2.4rem;
      margin-top: 14px; }
    .gating-check__wrapper .card-wrapper .back-btn-link {
      font-family: Helvetica;
      font-size: 1.6rem;
      color: #29a9e0;
      cursor: pointer;
      position: absolute;
      left: 5%;
      top: 12%; }
    .gating-check__wrapper .card-wrapper .label-heading {
      color: #1d1d1d;
      font-weight: 700 !important;
      font-size: 16px;
      padding: 0;
      margin-top: 10px; }
    .gating-check__wrapper .card-wrapper .gating-status {
      border-radius: 20px;
      display: inline-block;
      padding: 5px 20px;
      margin-top: 10px; }
    .gating-check__wrapper .card-wrapper [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-top: 10px !important; }

@media screen and (max-width: 900px) {
  .gating-check__wrapper {
    width: 90%; } }

@media screen and (max-width: 500px) {
  .gating-check__wrapper {
    width: 95%; }
    .gating-check__wrapper .container__heading {
      font-size: 24px; }
    .gating-check__wrapper .container {
      padding: 20px 10px !important; }
      .gating-check__wrapper .container .sub-heading {
        font-size: 2.2rem; }
      .gating-check__wrapper .container .back-btn-link {
        left: 1%;
        top: 0.7%; }
    .gating-check__wrapper [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-top: 10px !important; } }

.bicycle-analysis-wrapper {
  margin-top: 10px; }
  .bicycle-analysis-wrapper .box-wrapper {
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
    margin-bottom: 35px;
    padding: 25px 20px; }
    .bicycle-analysis-wrapper .box-wrapper .box-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
      .bicycle-analysis-wrapper .box-wrapper .box-header_heading {
        font-family: "Helvetica Bold";
        font-size: 18px;
        line-height: 0.92;
        letter-spacing: normal;
        color: #00cdbe;
        margin-top: 0; }
  .bicycle-analysis-wrapper .container_top {
    position: relative; }
    .bicycle-analysis-wrapper .container_top .container_heading {
      text-align: center;
      color: #001061;
      margin-bottom: 20px; }
    .bicycle-analysis-wrapper .container_top .back-btn-link {
      font-family: Helvetica;
      font-size: 1.6rem;
      color: #29a9e0;
      cursor: pointer;
      position: absolute;
      left: -1%;
      top: 2%; }
  .bicycle-analysis-wrapper .bicycle-part-image {
    margin-right: 20px;
    height: 38px;
    padding-left: 20px; }
  .bicycle-analysis-wrapper .box_sub_heading {
    font-family: "Helvetica Bold";
    font-size: 18px;
    line-height: 0.92;
    letter-spacing: normal;
    color: #001061;
    margin-top: 27px; }
  .bicycle-analysis-wrapper .w-50 {
    width: 46%;
    display: inline-block;
    margin-right: 33px; }
  .bicycle-analysis-wrapper .mt-0 {
    margin-top: 0px !important; }
  .bicycle-analysis-wrapper .mt-20 {
    margin-top: 20px; }
  .bicycle-analysis-wrapper .bold {
    font-weight: 700 !important;
    margin-top: 16px;
    padding-left: 0px; }
    .bicycle-analysis-wrapper .bold div {
      font-size: 14px;
      padding-top: 4px; }

.date-dropdown-wrapper .date-element {
  border: 1px solid #eee;
  padding: 11px 12px; }

.date-dropdown-wrapper .r-left {
  border-radius: 5px 0px 0px 5px; }

.date-dropdown-wrapper .r-right {
  border-radius: 0px 5px 5px 0px; }
